import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Login from 'tsx/features/main/Login';
import Logout from 'tsx/features/main/Logout';
import SessionCheck from './SessionCheck';
import Home from 'tsx/features/main/Home';
import Appointments from 'tsx/features/appointments/Appointments';
import Appointment from 'tsx/features/appointments/pages/Appointment';
import Users from 'tsx/features/care-workers/Users';
import User from 'tsx/features/care-workers/User';
import PageNotFound from 'tsx/components/PageNotFound';
import RedirectTo from 'tsx/components/RedirectTo';
import AppointmentCancellation from 'tsx/features/appointments/pages/Cancellation';
import RepeatAppointments from 'tsx/features/appointments/RepeatAppointments';
import WeeklyPlanner from 'tsx/features/weekly-planner/WeeklyPlanner';

import AppDynamicRoute from './AppDynamicRoute';

function AppRoutes() {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/auth-session',
      element: <SessionCheck />,
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '/',
          element: <Home />,
          children: [
            // appointments
            { path: 'appointments', element: <Appointments key={1} /> },
            { path: 'appointments/repeats', element: <RepeatAppointments key={3} /> },
            { path: 'appointments/new', element: <Appointment key={2} isNew={true} /> },
            { path: 'appointments/repeats/new', element: <Appointment key={4} isNew={true} forceRepeat={true} /> },
            { path: 'appointments/transport/new', element: <Appointment key={5} isNew={true} isTransport={true} /> },
            { path: 'appointments/:id', element: <AppDynamicRoute Route={Appointment} paramKey={'id'} /> },
            { path: 'appointments/:id/cancel', element: <AppointmentCancellation /> },

            // users
            { path: 'users', element: <Users /> },
            { path: 'users/:id', element: <AppDynamicRoute Route={User} paramKey={'id'} /> },

            // weekly planner
            { path: 'weekly-planner', element: <WeeklyPlanner /> },

            { path: 'redirect/*', element: <RedirectTo /> },
            {
              path: '*', // This will catch all unmatched routes
              element: <PageNotFound />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRoutes;
