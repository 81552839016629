import React from 'react';
import { useAppSelector } from 'hooks';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { selectCurrentAppointmentSignature } from '../reducers/appointments';

function FormInputSignature({ id }: InputProps) {
  const signature = useAppSelector(selectCurrentAppointmentSignature);
  return (
    <>
      {!signature && (
        <FormInput.ReadOnly type={'readonly'} name={id} id={id} value="None collected" className="ps-2 pe-2" />
      )}
      {signature && <img className="p-2 border rounded" src={signature} />}
    </>
  );
}

export default FormInputSignature;
