import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { apiClientPackageSchedules } from 'tsx/constants/api';
import { dateInputFieldFormat } from 'tsx/libs/dayjs';
import { get } from 'tsx/libs/genericAction';

export const typePrefix = 'clients-packages-schedules';

interface Params {
  id?: string;
  [key: string]: string | number | boolean | object | undefined;
}

export const getClientPackageSchedules = createAsyncThunk(`${typePrefix}/getAll`, async (params?: Params) => {
  let filterDate: any;
  if (params && params['date']) {
    filterDate = params['date'];
  }
  return get(apiClientPackageSchedules, {
    sort: ['package.name'],
    deleted: false,
    client_id: (params && params['client_id']) ?? '',
    end_date: {
      gte: dayjs(filterDate).format(dateInputFieldFormat),
    },
  });
});

export const getClientPackageSchedule = createAsyncThunk(`${typePrefix}/getOne`, async (params: Params) =>
  get(`${apiClientPackageSchedules}/${params.id}`, params),
);
