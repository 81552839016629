import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks';
import dayjs, { Dayjs } from 'dayjs';

import ContainerPanel from 'tsx/components/ContainerPanel';
import Link from 'tsx/components/Link';
import { Icon, icons } from 'tsx/components/Icon';

import Banner from './components/Banner';
import { SideBar } from './components/SideBar';
import WeeklyView from './components/WeeklyView';
import DailyView from './components/DailyView/DailyView';

import { formatDateString, isBeforeDay } from 'tsx/libs/dayjs';
import { deriveClientLink } from 'tsx/features/appointments/lib/derive';

import { buildAssignChange } from './lib/simulate';

import { getAll as getUsers } from 'tsx/features/main/actions/users';
import { getAll as getCareQualificationLevels } from 'tsx/features/appointments/actions/careQualificationLevels';
import { getAll as getServiceTypes } from 'tsx/features/appointments/actions/serviceTypes';

import { getAll as getAllWeeklyPlannerData } from './actions/weeklyPlanner';
import { simulate } from './actions/unsaved';
import { getAwardAlertTypes } from './actions/weeklyPlannerAppointments';

import { setTitle } from 'tsx/features/main/actions/login';

import { toggleQuickMenu } from 'tsx/features/main/reducers/app';
import {
  updateWeeklyPlannerParams,
  openAppointmentDetails,
  selectFocusedAppointment,
  focusAppointment,
  selectLoading,
  selectParams,
  selectFocusedWorkerId,
} from './reducers/weeklyPlanner';
import { clearInteracting, setInteracting } from './reducers/unsaved';
import { selectInteracting, selectSimulatedChanges, selectIsFocusedWorkerInRows } from './selectors/simulate';

dayjs.updateLocale('en', {
  weekStart: 1,
});

const WeeklyPlanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const weeklyPlannerDataLoading = useAppSelector(selectLoading);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);
  const focused = useAppSelector(selectFocusedAppointment);
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);
  const isWorkerInRows = useAppSelector(selectIsFocusedWorkerInRows);
  const interacting = useAppSelector(selectInteracting);

  const { appointment: focusedAppointment } = focused || {};
  const { key: focusedAppointmentKey, date: focusedDate = null } = focusedAppointment || {};

  const isLoading = weeklyPlannerDataLoading === 'pending';

  const [date, setDate] = useState<Dayjs>(dayjs().weekday(0));
  const [dayView, setDayView] = useState(false);

  const [isRightClickMenuOpen, setRightClickMenuOpen] = useState(false);

  const [clickPoint, setClickPoint] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setTitle('Weekly Planner');
    // Set quick menu narrow by default for extra screen space
    dispatch(toggleQuickMenu(true));

    dispatch(
      getCareQualificationLevels({
        attributes: ['id', 'name', 'colour'],
      }),
    );
    dispatch(getServiceTypes());
    dispatch(getUsers({ sort: ['surname'] }));
    dispatch(getAwardAlertTypes());

    document.addEventListener('click', leftClickCheck);
    document.addEventListener('contextmenu', contextClick);
    return () => {
      document.removeEventListener('click', leftClickCheck);
      document.removeEventListener('contextmenu', contextClick);
    };
  }, []);

  useEffect(() => {
    load();
  }, [params]);

  useEffect(() => {
    const week_start = !dayView ? date.weekday(0) : date;
    const params = {
      week_start: week_start.toISOString(),
      week_end: dayView ? week_start.add(1, 'day').toISOString() : null,
      limit: focusedAppointmentKey ? 0 : 30,
      ...(focusedAppointmentKey != null && { focus_appointment_id: focusedAppointmentKey }),
    };
    dispatch(updateWeeklyPlannerParams(params));

    setTitle(`${formatDateString(week_start)} - Weekly Planner `);
  }, [dayView, date]);

  useEffect(() => {
    if (focusedAppointmentKey) {
      if (!dayView) setDayView(true);
      setDate(dayjs(focusedDate));
    }
  }, [focusedAppointmentKey]);

  useEffect(() => {
    if (focusedWorkerId !== null && !isWorkerInRows) {
      dispatch(updateWeeklyPlannerParams({ ...params, focus_worker_id: focusedWorkerId }));
    }
  }, [focusedWorkerId]);

  const load = (searchParams?: { [key: string]: any }) => {
    if (params.week_start && !isLoading) {
      dispatch(clearInteracting());
      dispatch(getAllWeeklyPlannerData({ ...params, ...searchParams }));
    }
  };

  // On right-click check
  const contextClick = (event: MouseEvent) => {
    const clickTarget = event.target as HTMLElement;
    const appointmentCard = clickTarget.closest('.appointment-card');
    dispatch(openAppointmentDetails(null));

    if (appointmentCard) {
      event.preventDefault();
      if (appointmentCard) {
        const key = appointmentCard.getAttribute('data-key');
        dispatch(setInteracting(key));
      }
      setClickPoint({ x: event.clientX, y: event.clientY });
      setRightClickMenuOpen(true);
    }
  };

  //On left click check
  const leftClickCheck = (event: MouseEvent) => {
    const dropdown = document.getElementById('weekly-planner-context') as HTMLElement;
    if (!dropdown?.contains(event.target as Node)) {
      dispatch(clearInteracting());
      dispatch(openAppointmentDetails(null));
      setRightClickMenuOpen(false);
    }
  };

  const setDateChange = (value: Dayjs) => {
    dispatch(focusAppointment(null));
    const dateValue = dayView ? value : value.weekday(0);
    !date.isSame(dateValue, 'day') && setDate(dateValue);
  };
  const setViewChange = (toggle: boolean) => {
    setDayView(toggle);
    dispatch(focusAppointment(null));
  };

  const disableUnassign = interacting && isBeforeDay(dayjs(interacting.date), dayjs());

  const { x: left, y: top } = clickPoint;
  const dropdownStyle: CSSProperties = { top, left, position: 'absolute' };

  const MenuLink = ({ to, children, disabled = false }: { to: string; children: ReactNode; disabled?: boolean }) => {
    return (
      <Link to={to} target="_blank" className="text-decoration-none" disabled={disabled}>
        <DropdownItem disabled={disabled}>
          {children}
          <Icon icon={icons.faUpRightFromSquare} className="hover-icon" />
        </DropdownItem>
      </Link>
    );
  };

  const unassignAppointment = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    if (interacting) {
      const change = buildAssignChange(weeklyPlannerData, [interacting], true);
      dispatch(simulate({ ...change, week_start: dayjs().weekday(0) }));
    }
  };

  const findNewWorker = () => {
    dispatch(
      focusAppointment({
        appointment: interacting,
        isAvailabilityView: true,
        isBiddingView: false,
      }),
    );
    dispatch(clearInteracting());
  };
  return (
    <div className="p-2 weekly-planner">
      <Banner isDayView={dayView} onDateChange={setDateChange} onViewChange={setViewChange} onRefresh={load} />
      <ContainerPanel className="canvas gx-0 flex-nowrap" sidepanel={<SideBar date={date} />}>
        {dayView ? (
          <DailyView date={date} onDateChange={setDateChange} />
        ) : (
          <WeeklyView date={date} onDateChange={setDateChange} onViewChange={setViewChange} />
        )}
      </ContainerPanel>
      <Dropdown
        id="weekly-planner-context"
        style={dropdownStyle}
        isOpen={isRightClickMenuOpen}
        toggle={() => setRightClickMenuOpen(!isRightClickMenuOpen)}
        className="weekly-planner-context"
      >
        <DropdownMenu>
          <DropdownItem
            disabled={!interacting?.id}
            onClick={(event) => {
              event.stopPropagation();
              dispatch(openAppointmentDetails(interacting?.id));
              dispatch(clearInteracting());
            }}
          >
            View Details
          </DropdownItem>
          <DropdownItem disabled={disableUnassign} onClick={unassignAppointment}>
            Unassign
          </DropdownItem>
          <DropdownItem disabled={!interacting?.id} onClick={findNewWorker}>
            Find New Worker
          </DropdownItem>
          <DropdownItem divider></DropdownItem>
          <MenuLink disabled={!interacting?.id} to={`${window.origin}/appointments/${interacting?.id}`}>
            View Appointment
          </MenuLink>
          <MenuLink
            disabled={!interacting?.parent_repeat_id}
            to={`${window.origin}/appointments/${interacting?.parent_repeat_id}`}
          >
            View Parent
          </MenuLink>
          <MenuLink disabled={!interacting?.client_id} to={deriveClientLink({ client_id: interacting?.client_id })}>
            View Client
          </MenuLink>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default WeeklyPlanner;
