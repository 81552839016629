import { get, post } from 'tsx/libs/genericAction';
import { apiLogin, apiAuthenticateSession, apiSettings } from 'tsx/constants/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { APPLICATION_TITLE } from 'tsx/constants/headerMenu';

export const typePrefix = 'login';

interface Params {
  email: string;
  password: string;
}

export const setTitle = (title: string) => {
  document.title = `${title} - ${APPLICATION_TITLE}`;
};

export const login = createAsyncThunk(`${typePrefix}/post`, async (params: Params) => post(apiLogin, params));

export const authenticateSession = createAsyncThunk(`${typePrefix}/authenticate`, async (params: { session: string }) =>
  post(apiAuthenticateSession, params),
);

export const getSettings = createAsyncThunk(`${typePrefix}/getSettings`, async (params?: Params) =>
  get(apiSettings, params),
);
