import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';

import { PIP_INTERVALS } from './DailyView/DailyView';
import AppointmentPopover from './AppointmentPopover';
import { Icon, icons } from 'tsx/components/Icon';

import { Appointment, Event, formatDistanceDisplayString, maxDistance } from '../lib/common';
import { getPredictedTime } from '../lib/simulate';
import { timeValueFormat } from 'tsx/libs/dayjs';

import { selectChangeById } from '../selectors/simulate';
import { selectServiceTypeColourById } from 'tsx/features/appointments/reducers/serviceTypes';
import { selectFocusedAppointment, selectOpenAppointmentDetailsId } from '../reducers/weeklyPlanner';
import { selectOptions } from '../reducers/weeklyPlanner';

interface ComponentProps {
  appointment: Appointment;
  className?: string;
  style?: CSSProperties;
  containerId?: string;
  containerWidth?: number;
  maxSegments?: number;
  displayOnTransform?: boolean;
  events: Event[];
}

const AppointmentCard: React.FC<ComponentProps> = ({
  containerId,
  appointment,
  className,
  style,
  containerWidth,
  maxSegments,
  displayOnTransform = false,
  events = [],
}) => {
  const {
    key,
    id,
    client,
    start_time,
    end_time,
    status,
    flexibility,
    date,
    service_type,
    is_travel_exempt,
    is_cancelled,
    is_active_cancellation,
  } = appointment;
  const { full_name } = client;

  const [isPopOpen, setPopOpen] = useState(false);
  const poppedId = useAppSelector(selectOpenAppointmentDetailsId);
  const { appointmentFlexibility } = useAppSelector(selectOptions);
  const hasChanged = useAppSelector((state) => selectChangeById(state, key));
  const focused = useAppSelector(selectFocusedAppointment);

  const isNew = id === null;
  const { appointment: focusedAppointment, isAvailabilityView } = focused || {};
  const { key: focusKey, client: focusAppointmentClient } = focusedAppointment || {};

  const contextTarget = `appointment-card-${key}`;

  useEffect(() => {
    // Don't allow a popover to be shown unless the Appointment actually exists.
    // Pseudo/new appointments don't have all the information
    const shouldPop = id && id === poppedId;
    if (shouldPop && !isPopOpen) setPopOpen(true);
    else if (!shouldPop && isPopOpen) setPopOpen(false);
  }, [poppedId]);

  // Do not set draggable unless "key" exists
  // Set optional variables, only set if a containerId exists
  let attributes, listeners, setNodeRef, transform;
  if (containerId && key && !is_cancelled) {
    // Only establish draggable if a containerId exists
    ({ attributes, listeners, setNodeRef, transform } = useDraggable({
      id: key,
      data: { containerId, containerStyle: style, appointment },
    }));
  }

  const { x, y } = transform ?? {};
  const serviceTypeColour = useAppSelector((state) => selectServiceTypeColourById(state, service_type?.id));

  const flexibilityClasses = {
    true: 'flexible',
    false: 'non-flexible',
  };

  const backgroundColor = serviceTypeColour ? serviceTypeColour : 'white';

  let appointmentCardStyle: CSSProperties = {
    transform: transform ? `translate3d(${x}px, ${!displayOnTransform ? y : '0'}px, 0)` : undefined,
    backgroundColor,
    color: tinycolor(backgroundColor).isDark() ? 'white' : undefined,
    ...style,
  };

  const statusStripStyle: CSSProperties = {
    backgroundColor: status?.colour ?? undefined,
  };

  // If moving on the x scale, show the transformed start and end times.
  // CAREFUL, offsetMinutes is based on the current container size, x position and movement made on drag.
  let displayStart = start_time;
  let displayEnd = end_time;
  if (transform && displayOnTransform) {
    displayStart = getPredictedTime(containerWidth ?? 1, x ?? 0, maxSegments ?? 1, start_time, PIP_INTERVALS).format(
      timeValueFormat,
    );
    displayEnd = getPredictedTime(containerWidth ?? 1, x ?? 0, maxSegments ?? 1, end_time, PIP_INTERVALS).format(
      timeValueFormat,
    );
  }

  // distance display for focusAppointment view (distance focus)
  const maxDist = formatDistanceDisplayString(maxDistance(events));
  const displayDistance = (focusKey && !isAvailabilityView) ?? false;
  const distanceInfo = `${date}\n${start_time} - ${end_time}\n${full_name}\n${maxDist} from ${focusAppointmentClient?.full_name}`;
  const displayTitle = displayDistance ? maxDist : full_name;
  if (displayDistance) {
    appointmentCardStyle = {
      ...appointmentCardStyle,
      backgroundImage: `linear-gradient(to bottom, red 40%, ${status?.colour ?? undefined} 40%)`,
    };
  }

  const extraProps = {
    ref: setNodeRef,
    ...attributes,
    ...listeners,
  };

  const cardClassName = classNames('appointment-card tooltip-container', {
    [`${className}`]: className !== undefined,
    changed: hasChanged,
    cancelled: is_cancelled,
    new: isNew,
    [`${flexibilityClasses[`${flexibility}`]}`]: appointmentFlexibility,
  });

  return (
    <>
      <div
        className={cardClassName}
        id={contextTarget}
        data-key={key}
        data-tooltip={!displayDistance ? `${displayTitle} (${start_time} - ${end_time})` : distanceInfo}
        style={appointmentCardStyle}
        {...extraProps}
        onClick={(clickEvent) => clickEvent.stopPropagation()}
        onContextMenu={(contextClick) => {
          contextClick.preventDefault();
        }}
      >
        {!displayDistance && <div className="status-strip" style={statusStripStyle} />}
        <div className="appointment-info">
          <div>
            <strong>{displayTitle}</strong>
          </div>
          <div>
            {displayStart} - {displayEnd}
          </div>
          {is_travel_exempt && (
            <span className="fa-layers fa-fw" title="Travel exempt">
              <Icon icon={icons.faCar} size="xs" />
              <Icon icon={icons.faBan} size="lg" />
            </span>
          )}
          {is_active_cancellation && <Icon icon={icons.faCommentDollar} />}
        </div>
      </div>

      <AppointmentPopover isOpen={isPopOpen} target={contextTarget} appointment={appointment} />
    </>
  );
};

export default AppointmentCard;
