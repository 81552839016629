import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Table } from 'reactstrap';
import dayjs from 'dayjs';

import ModalCard from 'tsx/components/ModalCard';

import { dateInputFieldFormat, formatHourMinutes, weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';

import { getStatistics } from 'tsx/features/appointments/actions/appointments';
import { getAll as getStatuses } from 'tsx/features/appointments/actions/statuses';

import { selectStatistics } from 'tsx/features/appointments/reducers/appointments';
import { selectAppointmentStatuses } from 'tsx/features/appointments/reducers/statuses';

interface ComponentProps {
  weekStart: string;
  modalProps: any;
}

interface DayStats {
  count: number;
  duration: number;
  users: number;
  avg_duration: number;
  hours: number;
}

interface Statistics {
  daily?: Record<string, DayStats>;
  status: {
    [key: number]: string;
  };
}

const Statistics: React.FC<ComponentProps> = ({ modalProps, weekStart }) => {
  const startDate = dayjs(weekStart, dateInputFieldFormat);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day'));

  const dispatch = useAppDispatch();
  const stats = useAppSelector(selectStatistics) as Statistics;
  const statuses = useAppSelector(selectAppointmentStatuses);

  useEffect(() => {
    dispatch(getStatistics({ week_start: weekStart }));
    dispatch(getStatuses());
  }, [weekStart]);

  const dayStats = stats?.daily || {};
  const statusStats = stats?.status || {};

  const displayValue = (value: number) => {
    return value === null || value === 0 || isNaN(value) ? '-' : value;
  };

  const formatTime = (avgMinutes: number) => {
    if (avgMinutes) return formatHourMinutes(avgMinutes);
    return '-';
  };

  const renderRow = (header: string, row: any, key: string) => {
    return (
      <tr key={key}>
        <th>{header}</th>
        <td>{displayValue(row?.count)}</td>
        <td>{displayValue(row?.hours)}</td>
        <td>{displayValue(row?.users)}</td>
        <td>{formatTime(row?.avg_duration)}</td>
      </tr>
    );
  };

  const renderStatuses = () => {
    return (
      <tbody>
        {statuses.map((status) => {
          const statusValue = statusStats[status.id];

          return (
            <tr key={status.id}>
              <td>{status.name}</td>
              <td className="text-center">{displayValue(Number(statusValue))}%</td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <ModalCard {...modalProps} header="Statistics">
      <div className="m-1 p-1">
        <Table size="sm">
          <thead>
            <tr>
              <th>Day</th>
              <th title="Total number of appointments">Count</th>
              <th title="Total appointment hours ">Hours</th>
              <th title="Care Workers available">CW</th>
              <th title="Mean average duration of appointments">Avg T</th>
            </tr>
          </thead>
          <tbody>
            {daysOfWeek.map((day) =>
              renderRow(
                day.format(weeklyPlannerDayDisplayFormat),
                dayStats[day.format(dateInputFieldFormat)],
                day.toISOString(),
              ),
            )}
            {renderRow('Totals', dayStats['total'], 'total')}
          </tbody>
        </Table>
        <Table striped>
          <thead>
            <tr>
              <th>Status</th>
              <th className="text-center">Percentage</th>
            </tr>
          </thead>
          {renderStatuses()}
        </Table>
      </div>
    </ModalCard>
  );
};

export default Statistics;
