import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { State } from 'tsx/types/reducers';
import { typePrefix, getAll } from '../actions/paymentTypes';

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const paymentTypesSlice = createSlice({
  name: 'payment-types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectPaymentTypes = (state: RootState) => state.paymentTypes.rows;

export default paymentTypesSlice.reducer;
