type TagStructure = {
  [key: string]: string | TagStructure;
};

const createTags = <T extends TagStructure>(namespace: string, structure: T): T => {
  return Object.fromEntries(
    Object.entries(structure).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, `${namespace}:${value}`];
      } else {
        return [key, createTags(`${namespace}-${key.toLowerCase()}`, value)];
      }
    }),
  ) as T;
};

export const AuthTags = Object.freeze({
  APPOINTMENTS: createTags('appointments', {
    VIEWALL: 'ViewAll',
    VIEW: 'View',
    UPDATE: 'Update',
    DOWNLOAD: 'Download',
    CANCEL: 'Cancel',
    SUPPORTCOORDINATION: { VIEW: 'View' },
    LEAVEENTRIES: { VIEW: 'View' },
    TRANSPORT: { VIEW: 'View' },
    GROUPSESSIONS: { VIEW: 'View' },
    GROUPS: { VIEW: 'View' },
    REPORTS: {
      REPEATVSACTUALS: { VIEW: 'View' },
    },
    EMAILSCHEDULES: { VIEW: 'View', SEND: 'Send' },
    CLIENTSCHEDULES: { VIEW: 'View', SEND: 'Send' },
    PROVIDERBILLING: { VIEW: 'View', SEND: 'Send' },
    CAREWORKERS: { VIEW: 'View' },
    TIMESHEETS: { VIEW: 'View' },
    ACTIVITIES: { VIEW: 'View' },
  }),
  DASHBOARDS: createTags('dashboards', {
    CAREWORKERS: { VIEW: 'View' },
  }),
  BUDGETS: createTags('budgets', {
    VIEW: 'View',
  }),
  STATEMENTS: createTags('statements', {
    VIEW: 'View',
    HOMECARE: { VIEW: 'View' },
    HOMECAREIPA: { VIEW: 'View' },
    RESIDENTIAL: { VIEW: 'View' },
    CHSP: { VIEW: 'View' },
    HACC: { VIEW: 'View' },
    NATSIFAC: { VIEW: 'View' },
    NDIS: { VIEW: 'View' },
    NDISQUOTES: { VIEW: 'View' },
    NDISINVOICES: { VIEW: 'View' },
    BROKERAGE: { VIEW: 'View' },
    PRIVATE: { VIEW: 'View' },
    RESIDENTIALCDCBUDGET: { VIEW: 'View' },
    ITEMEXPORT: { VIEW: 'View' },
  }),
  TRANSPORT: createTags('transport', {
    VIEW: 'View',
    TRIPS: { VIEW: 'View' },
  }),
  USERS: createTags('users', {
    VIEWALL: 'ViewAll',
    ADMIN: { VIEW: 'View', UPDATE: 'Update' },
    AVAILABILITY: { VIEW: 'View' },
    SMS: { BULKSEND: 'BulkSend' },
    VOLUNTEERS: { VIEW: 'View' },
    TEAMS: { VIEW: 'View' },
  }),
  CLIENTS: createTags('clients', {
    VIEW: 'View',
  }),
  COMPANY: createTags('company', {
    CLIENTNOTES: { VIEW: 'View' },
    CLIENTHOLDPERIODS: { VIEW: 'View' },
    CUSTOMFORMS: { VIEW: 'View' },
    FORMS: { VIEW: 'View' },
    INCIDENTS: { VIEW: 'View' },
    SMS: { BULKSEND: 'BulkSend' },
    CLIENTPORTAL: { VIEW: 'View' },
    CLIENTS: { VIEW: 'View' },
  }),
  EQUIPMENT: createTags('equipments', {
    VIEW: 'View',
  }),
  LEAVEREQUESTS: createTags('leaverequests', {
    VIEW: 'View',
    UPDATE: 'Update',
  }),
  INVOICES: createTags('invoices', {
    VIEW: 'View',
    SUPPLIERS: { VIEW: 'View' },
    SUPPLIERSWORKFLOW: { VIEW: 'View' },
    NDIS: { VIEW: 'View' },
  }),
  PAYROLL: createTags('payroll', {
    VIEW: 'View',
    TCONNECT: { VIEW: 'View' },
  }),
  DOCUMENTS: createTags('documents', {
    VIEW: 'View',
  }),
  REPORTS: createTags('reports', {
    VIEW: 'View',
    DVA: { VIEW: 'View' },
    DVANURSING: { VIEW: 'View' },
    HACCVIC: { VIEW: 'View' },
    HACC: { VIEW: 'View' },
    ALLIEDHEALTH: { VIEW: 'View' },
    SOAP: { VIEW: 'View' },
    ABF: { VIEW: 'View' },
  }),
  SETTINGS: createTags('settings', {
    VIEW: 'View',
    EQUIPMENT: { VIEW: 'View' },
    ADMIN: { VIEW: 'View', UPDATE: 'Update' },
    DEX: { VIEW: 'View', UPDATE: 'Update' },
    HACC: { VIEW: 'View' },
    BANKDETAILS: { VIEW: 'View', UPDATE: 'Update' },
    DVANURSING: { VIEW: 'View', UPDATE: 'Update' },
    HRMATRIX: { VIEW: 'View', UPDATE: 'Update' },
    BILLINGMATRIX: { VIEW: 'View', UPDATE: 'Update' },
    PACKAGERESTRICTIONS: { VIEW: 'View', UPDATE: 'Update' },
  }),
});
