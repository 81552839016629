import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';

import { Icon, icons } from 'tsx/components/Icon';

import { selectOptions, toggleOption } from '../../reducers/weeklyPlanner';

const MissingQualificationsViewMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { missingQualificationsExtendedQualifications } = useAppSelector(selectOptions);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any) => (
    <DropdownItem toggle={false} onClick={onClick}>
      <Icon icon={iconToggle ? icons.faEye : icons.faEyeSlash} className="mt-1" />
      <Label className="ms-2 mb-0">{label}</Label>
    </DropdownItem>
  );

  return (
    <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down">
      <DropdownToggle tag="span" className="action-view">
        <Icon icon={icons.faEye} onClick={() => setIsViewOpen(!isViewOpen)} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>View Options</DropdownItem>
        {renderItem(
          () =>
            dispatch(
              toggleOption({
                key: 'missingQualificationsExtendedQualifications',
                toggle: !missingQualificationsExtendedQualifications,
              }),
            ),
          missingQualificationsExtendedQualifications,
          'Detailed Qualifications',
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MissingQualificationsViewMenu;
