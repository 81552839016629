import * as Yup from 'yup';
import { FieldProps } from 'tsx/components/FormFields';

type FormValues = {
  [key: string]: any;
};

const generateYupSchema = (fields: FieldProps): Yup.ObjectSchema<FormValues> => {
  const schema: Record<string, Yup.AnySchema> = {};

  Object.keys(fields).forEach((key) => {
    const field = fields[key];
    const fieldKey = field.field ?? key;

    let validator: Yup.MixedSchema<any> = Yup.mixed().nullable(); // Allow null values

    // Apply the required rule
    if (field.required && (field.type !== 'readonly' || 'multi-readonly')) {
      validator = validator.required(`${field.caption || fieldKey} is required`);

      validator = validator.test('not-empty', `${field.caption || fieldKey} cannot be empty`, function (value) {
        if (value === null || value === undefined) return false;
        if (typeof value === 'string' && value.trim() === '') {
          return false; // Fail if the value is an empty string
        }
        if (Array.isArray(value) && value.length === 0) {
          return false; // Fail if the value is an empty array
        }
        return true; // Pass for other cases
      });
    }

    if (field.validate) {
      validator = validator.test('custom-validation', `${field.caption || fieldKey} is invalid`, function () {
        const { parent } = this;

        if (typeof field.validate === 'function') {
          const result = field.validate(parent);

          if (typeof result === 'string') {
            console.log(field);
            return this.createError({ message: result });
          }

          return result;
        }

        // escape if validate doesn't contain a function for some reason
        return true;
      });
    }

    if (field.validateOnSave) {
      validator = validator.test('custom-validation', `${field.caption || key} is invalid`, function () {
        const { parent } = this;

        if (typeof field.validateOnSave === 'function') {
          const result = field.validateOnSave(parent);

          if (typeof result === 'string') {
            console.log(field);
            return this.createError({ message: result });
          }

          return result;
        }

        // escape if validate doesn't contain a function for some reason
        return true;
      });
    }

    if (field.show) {
      validator = validator.when([], {
        is: () => {
          validator = validator.test('show-validation', `${field.caption || key} is invalid`, function () {
            const { parent } = this;
            if (typeof field.show === 'function') {
              const result = field.show(parent);

              if (typeof result === 'string') {
                console.log(field);
                return this.createError({ message: result });
              }

              return result;
            }
          });
        },
        then: (schema) => schema,
        otherwise: (schema) => schema.notRequired(),
      });
    }

    if (field.type !== 'readonly' && field.type !== 'multi-readonly') {
      schema[fieldKey] = validator;
    }
  });

  return Yup.object().shape(schema);
};

export default generateYupSchema;
