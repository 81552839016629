import { useAppSelector } from 'hooks';
import { FieldProp } from 'tsx/components/FormFields';
import { Row } from 'tsx/types/reducers';

export const getNestedValue = (field: string, row: { [key: string]: any }): string | number | null => {
  let parent = row;

  for (const key of field.split('.').values()) {
    parent = key && parent instanceof Object ? parent[key] : null;
  }

  return parent instanceof Object ? null : parent;
};

export const deriveOptionValue = ({ options }: FieldProp, value: any) =>
  options && options.length > 0 ? options?.find((option) => option.id === value)?.name : value;

export const deriveSelectorValue = ({ selectorOptions }: FieldProp, value: any) => {
  let options: Array<Row> = [];
  if (selectorOptions !== undefined) options = useAppSelector(selectorOptions);

  if (options.length > 0) {
    return options.find((option) => option.id === parseInt(value))?.name || '';
  }

  return '';
};

export const deriveBooleanValue = (value: boolean | null | undefined) => {
  if (value === null || value === undefined) return '';
  return value ? 'Yes' : 'No';
};
