import React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import { useAppSelector } from 'hooks';
import { InputProps } from 'tsx/components/FormFields';

interface ComponentProps extends InputProps {
  ignorePlaceholder?: boolean;
  styles?: any;
  getOptionStyle?: (option: Option) => React.CSSProperties;
}

interface Option {
  value: string | number;
  label: string;
  [key: string]: any;
}

const Select: React.FC<ComponentProps> = ({
  id,
  value,
  placeholder,
  ignorePlaceholder = false,
  className,
  disabled = false,
  options,
  selectorOptions,
  onChange,
  styles,
  getOptionStyle,
}) => {
  let selectOptions: Option[] = [];
  if (options) {
    selectOptions = options.map(({ id, name, ...props }) => ({ value: id, label: name, ...props }));
  } else if (selectorOptions) {
    const rows = useAppSelector(selectorOptions);
    selectOptions = rows.map(({ id, name, ...props }) => ({ value: id, label: name, ...props }));
  }

  const customStyles: StylesConfig<Option> = {
    option: (provided, state) => ({
      ...provided,
      ...(getOptionStyle ? getOptionStyle(state.data as Option) : {}),
    }),
    ...styles,
  };

  return (
    <ReactSelect
      id={id}
      name={id}
      value={selectOptions.find((option) => option.value === value)}
      isDisabled={disabled}
      options={selectOptions}
      className={className}
      classNamePrefix={'form-input-select'}
      onChange={(option: any) => onChange && onChange(id, option?.value)}
      placeholder={!ignorePlaceholder ? placeholder ?? '-' : undefined}
      styles={customStyles}
    />
  );
};

export default Select;
