import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import { deriveOptionValue, deriveSelectorValue, deriveBooleanValue } from 'tsx/libs/records';

const ReadOnly: React.FC<InputProps> = ({ value, id, options, selectorOptions, className, link = '' }) => {
  // Build caption based on value, derived value or selector value.
  // Prioritise selectorOptions over options when building
  let caption;
  if (options) caption = deriveOptionValue({ options }, value);
  else if (selectorOptions) {
    caption = deriveSelectorValue({ selectorOptions }, value);
  } else if (typeof value === 'boolean') {
    caption = deriveBooleanValue(value);
  } else {
    caption = value;
  }

  return (
    <div id={id} className={`${className !== undefined ? className : ''}`}>
      {link ? (
        <Link to={link}>
          <Button type="button" size="sm" className="mb-2 btn btn-success btn-sm">
            {caption}
          </Button>
        </Link>
      ) : (
        <span>{caption}</span>
      )}
    </div>
  );
};

export default ReadOnly;
