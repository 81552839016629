import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { FieldType } from 'tsx/components/FormFields';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);

// static format variations

// preferred API formats
export const dateInputFieldFormat = 'YYYY-MM-DD';
export const timeValueFormat = 'HH:mm';
export const defaultMinuteInterval = 5;

// preferred FE display formats
export const dateDisplayFormat = 'DD/MMM/YYYY';
export const datePickerDisplayFormat = 'DD/MM/YYYY';
const timeDisplayFormat = 'HH:mm A';
export const weeklyPlannerDayDisplayFormat = 'ddd (DD/MMM)';

// legacy garbage (╯°□°）╯︵ ┻━┻
const weekdayFormat = 'dddd';
export const dayFormatShort = 'ddd';
const timezoneOffset = '+10:00';
export const timeFormatWithSeconds = 'HH:mm:ss';
export const catchAllStartDate = '1990-01-01';
export const catchAllEndDate = '2050-12-31';

export { Dayjs };

export const formatDateTimeValue = (type: FieldType | undefined, value: any) => {
  switch (type) {
    case 'date': {
      if (value?.length == 10) return dayjs(value).format(dateInputFieldFormat);
      return value;
    }
    case 'time': {
      if (value?.length == 5) return dayjs(value, timeValueFormat).format(timeFormatWithSeconds);
      return value;
    }
    default: {
      return value;
    }
  }
};

export const formatWeekday = (dayIndex: number, useShortFormat = false): string => {
  const formatType = useShortFormat ? dayFormatShort : weekdayFormat;
  return dayjs().day(dayIndex).format(formatType);
};

export const getDayString = (dateValue: any): string => {
  return dateValue ? dayjs(dateValue.toString()).format(dayFormatShort) : '';
};

export function getNextMonday() {
  const today = dayjs();
  const monday = today.day() === 1 ? today.add(1, 'week') : today.day(8);
  return monday.format(dateInputFieldFormat);
}

export const formatDayString = (dateValue: any): string => {
  return dateValue ? dayjs(`${dateValue.toString().slice(0, -1)}${timezoneOffset}`).format(dateDisplayFormat) : '';
};

export const formatDateString = (dateValue: any): string => {
  return dateValue ? dayjs(dateValue.toString()).format(dateDisplayFormat) : '';
};

export const formatTimeString = (timeValue: any): string => {
  if (!timeValue) return '';
  let parsedTime;

  if (dayjs.isDayjs(timeValue)) {
    parsedTime = timeValue;
  } else {
    const formattedValue = formatDateTimeValue('time', timeValue.toString());
    parsedTime = dayjs(formattedValue, timeValueFormat);
  }

  return parsedTime.isValid() ? parsedTime.format(timeDisplayFormat) : '';
};

export const getTime = (timeValue: any, offsetMinutes?: number, date?: string): Dayjs => {
  let timeVal = dayjs(formatDateTimeValue('time', timeValue), timeValueFormat);
  if (date) timeVal = dayjs(`${date} ${timeValue}`, 'YYYY-MM-DD HH:mm');
  if (!offsetMinutes) return timeVal;
  return timeVal.add(offsetMinutes, 'minutes');
};

export const getDate = (value: any) => dayjs(value).format(dateInputFieldFormat);

// Check if the first day is in the same week of the second day
export const isInSameWeek = (a: Dayjs, b: Dayjs | string) => (b === null ? false : a.isSame(b, 'week'));
export const isBeforeDay = (a: Dayjs, b: Dayjs | string) => (b === null ? false : a.isBefore(b, 'day'));
export const isInSameOrAfterWeek = (a: Dayjs, b: Dayjs | string) => (b === null ? false : a.isSameOrAfter(b, 'week'));

export const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const units = [];
  if (hours > 0) units.push(`${hours}h`);
  const displayMinutes = Math.floor(minutes - hours * 60);
  units.push(`${displayMinutes}m`);
  return units.join(' ');
};

// Format time to HH:mm string
export const formatHourMinutes = (total: number) => {
  const hours = Math.floor(total / 60);
  const minutes = total % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
