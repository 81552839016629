import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useAppDispatch, useAppSelector } from 'hooks';
import { InputProps } from 'tsx/components/FormFields';

interface Option {
  value: string | number | undefined;
  label: string | undefined;
}

const SelectLazy: React.FC<InputProps> = ({
  id,
  value,
  disabled,
  placeholder,
  initialOption,
  selectorOptions,
  selectorActions,
  selectorParams,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const fetchedSelectorOptions = useAppSelector(selectorOptions || (() => []));
  const initVal: Option | null = initialOption ? { value: initialOption.id, label: initialOption.name } : null;
  const options = fetchedSelectorOptions.map(({ id, name }) => ({ value: id, label: name }) as Option);

  const loadOptions = async (inputValue: string) => {
    const updatedOptions = options.filter((option) => option.label?.toLowerCase().includes(inputValue.toLowerCase()));
    return updatedOptions;
  };

  const handleFocus = () => {
    if (!optionsLoaded) {
      selectorActions &&
        dispatch(selectorActions(selectorParams))
          .then(() => {
            setOptionsLoaded(true);
          })
          .catch((error: any) => {
            console.log(`Error loading options for ${id} : ${error}`);
          });
    }
  };

  return (
    <AsyncSelect
      id={id}
      name={id}
      classNamePrefix={'form-input-select'}
      isDisabled={disabled}
      placeholder={placeholder ?? '-'}
      value={options.find((option) => option.value === value) ?? initVal}
      loadOptions={loadOptions}
      onFocus={handleFocus}
      onChange={(selected, event) => {
        onChange && onChange(event.name ?? '', selected?.value);
      }}
      defaultOptions={options}
      cacheOptions
    />
  );
};

export default SelectLazy;
