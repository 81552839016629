import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState, Row } from 'tsx/types/reducers';
import {
  typePrefix,
  getAll,
  getAwardAlerts,
  getConflicts,
  getRejected,
  getAssignableUsers,
  getAwardAlertTypes,
} from '../actions/weeklyPlannerAppointments';

import { AssignableUser, AwardAlert, AwardAlertVisibility } from '../lib/common';
import { addCommonReducers } from 'tsx/libs/reduxUtils';

interface weeklyPlannerAppointmentsState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<any>;
  rejected: Row[];
  conflicts: Row[];
  awardAlerts: Row[];
  assignableUsers: AssignableUser[];
  awardAlertTypes: AwardAlert[];
  awardAlertsVisibilities: AwardAlertVisibility;

  filterOptions?: {
    client_id: number;
    user_id: number;
    suburb: string;
    date: string;
  };
}

const initialState: weeklyPlannerAppointmentsState = {
  loading: 'idle',
  error: null,
  rows: [],
  rejected: [],
  conflicts: [],
  awardAlerts: [],
  assignableUsers: [],
  awardAlertTypes: [],
  awardAlertsVisibilities: {},
};

export const weeklyPlannerAppointments = createSlice({
  name: typePrefix,
  initialState,
  reducers: {
    setFilterOptions(state, action) {
      state.filterOptions = action.payload;
    },
    toggleAwardAlertVisibility(state, action) {
      const { key, toggle } = action.payload;
      state.awardAlertsVisibilities[key] = toggle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConflicts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.conflicts = action.payload.data;
    });
    builder.addCase(getRejected.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rejected = action.payload.data;
    });
    builder.addCase(getAwardAlerts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.awardAlerts = action.payload.data;
    });
    builder.addCase(getAssignableUsers.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.assignableUsers = action.payload.data;
    });
    builder.addCase(getAwardAlertTypes.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.awardAlertsVisibilities = action.payload.data.reduce(
        (accumulator: AwardAlertVisibility, awardAlert: AwardAlert) => {
          accumulator[awardAlert.tag] = true;
          return accumulator;
        },
        {},
      );
      state.awardAlertTypes = action.payload.data;
    });

    addCommonReducers<weeklyPlannerAppointmentsState>(builder, typePrefix, getAll);
  },
});

export const { toggleAwardAlertVisibility } = weeklyPlannerAppointments.actions;

export const { setFilterOptions } = weeklyPlannerAppointments.actions;
export const selectAll = (state: RootState) => state.weeklyPlannerAppointments.rows;
export const selectAwardAlerts = (state: RootState) => state.weeklyPlannerAppointments.awardAlerts;
export const selectRejected = (state: RootState) => state.weeklyPlannerAppointments.rejected;
export const selectConflicts = (state: RootState) => state.weeklyPlannerAppointments.conflicts;
export const selectAssignableUsers = (state: RootState) => state.weeklyPlannerAppointments.assignableUsers;
export const selectAwardAlertTypes = (state: RootState) => state.weeklyPlannerAppointments.awardAlertTypes;
export const selectAwardAlertsVisibilities = (state: RootState) =>
  state.weeklyPlannerAppointments.awardAlertsVisibilities;

export default weeklyPlannerAppointments.reducer;
