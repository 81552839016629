import { getNextMonday } from 'tsx/libs/dayjs';

const TPONE_URL = process.env.REACT_APP_TPONE_URL;
const SUPPORT_URL = process.env.REACT_APP_TPONE_SUPPORT_URL;

const NEXT_MONDAY = getNextMonday();

export const getTP1Urls = (user: any, settings: any, companyDefaults: any) => ({
  Home: `${TPONE_URL}/dashboard.asp`,

  APPOINTMENTS: {
    AddSupportCoordination: `${TPONE_URL}/appointment-edit-SUPPORT-COORDINATION.asp?add=yes&uid=${user?.id}&svc=${settings?.SYSTEM_SupportCoordinationServiceTypeID ?? 0}`,
    AddLeaveEntry: `${TPONE_URL}/appointment-edit-LEAVE-ENTRY.asp?add=yes&svc=${companyDefaults?.leave_entry_service_id ?? 0}&cid=${companyDefaults?.leave_entry_client_id ?? 0}`,
    AddGroupSession: `${TPONE_URL}/appointment-edit.asp?add=yes&cid=${settings?.SYSTEM_2022_GROUP_SESSION_CLIENT_ID ?? 0}&multiclient=yes`,
    AddGroupAppointment: `${TPONE_URL}/appointment-edit.asp?add=yes&cid=${settings?.SYSTEM_2022_GROUP_SESSION_CLIENT_ID ?? 0}&groupSessionOverride=1&uid=${settings?.SYSTEM_2022_GROUP_SESSION_USER_ID ?? 0}`,
    AddGroupRepeat: `${TPONE_URL}/appointment-edit.asp?add=yes&cid=${settings?.SYSTEM_2022_GROUP_SESSION_CLIENT_ID ?? 0}&groupSessionOverride=1&uid=${settings?.SYSTEM_2022_GROUP_SESSION_USER_ID ?? 0}&AppointmentRepeat=yes`,
    ViewGroupAppointments: `${TPONE_URL}/appointments-all.asp?posted=yes&fld253=${settings?.SYSTEM_2022_GROUP_SESSION_CLIENT_ID ?? 0}&fld32=${settings?.SYSTEM_2022_GROUP_SESSION_USER_ID ?? 0}&psize=20&ob=&sbmt=yes`,
    RepeatsCalendar: `${TPONE_URL}/appointment-repeat-grouped.asp`,
    Overlaps: `${TPONE_URL}/report-appts-overlap.asp`,
    Compliance: `${TPONE_URL}/appts-compliance-report.asp`,
    Timesheets: `${TPONE_URL}/appointment-daily-schedule.asp`,
    PrintableSchedule: `${TPONE_URL}/print-daily-schedule.asp`,
    EmailSchedule: `${TPONE_URL}/email-appts-CW.asp`,
    ClientSchedule: `${TPONE_URL}/email-appts-CLIENT.asp`,
    WeeklyPlanner: `${TPONE_URL}/appointment-planner-HV.asp`,
    NextWeek: `${TPONE_URL}/appointment-planner-HV.asp?tgd=${NEXT_MONDAY}`,
    CareworkerDashboard: `${TPONE_URL}/dashboard.asp?uo=careW`,
    RepeatsVsActuals: `${TPONE_URL}/report-repeats-vs-actuals.asp`,
    AvailabilityEditor: `${TPONE_URL}/availability-editor.asp`,
  },

  BUDGETSSTATEMENTS: {
    Residential: `${TPONE_URL}/generate-statements-RESIDENTIAL.asp`,
    HomeCareIPA: `${TPONE_URL}/generate-statements-HOMECARE-IPA.asp`,
    Claims: `${TPONE_URL}/client-claims-ipa.asp`,
    ITFBalances: `${TPONE_URL}/client-claims-ITFs.asp?posted=yes`,
    PreIPAStatements: `${TPONE_URL}/generate-statements-HOMECARE.asp?MonthHop=01/Jul/2021`,
    UnspentFunds: `${TPONE_URL}/client-claims-opening-subsidies.asp?posted=yes`,
    LowBalances: `${TPONE_URL}/client-HCP-low-balances.asp`,
    CHSP: `${TPONE_URL}/generate-statements-CHSP.asp`,
    HACC: `${TPONE_URL}/generate-statements-HACC.asp`,
    NATSIFAC: `${TPONE_URL}/generate-statements-NATSIFAC.asp`,
    NDIS: `${TPONE_URL}/client-NDIS-statements-all.asp`,
    NDISQuotes: `${TPONE_URL}/ndis-quotes.asp`,
    NDISInvoiceProgress: `${TPONE_URL}/client-NDIS-invoice-progress.asp`,
    Brokerage: `${TPONE_URL}/generate-statements-BROKERAGE.asp`,
    FeeForService: `${TPONE_URL}/generate-statements-PRIVATE.asp`,
    CDCResBudget: `${TPONE_URL}/CDC-RES-BUDGET-TEMPLATE.asp`,
    StatementItemExport: `${TPONE_URL}/export-statement-items.asp`,
    ItemsGrouped: `${TPONE_URL}/statement-items-grouped.asp`,
    StatementArchive: `${TPONE_URL}/client-statements.asp?posted=yes&fld695=False`,
    StatementItemSearch: `${TPONE_URL}/client-statement-items.asp?posted=yes&fld705=False`,
    AllBudgets: `${TPONE_URL}/client-budgets-all.asp`,
  },

  TRANSPORT: {
    TransportAppointment: `${TPONE_URL}/appointment-edit.asp?eid=&add=yes&TasksShow=no&ServiceNameSelected=Transport&QualificationNameSelected=Transport`,
    GroupTrips: `${TPONE_URL}/client-trips.asp`,
    TripPoints: `${TPONE_URL}/client-trip-points.asp`,
  },

  CAREWORKERS: {
    CareWorkers: `${TPONE_URL}/carers.asp?posted=yes&fld57=False`,
    StandardAvailability: `${TPONE_URL}/availability-by-carer.asp`,
    AvailabilityEntries: `${TPONE_URL}/availability-staff.asp?posted=yes&fld524=False`,
    BulkSms: `${TPONE_URL}/users-message-broadcast.asp`,
    VolunteerMatching: `${TPONE_URL}/client-preferences-match.asp`,
    VolunteerSearch: `${TPONE_URL}/volunteer-search.asp`,
    Teams: `${TPONE_URL}/user-teams.asp`,
    LocationMap: `${TPONE_URL}/carers-map.asp`,
    UserAdmin: `${TPONE_URL}/users-all.asp?posted=yes&fld57=False`,
  },

  CLIENTINFO: {
    ViewAll: `${TPONE_URL}/clients-view.asp`,
    Incidents: `${TPONE_URL}/incidents.asp`,
    SOAPNotes: `${TPONE_URL}/appts-notes-report.asp`,
  },

  COMPANYCRM: {
    Clients: `${TPONE_URL}/clients.asp?posted=yes`,
    AddNote: `${TPONE_URL}/note-add.asp`,
    NotesSearch: `${TPONE_URL}/notes.asp?fld331=False&ShowExtraRow_OR=no`,
    OnHoldPeriods: `${TPONE_URL}/respite-periods.asp?posted=yes&fld422=False`,
    PackageSchedules: `${TPONE_URL}/client-package-schedules.asp?posted=yes&fld264=False`,
    CustomForms: `${TPONE_URL}/custom-forms.asp`,
    Forms: `${TPONE_URL}/forms-type.asp`,
    IncidentsHazards: `${TPONE_URL}/incidents.asp`,
    BulkSmsClients: `${TPONE_URL}/clients-message-broadcast.asp`,
    PortalUsers: `${TPONE_URL}/client-portal-users.asp?posted=yes&fld1022=False`,
    PortalNotifications: `${TPONE_URL}/client-portal-users-notify-appts-CLIENT.asp`,
    PortalUsersList: `${TPONE_URL}/portal-users-active-count.asp`,
  },

  EQUIPMENT: {
    StockLevels: `${TPONE_URL}/equpment-levels.asp`,
    CareWorkerUsage: `${TPONE_URL}/equipment-usage.asp`,
    Medications: `${TPONE_URL}/medications-usage.asp`,
  },

  LEAVEREQUESTS: {
    NewRequest: `${TPONE_URL}/leave-request-edit.asp`,
    PendingRequests: `${TPONE_URL}/leave-requests-all.asp?posted=yes&fld290=1`,
    AllRequests: `${TPONE_URL}/leave-requests-all.asp?posted=yes`,
  },

  INVOICES: {
    InvoiceExport: `${TPONE_URL}/invoice-export.asp`,
    ClientInvoices: `${TPONE_URL}/invoices.asp`,
    SupplierInvoices: `${TPONE_URL}/invoices-suppliers.asp`,
    SupplierGenerate: `${TPONE_URL}/generate-supplier-invoice-preview.asp`,
    Suppliers: `${TPONE_URL}/suppliers.asp?posted=yes&fld761=False`,
    AppointmentStatus: `${TPONE_URL}/invoices-pending-appts.asp`,
    SupplierInvoicesWorkflow: `${TPONE_URL}/invoices-suppliers-workflow.asp`,
    NDISInvoices: `${TPONE_URL}/invoices-NDIS.asp`,
    GenerateNDISInvoices: `${TPONE_URL}/invoice-export-NDIS.asp`,
  },

  PAYROLL: {
    PayrollExport: `${TPONE_URL}/payroll-export.asp`,
    PayrollCategories: `${TPONE_URL}/payroll-cats.asp?posted=yes&fld578=False`,
    PayGroups: `${TPONE_URL}/user-pay-groups.asp?posted=yes`,
    GroupsCustom: `${TPONE_URL}/pay-groups-custom.asp`,
    AppointmentStatus: `${TPONE_URL}/payroll-pending-appts.asp`,
    PayrollEntries: `${TPONE_URL}/payroll.asp`,
    NewPayrollItem: `${TPONE_URL}/payroll-generator.asp?add=yes`,
    TConnect: `${TPONE_URL}/tconnect.asp`,
  },

  DOCUMENTS: {
    AllDocuments: `${TPONE_URL}/documents.asp`,
    NewDocument: `${TPONE_URL}/document-details.asp?add=yes`,
    FolderAdmin: `${TPONE_URL}/folders.asp?posted=yes&fld805=False`,
  },

  REPORTS: {
    CovidVaccines: `${TPONE_URL}/report-COVID-notes.asp`,
    VHCPortal: `${TPONE_URL}/appointments-export-vhc1.asp`,
    DVANursing: `${TPONE_URL}/appointments-export-DVA-Nursing.asp`,
    HACCVIC: `${TPONE_URL}/appointments-export-HACC-VIC.asp`,
    MDSFormatHACC: `${TPONE_URL}/appointments-export-HACC1.asp`,
    AlliedHealth: `${TPONE_URL}/appointments-export-allied.asp`,
    SOAPNotes: `${TPONE_URL}/appts-notes-report.asp`,
    DEX: `${TPONE_URL}/appointments-export-dex1.asp`,
    ABF: `${TPONE_URL}/appointments-export-ABF.asp`,
    DocumentChecklist: `${TPONE_URL}/report-doc-checklist.asp`,
    PhotoSearch: `${TPONE_URL}/appts-photos-search.asp`,
    SchedulesChart: `${TPONE_URL}/client-package-schedules-chart.asp`,
    TimeVarianceReview: `${TPONE_URL}/timevar-review.asp`,
    TravelVarianceReview: `${TPONE_URL}/travelvar-review.asp`,
    QualificationsReview: `${TPONE_URL}/qualifications-by-carer.asp`,
    SystemActivity: `${TPONE_URL}/activity.asp`,
    MailMerge: `${TPONE_URL}/mail-merge.asp`,
    ClientReviewReport: `${TPONE_URL}/report-service-review.asp`,
    ClientPreferencesAll: `${TPONE_URL}/report-client-prefs-all.asp`,
    GroupedSummaries: `${TPONE_URL}/report-grouped-summaries.asp`,
  },

  SETTINGSCONFIG: {
    TechSupport: `${TPONE_URL}/support.asp`,
    EventsAdmin: `${TPONE_URL}/events.asp?posted=yes&fld517=False`,
    BillingGroups: `${TPONE_URL}/client-billing-groups.asp?posted=yes&fld781=False`,
    Qualifications: `${TPONE_URL}/qualification-levels.asp?posted=yes&fld445=False`,
    TaskTypes: `${TPONE_URL}/tasktypes-all.asp?posted=yes&fld269=False`,
    Packages: `${TPONE_URL}/client-packages.asp?posted=yes&fld567=False`,
    ServiceTypes: `${TPONE_URL}/service-types.asp?posted=yes&fld586=False`,
    BillingPayRules: `${TPONE_URL}/service-type-rules.asp?posted=yes&fld624=False`,
    BudgetItems: `${TPONE_URL}/budget-items.asp`,
    Referrers: `${TPONE_URL}/client-refs.asp?posted=yes&fld563=False`,
    StockLevels: `${TPONE_URL}/equpment-levels.asp`,
    Medications: `${TPONE_URL}/medications-usage.asp`,
    DEXConfiguration: `${TPONE_URL}/dex-service-settings.asp`,
    DEXAllClients: `${TPONE_URL}/client-settings-all-DEX-DETAILS.asp`,
    InfoSheetAllClients: `${TPONE_URL}/client-settings-all-INFO.asp`,
    HACCAllClients: `${TPONE_URL}/client-settings-all-HACC-DETAILS.asp`,
    BankDetailsAllClients: `${TPONE_URL}/client-settings-all-BANK-DETAILS.asp`,
    DVANursingSettings: `${TPONE_URL}/client-settings-all-DVA-Nursing.asp`,
    HRCodeMatrix: `${TPONE_URL}/payroll-config-HR.asp`,
    BillingCodeMatrix: `${TPONE_URL}/invoice-config-codes.asp`,
    PackageRestrictions: `${TPONE_URL}/package-service-restrictions.asp`,
    Notifications: `${TPONE_URL}/system-notifications.asp`,
    ProcessGuides: `${TPONE_URL}/docs-PDF-guides.asp`,
    SystemConfiguration: `${TPONE_URL}/system-configuration.asp`,
    KnowledgeBase: `${SUPPORT_URL}`,
    SystemInfo: `${TPONE_URL}/system.asp`,
  },
});
