import React from 'react';
import { InputProps } from 'tsx/components/FormFields';

function FormInputTimeMarked({ value }: InputProps) {
  const start_time_marked = value?.start_time_marked || 'Start time not set';
  const end_time_marked = value?.end_time_marked || 'End time not set';

  return (
    <div className="d-flex flex-column">
      <div>{start_time_marked}</div>
      <div>{end_time_marked}</div>
    </div>
  );
}

export default FormInputTimeMarked;
