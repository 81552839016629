import React, { CSSProperties } from 'react';
import { BeatLoader, GridLoader } from 'react-spinners';

export interface Props {
  size?: number;
  margin?: number;
  color?: string;
  loading: boolean;
  className?: string;
  speed?: number;
  type?: 'grid' | 'beat';
  style?: CSSProperties;
}

function Spinner({ type = 'grid', size = 20, className = '', speed = 1, style, ...props }: Props) {
  let loader;
  switch (type) {
    case 'beat': {
      loader = <BeatLoader size={size} speedMultiplier={speed} {...props} />;
      break;
    }
    case 'grid':
    default: {
      loader = <GridLoader size={size} speedMultiplier={speed} {...props} />;
      break;
    }
  }

  return (
    <div className={`spinner d-flex justify-content-center ${className}`} style={style} data-id="loading-spinner">
      {loader}
    </div>
  );
}

export default Spinner;
