import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';

import editFields from './forms/userEdit';

import Button from 'tsx/components/Button';
import ContainerLoading from 'tsx/components/ContainerLoading';
import ContainerHeader from 'tsx/components/ContainerHeader';
import { Fields, FieldProps } from 'tsx/components/FormFields';
import { Icon, icons } from 'tsx/components/Icon';

import { getOne, update } from 'tsx/features/main/actions/users';
import { getAll as getStates } from 'tsx/features/main/actions/states';
import { getAll as getUserDepartments } from './actions/userDepartments';
import { getAll as getUserPayGroups } from './actions/userPayGroups';
import { getAll as getUserPayLevels } from './actions/userPayLevels';
import { getAll as getAllowances } from '../main/actions/userAllowances';
import { setTitle } from 'tsx/features/main/actions/login';

import generateYupSchema from 'tsx/libs/yupValidation';
import { clearAddressFields, selectAddressFields } from 'tsx/features/main/reducers/addressLookup';
import { clearUser, selectCurrentUser, selectUsersLoading } from 'tsx/features/main/reducers/users';

const User: React.FC = () => {
  const [invalidFields, setInvalidFields]: [string[], any] = useState([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Link record from selector to component
  const user = useAppSelector(selectCurrentUser);
  const userLoading = useAppSelector(selectUsersLoading);

  const fieldDefinitions = Object.entries(editFields).map(([key, props]) => {
    return {
      key,
      ...props,
    };
  });

  useEffect(() => {
    setTitle(`Edit User`);
    // Get user by ID on mount
    dispatch(getOne({ id, attributes: Object.keys(editFields) }));

    // Get selector values on mount
    dispatch(getUserDepartments());
    dispatch(getUserPayGroups());
    dispatch(getUserPayLevels());
    dispatch(getStates());
    dispatch(getAllowances());

    // Clean component on unmount
    return () => {
      dispatch(clearAddressFields());
      dispatch(clearUser());
    };
  }, []);

  useEffect(() => {
    setTitle(`Edit ${user.full_name}`);
  }, [user]);

  const initVals =
    user ||
    Object.keys(editFields).reduce(
      (acc, key) => {
        const field = editFields[key].field;
        if (field) {
          acc[field] = '';
        }
        return acc;
      },
      {} as Record<string, any>,
    );

  const onBack = () => {
    navigate('/users');
  };

  const onSubmit = async (values: any) => {
    console.log(values);
    const { payload } = await dispatch(update({ id, ...values }));
    if (payload?.success) {
      navigate('/users');
    }
  };

  // Perform deep equality check on changed values, will be replaced when model mapping is introduced (╯°□°）╯︵ ┻━┻
  const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T) => {
    return Object.entries(values).reduce((records: Partial<T>, [key, value]) => {
      if (!isEqual(initialValues[key], value)) records[key as keyof T] = value;
      return records;
    }, {});
  };

  const AddressUpdater = (setFieldValue: any) => {
    const states = useAppSelector((state: RootState) => state.states);
    const { address, suburb, postcode, state } = useAppSelector(selectAddressFields);

    useEffect(() => {
      if (address) setFieldValue('address', address);
      if (suburb) setFieldValue('suburb', suburb);
      if (postcode) setFieldValue('postcode', postcode);
      if (state) {
        const stateId = states.rows.find((record) => record.name === state)?.id;
        if (stateId) setFieldValue('state_id', stateId);
      }
    }, [address, suburb, postcode, state, setFieldValue]);

    return null;
  };

  const isLoading = userLoading === 'pending';

  const fieldsObject: FieldProps = fieldDefinitions.reduce((acc, field) => {
    const { key, ...fieldProps } = field;
    acc[key] = fieldProps;
    return acc;
  }, {} as FieldProps);

  const validationSchema = generateYupSchema(fieldsObject);

  const handleYupValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setInvalidFields([]);
    } catch (err: any) {
      if (err.inner) {
        const errors = err.inner.map((e: any) => e.path);
        console.log(errors);
        setInvalidFields(errors);
      }
    }
  };

  return (
    <div className="p-2">
      {!isLoading && (
        <>
          <ContainerHeader className="warning">
            <span>User Details</span>
          </ContainerHeader>
          <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2">
            <div>
              <Button size="sm" className="me-2" onClick={() => onBack()}>
                <Icon className="me-2" icon={icons.faArrowLeft} />
                Back to User Listing
              </Button>
              <Button size="sm" className="me-2" disabled={true}>
                Care Worker Summary
              </Button>
              <Button size="sm" className="me-2" disabled={true}>
                Show Password Field
              </Button>
            </div>
          </div>
          <ContainerLoading loading={isLoading} bgColor="white" color="black" isTransparent={false}>
            <Formik
              enableReinitialize
              initialValues={initVals}
              validationSchema={validationSchema}
              validate={handleYupValidation}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(getChangedValues(values, initVals));
                setSubmitting(false);
              }}
            >
              {({ values, setFieldValue, setFieldTouched, touched, isSubmitting }) => (
                <Form>
                  <Fields
                    definitions={fieldDefinitions}
                    row={user}
                    invalidFields={invalidFields}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                  />
                  <AddressUpdater setFieldValue={setFieldValue} />
                  <div>
                    <Button
                      size="sm"
                      disabled={isSubmitting}
                      className="ms-4 me-4 ps-4 pe-4"
                      color="success"
                      type="submit"
                    >
                      Update User
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ContainerLoading>
        </>
      )}
    </div>
  );
};

export default User;
