import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiClients } from 'tsx/constants/api';

export const typePrefix = 'clients';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiClients,
    method: get,
    actionParams: {
      disabled: false,
      attributes: ['id', 'full_name', 'default_package_id', 'non_chargeable', 'address', 'preferences', 'description'],
      sort: ['non_chargeable.desc', 'full_name'],
      limit: 0,
    },
  },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiClients}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
