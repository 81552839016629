import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiTaskClassifications } from 'tsx/constants/api';

export const typePrefix = 'tasks/classifications';

const config = [
  { actionType: 'getAll', apiPath: apiTaskClassifications, method: get, actionParams: { limit: 0 } },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiTaskClassifications}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
