import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { formatDateString } from 'tsx/libs/dayjs';
import { deriveBooleanValue } from 'tsx/libs/records';

const columns: ColumnDef<any>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    cell: (info) => info.getValue(),
    enableHiding: true,
  },
  {
    id: 'full_name',
    accessorKey: 'full_name',
    header: 'Full Name',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
    meta: {
      link: ({ id }: any) => `/users/${id}`,
      hoverIcon: faEdit,
    },
  },
  {
    id: 'date_last_login',
    accessorKey: 'date_last_login',
    header: 'Last Login',
    cell: (info) => formatDateString(info.getValue()),
    sortUndefined: 'last',
    sortingFn: 'datetime',
  },
  {
    id: 'level',
    accessorFn: (row) => row.level?.name ?? '',
    header: 'User Level',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'department',
    accessorFn: (row) => row.department?.name ?? '',
    header: 'Team',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'suburb',
    accessorKey: 'suburb',
    header: 'Suburb',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'postcode',
    accessorKey: 'postcode',
    header: 'Postcode',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'gender',
    accessorFn: (row) => row.gender?.name ?? '',
    header: 'Gender',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'accounts_access_enabled',
    accessorKey: 'accounts_access_enabled',
    header: 'Accounts Access Enabled',
    cell: (info) => deriveBooleanValue(info.getValue() as boolean),
    sortUndefined: 'last',
  },
  {
    id: 'unlocking_permissions',
    accessorKey: 'unlocking_permissions',
    header: 'Unlocking Allowed',
    cell: (info) => deriveBooleanValue(info.getValue() as boolean),
    sortUndefined: 'last',
  },
];

export default columns;
