import React from 'react';
import { Col, Label, Row } from 'reactstrap';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { formatWeekday } from 'tsx/libs/dayjs';
import { selectAppointmentRepeatFrequencies } from '../reducers/repeatFrequencies';

function FormInputRepeatFrequency({ value, onChange }: InputProps) {
  const { repeat_weekday = '', repeat_week_count = '', skip_on_public_holidays = '' } = value;

  const weekdayOptions = Array.from({ length: 7 }, (_, index) => {
    const day = formatWeekday(index + 1, true);
    return { id: day, name: day };
  });

  return (
    <>
      <Row>
        <Col className="pe-2">
          <FormInput.Select
            type="select"
            name="repeat_weekday"
            id="repeat_weekday"
            value={repeat_weekday ?? ''}
            placeholder="Select week day"
            onChange={onChange}
            options={weekdayOptions}
          />
        </Col>
        <Col>
          <FormInput.Select
            type="select"
            name="repeat_week_count"
            id="repeat_week_count"
            value={repeat_week_count ?? ''}
            placeholder="Select frequency"
            onChange={onChange}
            selectorOptions={selectAppointmentRepeatFrequencies}
          />
        </Col>
      </Row>
      <Row className="m-2">
        <Col>
          <Label className="me-2">Skip on Public Holidays?</Label>
        </Col>
        <Col>
          <FormInput.Switch
            type="switch"
            id="skip_on_public_holidays"
            name="skip_on_public_holidays"
            value={skip_on_public_holidays}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default FormInputRepeatFrequency;
