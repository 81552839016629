import React, { CSSProperties, Fragment } from 'react';
import { useAppSelector } from 'hooks';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';

import AppointmentCard from './AppointmentCard';
import TravelCard from './TravelCard';
import TotalCard from './TotalCard';

import { Availability, Event, Appointment, Travel, Conflict, getAppointmentSameTimeCount } from '../lib/common';
import { selectOptions } from '../reducers/weeklyPlanner';

dayjs.extend(isBetween);

interface ComponentProps {
  id: string;
  events: Event[];
  availability: Availability | null;
  style?: CSSProperties;
}

const DayCell: React.FC<ComponentProps> = ({ id, events, availability, style }) => {
  const { workerAvailability, totalSimple, totalAppointments, totalPredicted, totalDistance, showTravel } =
    useAppSelector(selectOptions);

  const { setNodeRef, isOver, active } = useDroppable({
    id,
  });

  const build = (events: Event[]) => {
    return events
      .map(({ type, data }, index) => {
        switch (type) {
          case 'appointment': {
            return renderAppointments([data], `${type}-${index}`, events);
          }
          case 'conflict': {
            return renderConflicts(data, index);
          }
          case 'travel': {
            return showTravel && renderTravels([data], `${type}-${index}`);
          }
          default: {
            return false;
          }
        }
      })
      .filter(Boolean);
  };

  const renderAppointments = (cards: Appointment[], index: string, events: Event[]) => {
    const conflicts = cards.length;
    const className = classNames('appointment-row', { conflict: conflicts > 1 });
    const style: CSSProperties = {};

    // If more than one appointment in a row, there are conflicts
    // Set row as a grid and show appointments side-by-side
    if (conflicts > 1) {
      const columns = Math.max(...cards.map((card: any) => getAppointmentSameTimeCount(card, cards)));
      style.gridTemplateColumns = `repeat(${columns}, minmax(5%, 1fr)`;
    }

    return (
      <div key={index} className={className} style={style}>
        {cards.map((appointment: any, index: number) => {
          const sameTimeConflicts = getAppointmentSameTimeCount(appointment, cards);
          const style: CSSProperties = {};

          if (conflicts > 0) {
            // Set height of appointment "card" based on how many conflicts exist over multiple start times.
            style.gridRow = `span ${(sameTimeConflicts > 0 ? conflicts - sameTimeConflicts : 0) + 1}`;
          }
          return (
            <AppointmentCard key={index} appointment={appointment} style={style} containerId={id} events={events} />
          );
        })}
      </div>
    );
  };

  const renderTravels = (cards: Travel[], index: string) => {
    const conflicts = cards.length;
    const className = classNames('travel-row', { conflict: conflicts > 1 });

    if (cards.length === 0) return <></>;

    let travel: {
      type: string;
      distance: number | string;
      duration: number | string;
    } = cards[0];

    if (conflicts > 1) {
      const distances = cards.map(({ distance }) => Math.round(distance));
      const durations = cards.map(({ duration }) => Math.round(duration));
      travel = {
        type: 'range',
        distance: `${Math.min(...distances)} - ${Math.max(...distances)}`,
        duration: `${Math.min(...durations)} - ${Math.max(...durations)}`,
      };
    }

    return (
      <div key={index} className={className}>
        <TravelCard travel={travel} />
      </div>
    );
  };

  const renderConflicts = (data: Conflict[], index: number) => {
    // Group all appointments together and render
    // Group all travels together and render a range travel
    const appointments: any[] = [];
    const travels: any[] = [];
    data.forEach((events) =>
      events.forEach(({ type, data }: Event) => {
        switch (type) {
          case 'appointment': {
            appointments.push(data);
            break;
          }
          case 'travel': {
            travels.push(data);
            break;
          }
          default: {
            break;
          }
        }
      }),
    );

    return (
      <Fragment key={index}>
        {renderAppointments(appointments, `appointment-${index}`, events)}
        {showTravel && renderTravels(travels, `travel-${index}`)}
      </Fragment>
    );
  };

  const renderTotals = (events: Event[]) => {
    return (
      <>
        {totalSimple && <TotalCard type="simple" events={events} />}
        {totalAppointments && <TotalCard type="appointments" events={events} />}
        {totalPredicted && <TotalCard type="predicted" events={events} />}
        {totalDistance && <TotalCard type="distance" events={events} />}
      </>
    );
  };

  const isAvailable = availability && availability?.start !== '0000';

  const className = classNames('day-cell d-flex flex-column justify-content-between w-100', {
    available: isAvailable,
    'is-dragging-over': isOver,
    'is-dragging-origin': active?.data?.current?.containerId === id,
  });

  return (
    <div id={id} className={className} style={style}>
      <div>
        {workerAvailability && (
          <div className="availability">
            {isAvailable ? (
              <>
                {availability.start} - {availability.end}
              </>
            ) : (
              <div className="unavailable">
                <strong>Unavailable</strong>
              </div>
            )}
          </div>
        )}

        <div ref={setNodeRef} className="event-rows">
          {build(events ?? [])}
        </div>
      </div>
      {events?.length > 0 && <div className="total-row">{renderTotals(events ?? [])}</div>}
    </div>
  );
};

export default DayCell;
