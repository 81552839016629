import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from 'tsx/libs/dayjs';
import SaveLoad from './SaveLoad';
import Conflicts from './Conflicts';
import Rejected from './Rejected';
import AwardAlerts from './AwardAlerts';
import MissingQualifications from './MissingQualifications';
import UnallocatedAppointments from './UnallocatedAppointments';
import Statistics from './Statistics';

interface ComponentProps {
  date: Dayjs;
}

const AccordianTabs = Object.freeze({
  SAVE_LOAD: '1',
  CONFLICTS: '2',
  REJECTED: '3',
  AWARD_ALERTS: '4',
  STATISTICS: '5',
  QUALIFICATIONS: '6',
  UNALLOCATED: '7',
});

const SideBar: React.FC<ComponentProps> = ({ date }) => {
  const weekStart = date.weekday(0).format(dateInputFieldFormat);
  const modalProps = {
    className: 'rounded-0 mt-1',
    headerClassName: 'rounded-0',
    bodyClassName: 'p-0',
  };

  const [openAccordionId, setOpenAccordion] = useState<string>('');

  const toggle = (accordianId: string) => {
    setOpenAccordion(() => (openAccordionId === accordianId ? '' : accordianId));
  };

  // close accordions to unmount components on date change
  useEffect(() => {
    return () => {
      toggle('');
    };
  }, [date]);

  const { SAVE_LOAD, CONFLICTS, REJECTED, AWARD_ALERTS, STATISTICS, QUALIFICATIONS, UNALLOCATED } = AccordianTabs;

  const renderAccordianItem = (id: string, header: string, body: React.ReactNode) => (
    <AccordionItem>
      <AccordionHeader targetId={id} onClick={() => toggle(id)}>
        {header}
      </AccordionHeader>
      <AccordionBody accordionId={id}>{openAccordionId === id && body}</AccordionBody>
    </AccordionItem>
  );

  return (
    <Accordion open={openAccordionId} toggle={toggle} className="side-bar-container">
      {renderAccordianItem(SAVE_LOAD, 'Save / Load', <SaveLoad weekStart={weekStart} modalProps={modalProps} />)}
      {renderAccordianItem(CONFLICTS, 'Conflicts', <Conflicts weekStart={weekStart} modalProps={modalProps} />)}
      {renderAccordianItem(
        REJECTED,
        'Rejected Appointments',
        <Rejected weekStart={weekStart} modalProps={modalProps} />,
      )}
      {renderAccordianItem(AWARD_ALERTS, 'Award Alerts', <AwardAlerts weekStart={weekStart} modalProps={modalProps} />)}
      {renderAccordianItem(STATISTICS, 'Statistics', <Statistics weekStart={weekStart} modalProps={modalProps} />)}
      {renderAccordianItem(QUALIFICATIONS, 'Missing Qualifications', <MissingQualifications weekStart={weekStart} />)}
      {renderAccordianItem(UNALLOCATED, 'Unallocated Appointments', <UnallocatedAppointments weekStart={weekStart} />)}
    </Accordion>
  );
};

export default SideBar;
