import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { addCommonReducers, CommonState } from 'tsx/libs/reduxUtils';
import { typePrefix, getAll } from '../actions/missingQualificationAppointments';
import { Row } from 'tsx/types/reducers';

interface State extends CommonState<Row> {
  hasLoaded: boolean;
  params: {
    week_start?: string;
    limit: number;
  };
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  params: { limit: 30 },
};

//Slice
export const missingQualificationAppointments = createSlice({
  name: typePrefix,
  initialState,
  reducers: {
    updateParams(state, action) {
      const { ...params } = action.payload;
      const newParams = {
        ...state.params,
        ...params,
      };

      state.params = newParams;
    },
    clear(state) {
      state.params = initialState.params;
      state.rows = initialState.rows;
      state.hasLoaded = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
      state.hasLoaded = true;
    });
    addCommonReducers<Row>(builder, typePrefix);
  },
});

//Actions
export const { clear, updateParams } = missingQualificationAppointments.actions;

//Selectors
export const selectAll = (state: RootState) => state.missingQualificationAppointments.rows;
export const selectLoading = (state: RootState) => state.missingQualificationAppointments.loading;
export const selectHasLoaded = (state: RootState) => state.missingQualificationAppointments.hasLoaded;
export const selectParams = (state: RootState) => state.missingQualificationAppointments.params;

export default missingQualificationAppointments.reducer;
