import React from 'react';
import { LinkProps as reactRouterLinkProps, Link as ReactRouterLink } from 'react-router-dom';

export interface ComponentProps extends reactRouterLinkProps {
  disabled?: boolean;
}

const Link: React.FC<ComponentProps> = ({ disabled, children, ...props }) => {
  return (
    <>
      {disabled && children}
      {!disabled && <ReactRouterLink {...props}>{children}</ReactRouterLink>}
    </>
  );
};

export default Link;
