import React from 'react';
import { Col } from 'reactstrap';

import { formatDuration } from 'tsx/libs/dayjs';
import { Icon, icons } from 'tsx/components/Icon';

import { Event, getEventRange, getEventTotalsRange } from '../lib/common';
import classNames from 'classnames';

interface ComponentProps {
  type: string;
  events: Event[];
  col?: number;
  direction?: 'left' | 'right';
}

const TotalCard: React.FC<ComponentProps> = ({ type, events = [], col = 2, direction = 'left' }) => {
  const duration = getEventTotalsRange(events, type);
  const distance = getEventRange(events, 'distance', 'travel');

  const caption =
    type === 'distance'
      ? `~ ${Math.round(distance[0])}${distance.length > 1 ? ` - ${Math.round(distance[1])}` : ''} kms`
      : `~ ${formatDuration(Math.round(duration[0]))}${duration.length > 1 ? ` - ${formatDuration(Math.round(duration[1]))}` : ''}`;

  const className = classNames('total-card d-flex align-items-center justify-content-between', {
    type: true,
    'flex-row-reverse': direction === 'right',
  });

  let icon;
  let title;

  switch (type) {
    case 'simple': {
      icon = icons.faCalculator;
      title = 'All Totals';
      break;
    }

    case 'predicted': {
      icon = icons.faUserNurse;
      title = 'Predicted Shift';
      break;
    }

    case 'appointments': {
      icon = icons.faBusinessTime;
      title = 'Appointment Totals';
      break;
    }

    case 'distance': {
      icon = icons.faRoute;
      title = ' Totals';
      break;
    }

    default: {
      break;
    }
  }

  return (
    <div className={className}>
      <Col>
        <div
          className="d-flex align-items-center justify-content-end"
          title={`${type === 'distance' ? 'Distance' : 'Duration'}- ${caption}`}
        >
          <div>{caption}</div>
          <Col sm={col} className="mx-1 text-center" title={title ?? ''}>
            {icon && <Icon icon={icon} />}
          </Col>
        </div>
      </Col>
    </div>
  );
};

export default TotalCard;
