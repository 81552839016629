import { useAppSelector } from 'hooks';
import { selectClientByID } from 'tsx/features/main/reducers/clients';
import { catchAllEndDate, formatDateString, formatTimeString, getTime } from 'tsx/libs/dayjs';
import dayjs from 'dayjs';

type Derive = (values: any) => any;
type ReadOnly = (values: any) => string | string[];

export const deriveClientAddress: Derive = ({ client: { address, suburb } = { address: '', suburb: '' } }) => {
  return address !== '' ? `${address}, ${suburb}` : '';
};

export const deriveClientDescription: Derive = ({ client_id }) => {
  const client = useAppSelector((state) => selectClientByID(state, parseInt(client_id)));
  return client?.description;
};

export const deriveClientLink: Derive = ({ client_id }) => {
  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;
  return `${REDIRECT_URL}/client-details.asp?eid=${client_id}`;
};

export const deriveClientPackageName: Derive = ({ client_id }) => {
  const client = useAppSelector((state) => selectClientByID(state, parseInt(client_id)));
  return client?.default_package?.name ?? 'No default package';
};

export const deriveDuration: Derive = ({ start_time, end_time, ends_next_day = false }) => {
  const start = getTime(start_time);
  const end = getTime(end_time);
  const diff = end.diff(start, 'minutes');
  const extraMinutes = ends_next_day ? 1440 : 0;
  return (isNaN(diff) ? 0 : diff) + extraMinutes;
};

export const deriveServiceTypeDescription: Derive = ({ service_type_id, package_fixed }) => {
  const { service_type_ids = [] } = package_fixed ?? {};
  if (!service_type_id || !package_fixed) return '';
  if (!service_type_ids?.includes(service_type_id)) return 'Package and service type are mismatched';
};

export const deriveAccountsDefinition: Derive = ({ accounts_definitions, client_id, service_type_id }) => ({
  ...accounts_definitions,
  client_id,
  service_type_id,
});

export const deriveAccountSettings: Derive = ({
  chargeable_travel,
  chargeable_travel_type,
  break_duration,
  leave_entry_id,
  exclude_from_payroll,
}) => ({
  chargeable_travel,
  chargeable_travel_type,
  break_duration,
  leave_entry_id,
  exclude_from_payroll,
});

export const deriveActualTimes: Derive = ({ start_time_actual, end_time_actual }) => ({
  start_time_actual,
  end_time_actual,
});

export const deriveChargeableCancellation: Derive = ({
  chargeable_cancellation,
  chargeable_cancellation_details,
  duration,
}) => ({
  chargeable_cancellation,
  chargeable_cancellation_details,
  duration,
});

export const deriveDistanceTravelled: Derive = ({ distance_to_client, distance_with_client }) => {
  return {
    distance_to_client,
    distance_with_client,
  };
};

export const deriveTimeMarked: Derive = ({ start_time_marked, end_time_marked }) => {
  return {
    start_time_marked: start_time_marked !== null ? formatTimeString(start_time_marked) : start_time_marked,
    end_time_marked: end_time_marked !== null ? formatTimeString(end_time_marked) : end_time_marked,
  };
};

export const deriveServiceTypeSelectorParams: Derive = ({ package_fixed_id = null, transport = false }) => {
  const params: any = {};
  if (transport) params.allow_transport = true;
  if (package_fixed_id) params.package_id = package_fixed_id;
  return params;
};

const placeholder = 'Not entered';

export const readOnlyStartEndTime: ReadOnly = ({ start_time, end_time }) =>
  `${formatTimeString(start_time)} - ${formatTimeString(end_time)}`;

export const readOnlyTimeMarked: ReadOnly = ({ start_time_marked, end_time_marked }) => [
  start_time_marked !== null ? formatTimeString(start_time_marked) : 'Start time not set',
  end_time_marked !== null ? formatTimeString(end_time_marked) : 'End time not set',
];

export const readOnlyTimeActual: ReadOnly = ({ start_time_actual, end_time_actual }) => [
  `Start: ${start_time_actual !== null ? formatTimeString(start_time_actual) : placeholder}`,
  `End: ${end_time_actual !== null ? formatTimeString(end_time_actual) : placeholder}`,
];

export const readOnlyDistanceTravelled: ReadOnly = ({ distance_to_client, distance_with_client }) => [
  `TO Client: ${distance_to_client !== null ? `${distance_to_client} kms` : placeholder}`,
  `WITH Client: ${distance_with_client !== null ? `${distance_with_client} kms` : placeholder}`,
];

export const readOnlyTimeTravelled: ReadOnly = ({ travel_1 }) => (!travel_1 ? placeholder : `${travel_1} mins`);

export const readOnlyChargeableCancellation: ReadOnly = ({ chargeable_cancellation_details }) => {
  const { client = null, client_unit = null, carer = null } = chargeable_cancellation_details ?? {};
  return [`Charge Client: ${client ?? '-'} ${client_unit ?? ''}`, `Pay Care Worker: ${carer ?? '-'} mins`];
};

export const readOnlyRepeatDates: ReadOnly = ({ repeat_start, repeat_end }) => [
  `Start: ${repeat_start !== null ? formatDateString(repeat_start) : '-'}`,
  `End: ${repeat_end !== null && dayjs(catchAllEndDate) > dayjs(repeat_end) ? formatDateString(repeat_end) : '-'}`,
];

export const readOnlyRepeatFrequency: ReadOnly = ({ repeat_frequency, skip_on_public_holidays }) => [
  `${repeat_frequency?.day}, ${repeat_frequency?.frequency}`,
  `Skip public holidays - ${skip_on_public_holidays ? 'Yes' : 'No'}`,
];
