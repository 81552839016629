import React from 'react';

interface Props {
  message?: string | null;
  className?: string;
  colour?: string;
}

function ErrorResponse({ message = '', className, colour = 'danger' }: Props) {
  const show = message !== '' && message !== null;

  return (
    <>
      {show && (
        <div
          data-id="errorResponse"
          className={`d-flex justify-content-center m-2 lead text-${colour} ${className !== undefined ? className : ''}`}
        >
          {message}
        </div>
      )}
    </>
  );
}

export default ErrorResponse;
