import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiLocations } from 'tsx/constants/api';

export const typePrefix = 'locations';

const config = [
  { actionType: 'getAll', apiPath: apiLocations, method: get, actionParams: { limit: 0 } },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiLocations}/${params.id}`, method: get },
  { actionType: 'getDistance', apiPath: `${apiLocations}/distance`, method: get },
];

export const { getAll, getOne, getDistance } = createThunks(typePrefix, config);
