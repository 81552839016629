import React from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  Route: React.FC;
  paramKey: string;
  [key: string]: any;
}

function AppDynamicRoute({ Route, paramKey, ...props }: Props) {
  const params = useParams();
  return <Route key={params[paramKey]} {...props} />;
}

export default AppDynamicRoute;
