import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from 'tsx/types/reducers';

interface State {
  loading: LoadingState;
  error: string | null | undefined;
  quickMenuNarrow: boolean;
  viewType?: 'feature' | null;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  quickMenuNarrow: false,
};

// Main slice, connecting API actions to redux state.
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleQuickMenu(state, action) {
      const { payload } = action;
      state.quickMenuNarrow = payload;
    },
    setViewType(state, action) {
      const { payload } = action;
      state.viewType = payload;
    },
  },
});

export const selectQuickMenuNarrow = (state: RootState) => state.app.quickMenuNarrow;
export const selectViewType = (state: RootState) => state.app.viewType;

export const { toggleQuickMenu, setViewType } = appSlice.actions;

export default appSlice.reducer;
