import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { Icon, icons } from 'tsx/components/Icon';

interface Travel {
  type: string;
  distance: number | string;
  duration: number | string;
}

interface ComponentProps {
  travel: Travel;
  style?: CSSProperties;
}

const TravelCard: React.FC<ComponentProps> = ({ travel, style }) => {
  const { distance, duration, type } = travel;
  const className = classNames('travel-card', {
    [`${type}`]: type !== undefined,
  });

  let icon;
  let arrow;
  switch (type) {
    case 'start':
    case 'end': {
      icon = icons.faHouse;
      arrow = icons.faArrowRight;
      break;
    }

    default: {
      icon = icons.faCar;
      break;
    }
  }

  return (
    <div
      className={className}
      style={style}
      title={`~ ${typeof duration === 'number' ? Math.round(duration) : duration}mins`}
    >
      <div>~ {typeof distance === 'number' ? distance?.toFixed(2) : distance} kms</div>
      <div className="icons">
        {icon && <Icon icon={icon} size="sm" />}
        {arrow && <Icon icon={arrow} size="sm" />}
      </div>
    </div>
  );
};

export default TravelCard;
