import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

const Icon: React.FC<FontAwesomeIconProps> = ({ ...props }) => {
  return <FontAwesomeIcon {...props} />;
};

export type { IconProp, SizeProp };
export default Icon;
