import { FieldProps } from 'tsx/components/FormFields';
import LabelDuration from 'tsx/components/LabelDuration';

import { getAll as getServiceTypes } from 'tsx/features/appointments/actions/serviceTypes';

import { selectAppointmentServiceTypes } from '../../reducers/serviceTypes';
import { selectCareQualificationLevels } from '../../reducers/careQualificationLevels';
import { selectLocations } from '../../reducers/locations';
import { selectMedications } from 'tsx/features/main/reducers/medications';
import { selectTaskTypes } from 'tsx/features/main/reducers/taskTypes';
import { selectEquipments } from 'tsx/features/main/reducers/equipments';
import { selectPaymentTypes } from 'tsx/features/main/reducers/paymentTypes';

import FormInputStartEndTimes from '../../components/FormInputStartEndTimes';
import FormInputTasks from '../../components/FormInputTasks';
import FormInputNDISRates from '../../components/FormInputNDISRates';
import FormInputLocations from '../../components/FormInputLocations';
import FormInputEquipments from '../../components/FormInputEquipments';
import FormInputQualifications from '../../components/FormInputQualifications';
import FormInputAccountSettings from '../../components/FormInputAccountSettings';
import FormInputPackageSelector from '../../components/FormInputPackageSelector';
import FormInputWorkerLeaveAvailability from '../../components/FormInputWorkerLeaveAvailability';
import FormInputWorkerAppointments from '../../components/FormInputWorkerAppointments';
import FormInputWorkerQualificationCheck from '../../components/FormInputWorkerQualificationCheck';
import FormInputWorkerRosterNotes from '../../components/FormInputWorkerRosterNotes';
import FormInputWorkerAvailability from '../../components/FormInputWorkerAvailability';
import FormInputCareWorkers from '../../components/FormInputCareWorkers';

import {
  deriveClientAddress,
  deriveClientLink,
  deriveDuration,
  deriveAccountsDefinition,
  deriveAccountSettings,
  deriveServiceTypeSelectorParams,
  deriveServiceTypeDescription,
} from '../../lib/derive';
import { showNDISRates, showTransport, showLocations, showPaymentAmount, showUserAvailability } from '../../lib/show';
import { validateTasks, validateStartEndTimes, validateCareWorker } from '../../lib/validate';
import { onChangePackage, onChangeServiceType } from '../../lib/onchange';

const commonFields: FieldProps = {
  id: {
    type: 'hidden',
  },
  status: {
    type: 'readonly',
    field: 'status.name',
    order: 100,
  },
  client_name: {
    type: 'readonly',
    field: 'client.full_name',
    caption: 'Client / Facility',
    deriveDescription: deriveClientAddress,
    className: 'text-left',
    deriveLink: deriveClientLink,
    required: true,
    colSize: 4,
    order: 200,
  },
  package_fixed_id: {
    type: 'custom',
    component: FormInputPackageSelector,
    dependencies: ['package_fixed_id', 'client_id', 'date'],
    onChange: onChangePackage,
    required: true,
    colSize: 6,
    order: 300,
  },
  client_description: {
    type: 'alert-info',
    field: 'client.description',
    colSize: 4,
    order: 400,
  },
  location_override_id: {
    type: 'select',
    caption: 'Location',
    colSize: 4,
    description: 'Optional, defaults to client home address',
    order: 500,
    show: showLocations,
    selectorOptions: selectLocations,
  },
  service_type_id: {
    type: 'select-lazy',
    caption: 'Service Type',
    initialOption: { id: 'service_type', name: '' },
    selectorOptions: selectAppointmentServiceTypes,
    selectorActions: getServiceTypes,
    selectorParams: deriveServiceTypeSelectorParams,
    onChange: onChangeServiceType,
    deriveWarning: deriveServiceTypeDescription,
    order: 600,
    colSize: 4,
    required: true,
  },
  locations: {
    type: 'custom',
    caption: 'Trip Points',
    component: FormInputLocations,
    dependencies: ['client_id', 'transport_pickup_id', 'transport_dropoff_id'],
    order: 700,
    colSize: 6,
    show: showTransport,
  },
  accounts_definitions: {
    type: 'custom',
    component: FormInputNDISRates,
    deriveValue: deriveAccountsDefinition,
    show: showNDISRates,
    order: 800,
  },
  qualification_level: {
    type: 'custom',
    caption: 'Qualifications Required',
    component: FormInputQualifications,
    dependencies: ['service_type_id', 'qualification_level'],
    selectorOptions: selectCareQualificationLevels,
    order: 900,
  },
  user_id: {
    type: 'custom',
    caption: 'Care Worker',
    component: FormInputCareWorkers,
    dependencies: ['client_id', 'user_id', 'date', 'id', 'repeat'],
    validateConfirm: validateCareWorker,
    colSize: 6,
    order: 910,
  },
  user_leave_availability: {
    type: 'custom',
    component: FormInputWorkerLeaveAvailability,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 920,
  },
  user_appointments: {
    type: 'custom',
    component: FormInputWorkerAppointments,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 6,
    order: 930,
  },
  user_qualification_check: {
    type: 'custom',
    component: FormInputWorkerQualificationCheck,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 940,
  },
  user_rostering_notes: {
    type: 'custom',
    component: FormInputWorkerRosterNotes,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 950,
  },
  user_availability: {
    type: 'custom',
    component: FormInputWorkerAvailability,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 6,
    order: 960,
  },
  start_end_time: {
    type: 'custom',
    caption: 'Start/End Times',
    colSize: 6,
    component: FormInputStartEndTimes,
    dependencies: ['start_time', 'end_time', 'ends_next_day'],
    validate: validateStartEndTimes,
    order: 1000,
  },
  duration: {
    type: 'custom',
    caption: 'Duration',
    component: LabelDuration,
    deriveValue: deriveDuration,
    className: 'fw-bold',
    colSize: 4,
    order: 1100,
  },
  payment_type: {
    type: 'radio',
    field: 'payment_type',
    selectorOptions: selectPaymentTypes,
    caption: 'Payment Type',
    colSize: 4,
    order: 1190,
  },
  payment_amount: {
    type: 'currency',
    field: 'payment_amount',
    caption: 'Payment Collected',
    show: showPaymentAmount,
    colSize: 4,
    order: 1200,
  },
  accounts: {
    type: 'custom',
    caption: 'Accounts',
    component: FormInputAccountSettings,
    deriveValue: deriveAccountSettings,
    colSize: 6,
    order: 1300,
  },
  flexibility: {
    type: 'switch',
    caption: 'Flexible with Time',
    order: 1400,
  },
  tasks: {
    type: 'custom',
    field: 'task_ids',
    caption: 'Tasks',
    labelCaption: 'Max 45',
    colSize: 6,
    component: FormInputTasks,
    selectorOptions: selectTaskTypes,
    validate: validateTasks,
    order: 1500,
  },
  medications: {
    type: 'multi-checkbox',
    field: 'medication_ids',
    caption: 'Medications',
    colSize: 3,
    selectorOptions: selectMedications,
    order: 1600,
  },
  equipment_details: {
    type: 'custom',
    caption: 'Equipment Details',
    colSize: 6,
    component: FormInputEquipments,
    selectorOptions: selectEquipments,
    order: 1700,
  },
  notes: {
    type: 'textarea',
    caption: 'Notes',
    placeholder: 'Instructions for care workers, specific to this appointment',
    order: 1800,
  },
};

export { commonFields };
