import { AddressLookup } from 'tsx/components/FormFields/Inputs';
import { FieldProps } from 'tsx/components/FormFields';
import { selectStates } from 'tsx/features/main/reducers/states';
import { selectUserDepartments } from '../reducers/userDepartments';
import { selectUserPayGroups } from '../reducers/userPayGroups';
import { selectUserPayLevels } from '../reducers/userPayLevels';
import { selectAllowances } from 'tsx/features/main/reducers/userAllowances';

const fields: FieldProps = {
  id: {
    type: 'hidden',
    field: 'id',
  },
  title: {
    type: 'readonly',
    caption: 'Title',
    field: 'title',
    colSize: 4,
    order: 10,
  },
  first_name: {
    type: 'readonly',
    caption: 'First Name',
    field: 'first_name',
    colSize: 4,
    order: 20,
  },
  surname: {
    type: 'readonly',
    caption: 'Surname',
    field: 'surname',
    colSize: 4,
    order: 30,
  },
  gender: {
    type: 'readonly',
    caption: 'Gender',
    field: 'gender.name',
    colSize: 4,
    order: 40,
  },
  email: {
    type: 'text',
    caption: 'Email',
    field: 'email',
    required: true,
    colSize: 4,
    order: 50,
  },
  level_id: {
    type: 'readonly',
    caption: 'User Level',
    field: 'level.name',
    colSize: 4,
    order: 60,
  },
  accounts_access_enabled: {
    type: 'readonly',
    caption: 'Accounts Access Enabled',
    field: 'accounts_access_enabled',
    colSize: 4,
    order: 70,
  },
  pay_level_id: {
    type: 'select',
    caption: 'Pay Level',
    selectorOptions: selectUserPayLevels,
    required: true,
    colSize: 4,
    order: 80,
  },
  pay_group_id: {
    type: 'select',
    caption: 'Pay Group',
    selectorOptions: selectUserPayGroups,
    required: true,
    colSize: 4,
    order: 90,
  },
  department_id: {
    type: 'select',
    caption: 'Team',
    selectorOptions: selectUserDepartments,
    required: true,
    colSize: 4,
    order: 100,
  },
  accounting_system_reference: {
    type: 'text',
    caption: 'Accounting System Reference:',
    field: 'accounting_system_reference',
    colSize: 4,
    order: 120,
  },
  abn: {
    type: 'text',
    caption: 'ABN / Contractor Number',
    field: 'abn',
    colSize: 4,
    order: 130,
  },
  is_case_manager: {
    type: 'switch',
    caption: 'Case Manager Account',
    required: true,
    colSize: 4,
    order: 135,
  },
  is_team_allocation: {
    type: 'switch',
    caption: 'Team Allocation User',
    required: true,
    colSize: 4,
    order: 136,
  },
  addressLookup: {
    type: 'custom',
    placeholder: 'Search for address',
    colSize: 6,
    component: AddressLookup,
    order: 140,
  },
  address: {
    type: 'text',
    caption: 'Address',
    field: 'address',
    colSize: 4,
    order: 150,
  },
  suburb: {
    type: 'text',
    caption: 'Suburb',
    field: 'suburb',
    colSize: 4,
    order: 160,
  },
  postcode: {
    type: 'text',
    caption: 'Postcode',
    field: 'postcode',
    colSize: 4,
    order: 170,
  },
  state: {
    type: 'select',
    caption: 'State',
    field: 'state_id',
    selectorOptions: selectStates,
    colSize: 4,
    order: 180,
  },
  dob: {
    type: 'date',
    caption: 'Date of Birth',
    field: 'dob',
    colSize: 4,
    order: 185,
  },
  phone: {
    type: 'text',
    caption: 'Landline Phone',
    field: 'phone',
    colSize: 4,
    order: 190,
  },
  mobile: {
    type: 'text',
    caption: 'Mobile',
    field: 'mobile',
    colSize: 4,
    order: 200,
  },
  emergency_contact_name: {
    type: 'text',
    caption: 'Emergency Contact Name',
    colSize: 4,
    order: 210,
  },
  emergency_contact_phone: {
    type: 'text',
    caption: 'Emergency Contact Phone',
    colSize: 4,
    order: 220,
  },
  can_notify_sms: {
    type: 'switch',
    caption: 'Notify SMS',
    labelCaption: 'Alert worker via mobile to any changes to same day appointments',
    colSize: 4,
    order: 230,
  },
  can_notify_email: {
    type: 'switch',
    caption: 'Notify Email',
    labelCaption: 'Used for workers overdue reminders',
    colSize: 4,
    order: 240,
  },
  can_notify_roster_notifications: {
    type: 'switch',
    caption: 'Get Roster Notifications',
    labelCaption: '(Admin Users Only) Can get an email alert when an appointment is declined / rejected',
    colSize: 4,
    order: 250,
  },
  does_ignore_conflicts: {
    type: 'switch',
    caption: 'Ignore Conflicts',
    labelCaption: 'Skip conflict warnings in the weekly planner',
    required: true,
    colSize: 4,
    order: 280,
  },
  does_ignore_award_alerts: {
    type: 'switch',
    caption: 'Ignore Award Alerts',
    labelCaption: 'Skip award alerts in the weekly planner',
    required: true,
    colSize: 4,
    order: 290,
  },
  description: {
    type: 'textarea',
    caption: 'Notes',
    colSize: 4,
    order: 260,
  },
  rostering_notes: {
    type: 'textarea',
    caption: 'Rostering Notes',
    labelCaption: 'Appears in the weekly planner next to the care worker name',
    colSize: 4,
    order: 270,
  },

  allowances: {
    type: 'multi-checkbox',
    caption: 'Allowances',
    selectorOptions: selectAllowances,
    colSize: 2,
    order: 310,
  },
};

export default fields;
