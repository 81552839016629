import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Row, State } from 'tsx/types/reducers';

import {
  typePrefix,
  getOne,
  getAll,
  create,
  update,
  getSignature,
  getEstimate,
  cancel,
  startBidding,
  endBidding,
  getStatistics,
} from '../actions/appointments';

interface appointmentState extends State {
  rowSignature?: string | null;
  total?: number;
  estimate?: { first: Row[]; last: Row[]; count: number | null };
  message: string | null;
  copyRow?: any;
  statistics?: {
    daily?: object;
    status?: object;
  };
  childRow?: any;
}

const initialState: appointmentState = {
  loading: 'idle',
  error: null,
  rows: [],
  total: 0,
  message: null,
};

const copyRow = (state: appointmentState, action: any) => {
  const row = { ...action.payload };
  state.message = `Appointment #${row.reference_number} copied! Check and save the appointment to confirm creation.`;

  // Clear ignore variables;
  const ignoreKeys = ['id', 'reference_number', 'status_id', 'status', 'repeat'];
  ignoreKeys.forEach((key) => delete row[key]);

  state.copyRow = row;
};

const createChild = (state: appointmentState, action: any) => {
  const row = { ...action.payload };
  state.message = `Parent #${row.reference_number} copied! Check and save the appointment to confirm creation.`;

  const childRow = { ...row, parent_repeat_id: row.id };

  const copyKeys = [
    'qualification_level',
    'service_type_id',
    'start_time',
    'end_time',
    'task_ids',
    'medication_ids',
    'flexibility',
    'user_id',
    'client_id',
    'duration',
    'notes',
    'notes_carer',
    'notes_admin',
    'shift_code',
    'ends_next_day',
    'package_override_id',
    'transport_pickup_id',
    'transport_dropoff_id',
    'location_override_id',
    'package_fixed_id',
    'leave_entry_id',
    'is_group_session',
    'parent_repeat_id',
  ];
  Object.keys(childRow).forEach((key) => {
    if (!copyKeys.includes(key)) delete childRow[key];
  });

  const ignoreKeys = ['id', 'reference_number', 'status_id', 'status'];
  ignoreKeys.forEach((key) => delete childRow[key]);

  state.childRow = childRow;
};

export const appointmentSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    copyAppointment(state, action) {
      copyRow(state, action);
    },
    createChildAppointment(state, action) {
      createChild(state, action);
    },
    clearCopyAppointment(state) {
      delete state.copyRow;
    },
    clearChildAppointment(state) {
      delete state.childRow;
    },
    clearAppointments(state) {
      state.rows = initialState.rows;
    },
    clearAppointment(state) {
      state.row = initialState.row;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { rows, total } = action.payload.data;
      state.rows = rows;
      state.total = total;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';

      const { isTemplate, isParent, data } = action.payload;

      if (isTemplate === true) copyRow(state, { payload: data });
      else if (isParent === true) createChild(state, { payload: data });
      else state.row = action.payload.data;
    });
    builder.addCase(getSignature.pending, (state) => {
      state.loading = 'fulfilled';
      state.rowSignature = null;
    });
    builder.addCase(getSignature.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rowSignature = action.payload.data;
    });
    builder.addCase(create.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.message = action.payload.message;
    });
    builder.addCase(update.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.message = action.payload.message;
    });
    builder.addCase(cancel.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.message = action.payload.message;
    });
    builder.addCase(getEstimate.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.estimate = action.payload.data;
    });
    builder.addCase(startBidding.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.message = action.payload.message;
    });
    builder.addCase(endBidding.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.message = action.payload.message;
    });
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.statistics = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isFulfilled, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.error = null;
    });
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectAppointments = (state: RootState) => state.appointments.rows;
export const selectAppointmentsTotal = (state: RootState) => state.appointments.total;
export const selectAppointmentRowEstimate = (state: RootState) => state.appointments.estimate;
export const selectCurrentAppointment = (state: RootState) => state.appointments.row;
export const selectCopyAppointment = (state: RootState) => state.appointments.copyRow;
export const selectChildAppointment = (state: RootState) => state.appointments.childRow;
export const selectCurrentAppointmentSignature = (state: RootState) => state.appointments.rowSignature;
export const selectErrorResponse = (state: RootState) => state.appointments.error;
export const selectAppointmentMessage = (state: RootState) => state.appointments.message;
export const selectAppointmentLoading = (state: RootState) => state.appointments.loading;
export const selectStatistics = (state: RootState) => state.appointments.statistics;

export const {
  copyAppointment,
  createChildAppointment,
  clearCopyAppointment,
  clearChildAppointment,
  clearAppointments,
  clearAppointment,
  clearMessage,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
