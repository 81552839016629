import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiRegions } from 'tsx/constants/api';

export const typePrefix = 'regions';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiRegions,
    method: get,
  },
];

export const { getAll } = createThunks(typePrefix, config);
