import { createThunks } from 'tsx/libs/reduxUtils';
import { get, post } from 'tsx/libs/genericAction';
import { apiWeeklyPlanner } from 'tsx/constants/api';

export const typePrefix = 'weekly-planner';

const config = [
  { actionType: 'getAll', apiPath: `${apiWeeklyPlanner}/users`, method: get, actionParams: { limit: 0 } },
  { actionType: 'publish', apiPath: `${apiWeeklyPlanner}/publish`, method: post },
  { actionType: 'getPublishEstimates', apiPath: `${apiWeeklyPlanner}/publish/estimates`, method: get },
  { actionType: 'sendSMS', apiPath: `${apiWeeklyPlanner}/users/sms`, method: post },
];

export const { getAll, publish, getPublishEstimates, sendSMS } = createThunks(typePrefix, config);
