import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import dayjs from 'dayjs';
import { dateInputFieldFormat } from 'tsx/libs/dayjs';
import { addCommonReducers } from 'tsx/libs/reduxUtils';

import { LoadingState } from 'tsx/types/reducers';
import { typePrefix, getAll, getPublishEstimates } from '../actions/weeklyPlanner';
import { Appointment } from '../lib/common';

interface WeeklyPlannerState {
  loading: LoadingState;
  error: string | null | undefined;
  currentDate: string | null;
  rows: Array<any>;
  openAppointmentDetailsId: number | null;
  focusedWorkerId: number | null;
  options: { [key: string]: boolean };
  conflictOptions: { [key: string]: boolean };
  params: {
    week_start?: string;
    week_end?: string;
    limit: number;
  };
  focused: {
    appointment: Appointment | null;
    isAvailabilityView: boolean;
    isBiddingView: boolean;
    newBidders: Array<any>;
  };
  filterOptions: { [key: string]: any };
  orderBy: {
    availability: boolean;
    distance: boolean;
  };
  publishEstimates: {
    users?: number | null;
    appointments?: number | null;
  };
  importedAppointments?: {
    id?: number | null;
    user_id?: number;
    date?: string;
    parent_repeat_id?: number;
  };
}

const initialState: WeeklyPlannerState = {
  loading: 'idle',
  error: null,
  currentDate: null,
  rows: [],
  params: {
    limit: 30,
  },
  openAppointmentDetailsId: null,
  focusedWorkerId: null,
  options: {
    appointmentFlexibility: false,
    workerAvailability: false,
    workerQualifications: false,
    workerAwardAlerts: false,
    totalSimple: false,
    totalAppointments: false,
    totalPredicted: true,
    unallocatedDuration: false,
    unallocatedQualifications: false,
    unallocatedServiceType: true,
    unallocatedSuburb: false,
    showBreaks: true,
    showTravel: true,
  },
  conflictOptions: {
    conflictTime: true,
    conflictTravel: true,
    conflictPreference: true,
  },
  focused: {
    appointment: null,
    isAvailabilityView: false,
    isBiddingView: false,
    newBidders: [],
  },
  filterOptions: {
    is_qualified: null,
    is_available: null,
    is_preferred: null,
    is_historical: null,
  },
  orderBy: {
    availability: false,
    distance: false,
  },
  publishEstimates: {},
};

export const weeklyPlannerSlice = createSlice({
  name: typePrefix,
  initialState,
  reducers: {
    toggleOption(state, action) {
      const { key, toggle } = action.payload;
      state.options[key] = toggle;
    },
    toggleConflictOption(state, action) {
      const { key, toggle } = action.payload;
      state.conflictOptions[key] = toggle;
    },
    updateWeeklyPlannerParams(state, action) {
      const { week_start, week_end, ...params } = action.payload;

      const newParams = {
        ...state.params,
        ...(week_start ? { week_start: dayjs(week_start).format(dateInputFieldFormat) } : {}),
        ...(week_end ? { week_end: dayjs(week_end).format(dateInputFieldFormat) } : {}),
        ...params,
      };

      // Clear week_end if not provided with week_start
      if (week_start && !week_end) {
        delete newParams.week_end;
      }

      state.params = newParams;
    },
    updateWeeklyPlannerData(state, action) {
      state.rows = action.payload;
    },
    openAppointmentDetails(state, action) {
      state.openAppointmentDetailsId = action.payload;
    },
    focusWorker(state, action) {
      state.focusedWorkerId = action.payload;
    },
    focusAppointment(state, action) {
      state.focused = action.payload;
    },
    filterUsers(state, action) {
      const { payload } = action;

      if ('key' in payload && 'value' in payload) {
        state.filterOptions[payload.key] = payload.value;
      } else {
        Object.entries(payload).forEach(([key, value]) => {
          state.filterOptions[key] = value;
        });
      }
    },
    toggleBidding(state, action) {
      const { isBiddingView } = action.payload;
      state.focused = { ...state.focused, isBiddingView: isBiddingView };
    },
    clearErrors(state) {
      state.error = initialState.error;
    },
    orderUsers(state, action) {
      const { payload } = action;
      state.orderBy = payload;
      state.focused = { ...state.focused, isAvailabilityView: payload.availability ?? false };
    },
    updateBiddingWorkers(state, action) {
      //TODO: rename func for clarity - updateUnsavedBiddingWorkers ?
      const { workerIds, isChecked } = action.payload;

      if (!state.focused.appointment) return;

      const newBidderIds = state.focused.newBidders || [];

      const mergedBidderIds = isChecked
        ? [...new Set([...newBidderIds, ...workerIds])]
        : newBidderIds.filter((id) => !workerIds.includes(id));

      state.focused = {
        ...state.focused,
        newBidders: mergedBidderIds,
      };
    },
    mergeBiddingWorkers(state) {
      const { focused } = state;
      const { appointment, newBidders } = focused || {};
      const existingBidders = appointment?.bidding_user_ids ?? [];
      const mergedBidders = Array.from(new Set([...existingBidders, ...newBidders]));

      const updatedAppointment: Partial<Appointment> = {
        ...appointment,
        is_bidding: true,
        bidding_user_ids: mergedBidders,
      };

      state.focused = { ...focused, appointment: updatedAppointment as Appointment, newBidders: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state, { meta }) => {
      const {
        arg: { week_start },
      } = meta;

      // Grab start date parameter from get call, check if the same.
      // If not, clear the original rows
      const prev = week_start;
      const curr = state.currentDate;
      if (prev && prev !== curr) {
        state.rows = initialState.rows;
        state.currentDate = prev;
      }
    });
    builder.addCase(getPublishEstimates.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.publishEstimates = action.payload.data;
    });

    addCommonReducers<WeeklyPlannerState>(builder, typePrefix, getAll);
  },
});

export const {
  toggleOption,
  toggleConflictOption,
  updateWeeklyPlannerData,
  updateWeeklyPlannerParams,
  openAppointmentDetails,
  focusWorker,
  focusAppointment,
  filterUsers,
  toggleBidding,
  clearErrors,
  orderUsers,
  updateBiddingWorkers,
  mergeBiddingWorkers,
} = weeklyPlannerSlice.actions;

export const selectAll = (state: RootState) => state.weeklyPlanner.rows;
export const selectLoading = (state: RootState) => state.weeklyPlanner.loading;
export const selectParams = (state: RootState) => state.weeklyPlanner.params;
export const selectOptions = (state: RootState) => state.weeklyPlanner.options;
export const selectConflictOptions = (state: RootState) => state.weeklyPlanner.conflictOptions;
export const selectOpenAppointmentDetailsId = (state: RootState) => state.weeklyPlanner.openAppointmentDetailsId;

export const selectFocusedAppointment = (state: RootState) => state.weeklyPlanner.focused;
export const selectOrderBy = (state: RootState) => state.weeklyPlanner.orderBy;
export const selectPublishEstimates = (state: RootState) => state.weeklyPlanner.publishEstimates;
export const selectErrorResponse = (state: RootState) => state.weeklyPlanner.error;
export const selectFocusedWorkerId = (state: RootState) => state.weeklyPlanner.focusedWorkerId;
export const selectImportedAppointments = (state: RootState) => state.weeklyPlanner.importedAppointments;

export default weeklyPlannerSlice.reducer;
