import React from 'react';
import { Input } from 'reactstrap';
import { useFormikContext } from 'formik';

import { Icon, icons } from 'tsx/components/Icon';
import { MultiSelect, Select } from 'tsx/components/FormFields/Inputs';
import { DateRange, SearchProp, TimeRange } from 'tsx/components/FormFields/Search';

interface ComponentProps extends SearchProp {
  id: string;
  value: any;
  includeCaption?: boolean;
  className?: string;
  inline?: boolean;
  disabled?: boolean;
  onKeyDown?: (key: string) => void;
  [key: string]: any;
}

const SearchField: React.FC<ComponentProps> = ({
  id,
  value,
  type,
  disabled = false,
  includeCaption = true,
  caption,
  inline = true,
  placeholder,
  className = '',
  selectorOptions,
  options,
  onKeyDown,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  let input;
  const inputClassName = `${className}${disabled ? ' bg-disabled' : ''}`;
  switch (type) {
    case 'text-like': {
      input = (
        <div className={inputClassName}>
          <Input
            type="text"
            name={id}
            id={id}
            className="border-0"
            value={value ?? ''}
            onChange={({ target: { name, value } }) => setFieldValue(name, value)}
            onKeyDown={(event) => onKeyDown && onKeyDown(event.key)}
            placeholder={placeholder}
            disabled={disabled}
          />
          <Icon className="ms-1 me-2" icon={icons.faMagnifyingGlass} />
        </div>
      );
      break;
    }
    case 'lookup': {
      input = (
        <div className={inputClassName}>
          {includeCaption && (
            <>
              <span className="ms-2 me-1 fw-bold">{caption}</span>
              <span className="ms-1">=</span>
            </>
          )}
          <Select
            {...props}
            type="select"
            name={id}
            id={id}
            value={value ?? ''}
            className={`${!inline ? 'w-100 ps-3' : ''} border-0`}
            onChange={setFieldValue}
            selectorOptions={selectorOptions}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      );
      break;
    }
    case 'multi-lookup': {
      input = (
        <div className={inputClassName}>
          {includeCaption && (
            <>
              <span className="ms-2 me-1 fw-bold">{caption}</span>
              <span className="ms-1">=</span>
            </>
          )}
          <MultiSelect
            {...props}
            type="select"
            name={id}
            id={id}
            value={value ?? ''}
            className={`${!inline ? 'w-100' : ''}`}
            selectorOptions={selectorOptions}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />
        </div>
      );
      break;
    }
    case 'date-range': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <DateRange id={id} value={value} onChange={setFieldValue} disabled={disabled} inline={inline} {...props} />
        </div>
      );
      break;
    }
    case 'time-range': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <TimeRange
            id={id}
            value={value}
            onChange={setFieldValue}
            onKeyDown={(key: string) => onKeyDown && onKeyDown(key)}
            disabled={disabled}
            inline={inline}
            {...props}
          />
        </div>
      );
      break;
    }
  }

  return input;
};

export default SearchField;
