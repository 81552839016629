import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import Button, { ButtonProps } from 'tsx/components/Button';

import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';

import { Fields, FieldProps } from 'tsx/components/FormFields';

import AppointmentActivities from 'tsx/features/appointments/AppointmentActivities';

import { selectCurrentAppointment } from 'tsx/features/appointments/reducers/appointments';
import generateYupSchema from 'tsx/libs/yupValidation';

const AppointmentForm: React.FC<any> = ({
  authTag,
  fields,
  defaultValues = {},
  overrideValues = {},
  canSave,
  save,
  isNew,
  showActivityLogs = false,
  buttonProps = {} as ButtonProps,
}) => {
  const [invalidFields, setInvalidFields]: [string[], any] = useState([]);

  const appointment = useAppSelector(selectCurrentAppointment);

  const fieldDefinitions = Object.entries(fields as FieldProps).map(([key, props]) => ({
    key,
    ...props,
  }));

  // Perform deep equality check on changed values, will be replaced when model mapping is introduced (╯°□°）╯︵ ┻━┻
  const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T) => {
    return Object.entries(values).reduce((records: Partial<T>, [key, value]) => {
      if (!isEqual(initialValues[key], value)) records[key as keyof T] = value;
      return records;
    }, {});
  };

  const reduceFieldValues = (
    fields: any,
    fillValue = false,
    defaults: Record<string, any> = {},
    overrides: Record<string, any> = {},
  ) => {
    return Object.keys(fields).reduce((values, index) => {
      const { key, field, dependencies = [] } = fields[index];
      const id = field ?? key;

      [id, ...dependencies].forEach((id: string) => {
        const value = overrides[id];
        if (value !== undefined && value !== null) values[id] = value;
        else if (fillValue) values[id] = '';
      });

      return values;
    }, defaults);
  };

  // Send request to update record, include ID if editing.
  const onSave = async (values: any) => {
    const valid = invalidFields.length === 0;
    if (valid && save) save(values);
  };

  const initialValues = appointment
    ? { ...appointment, ...defaultValues }
    : reduceFieldValues(fieldDefinitions, true, defaultValues);
  const initialChangedValues = reduceFieldValues(fieldDefinitions, false, {}, overrideValues);

  /**
   * Dynamic generation of yup validation schema
   */
  const fieldsObject: FieldProps = fieldDefinitions.reduce((acc, field) => {
    const { key, ...fieldProps } = field;
    acc[key] = fieldProps;
    return acc;
  }, {} as FieldProps);

  const validationSchema = generateYupSchema(fieldsObject);

  const handleYupValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setInvalidFields([]);
    } catch (err: any) {
      if (err.inner) {
        const errors = err.inner.map((e: any) => e.path);
        console.log(errors);
        setInvalidFields(errors);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues, ...initialChangedValues }}
        validationSchema={validationSchema}
        validate={handleYupValidation}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSave(getChangedValues(values, initialValues));
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, touched, isSubmitting }) => (
          <Form>
            <Fields
              authTag={authTag}
              definitions={fieldDefinitions}
              row={isNew ? overrideValues : appointment}
              invalidFields={invalidFields}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              touched={touched}
            />
            <div>
              {canSave && <Button size="sm" disabled={isSubmitting || !canSave()} type="submit" {...buttonProps} />}
            </div>
          </Form>
        )}
      </Formik>
      {showActivityLogs && <AppointmentActivities className="p-2 ps-4 pe-4 mt-2 mb-5" />}
    </>
  );
};

export default AppointmentForm;
