import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from 'tsx/types/reducers';
import { typePrefix, simulate } from '../actions/unsaved';
import { update } from 'tsx/features/appointments/actions/appointments';

interface UnsavedChanges {
  [id: number]: Change;
}

interface Change {
  id: number | null;
  user_id: number;
  date: string;
  parent_repeat_id?: number;
}

interface UnsavedState {
  loading: {
    full: LoadingState;
    rows: { [id: number]: LoadingState };
  };
  error: string | null | undefined;
  rows: Array<any>;
  appointments: UnsavedChanges;
  interacting: number | null;
}

const initialState: UnsavedState = {
  loading: {
    full: 'idle',
    rows: {},
  },
  error: null,
  rows: [],
  appointments: {},
  interacting: null,
};

export const unsavedSlice = createSlice({
  name: `${typePrefix}`,
  initialState,
  reducers: {
    setInteracting(state, action) {
      state.interacting = parseInt(action.payload);
    },
    clearInteracting(state) {
      state.interacting = initialState.interacting;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(simulate.pending, (state, { meta: { arg } }) => {
      const { type, data, workers } = arg;
      const { appointments } = state;

      switch (type) {
        case 'assign': {
          const changes: UnsavedChanges = {};
          data.forEach(({ id, user_id, date, parent_repeat_id }: any) => {
            state.loading.rows[parseInt(user_id)] = 'pending';
            const key = id === null ? parent_repeat_id : id;

            const changed: Change = {
              id,
              user_id,
              date,
            };

            if (parent_repeat_id) changed.parent_repeat_id = parent_repeat_id;

            changes[key] = {
              ...appointments[key],
              ...changed,
            };
          });

          state.appointments = {
            ...appointments,
            ...changes,
          };

          break;
        }

        case 'move':
        default: {
          const { key, id, parent_repeat_id, date, start_time, end_time } = data;

          const { origin, target } = workers;
          const changed = {
            id,
            parent_repeat_id,
            date,
            start_time,
            end_time,
            ...(origin ? { user_id: target.id } : {}),
          };

          const appointment = {
            ...appointments[key],
            ...changed,
          };

          state.appointments = {
            ...appointments,
            [key]: appointment,
          };

          if (origin) state.loading.rows[parseInt(origin.id)] = 'pending';
          if (target) state.loading.rows[parseInt(target.id)] = 'pending';

          break;
        }
      }
    });
    builder.addCase(simulate.fulfilled, (state, action) => {
      state.loading.full = 'fulfilled';

      // Default matching for loading cases, pending when action is being called
      const workers = action.payload.data;
      const rows = state.rows;
      if (workers?.length > 0) {
        workers.forEach((worker: any) => {
          const index = rows.findIndex(({ id }) => id === worker.id);
          if (index > -1) rows[index] = worker;
          else rows.push(worker);
          state.loading.rows[worker.id] = 'fulfilled';
        });
      }

      state.rows = rows;
    });
    builder.addCase(simulate.rejected, (state) => {
      state.loading = initialState.loading;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.appointments = initialState.appointments;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading.full = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading.full = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const { setInteracting, clearInteracting } = unsavedSlice.actions;

export const selectAll = ({ weeklyPlannerUnsaved }: RootState) => weeklyPlannerUnsaved.rows;

export const selectAllChanges = ({ weeklyPlannerUnsaved }: RootState) => weeklyPlannerUnsaved.appointments;

export const selectAllChangesCount = ({ weeklyPlannerUnsaved }: RootState) =>
  Object.values(weeklyPlannerUnsaved.appointments).length;

export const selectLoading = ({ weeklyPlannerUnsaved }: RootState) => weeklyPlannerUnsaved.loading.full;
const selectRowsLoading = ({ weeklyPlannerUnsaved }: RootState) => weeklyPlannerUnsaved.loading.rows;

export const selectRowLoading = createSelector(
  [selectRowsLoading, (_: RootState, id: number) => id],
  (rows, id) => rows[id] ?? 'idle',
);

export default unsavedSlice.reducer;
