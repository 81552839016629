import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks';
import 'styles/App.css';

import AppRoutes from 'tsx/features/main/AppRoutes';
import { selectForceRedirect } from './reducers/login';

function App() {
  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;
  const forceRedirect = useAppSelector(selectForceRedirect);

  useEffect(() => {
    if (forceRedirect && REDIRECT_URL) {
      window.location.href = REDIRECT_URL;
    }
  }, [forceRedirect]);

  return <AppRoutes />;
}

export default App;
