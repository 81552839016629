import React, { CSSProperties } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import Spinner from 'tsx/components/Spinner';
import { Event, Worker } from '../../lib/common';
import DailyContent from './DailyContent';
import WorkerCell from '../WorkerCell';
import { selectRowLoading } from '../../reducers/unsaved';
import { selectFocusedWorkerId } from '../../reducers/weeklyPlanner';

interface ComponentProps {
  worker: Worker;
  date: Dayjs;
  events: Event[];
  style?: CSSProperties;
}

const DailyRow: React.FC<ComponentProps> = ({ worker, date, events, style }) => {
  const { id } = worker;
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);
  const loading = useAppSelector((state) => selectRowLoading(state, parseInt(id)));
  const isLoading = loading === 'pending';

  const className = classNames('worker-row', {
    focus: focusedWorkerId && parseInt(worker.id) === focusedWorkerId,
  });

  return (
    <div id={id} className={className} key={`${id}`}>
      {isLoading && <Spinner loading={isLoading} style={style} />}
      <WorkerCell worker={worker} date={date} style={style} />
      <DailyContent worker={worker} date={date} events={events} style={style} />
    </div>
  );
};

export default DailyRow;
