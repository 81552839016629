import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get, post, put } from 'tsx/libs/genericAction';
import { apiUsers } from 'tsx/constants/api';

export const typePrefix = 'users';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiUsers,
    method: get,
    actionParams: {
      sort: ['care_worker.desc', 'is_team_allocation.desc', 'surname'],
      care_worker: true,
      disabled: false,
      attributes: [
        'id',
        'first_name',
        'surname',
        'full_name',
        'level_id',
        'pay_level',
        'pay_level_id',
        'qualifications',
        'rostering_notes',
      ],
      // limit: 0 = All Rows / No Limit
      limit: 0,
    },
  },

  {
    actionType: 'getOne',
    apiPath: (params: Params) => `${apiUsers}/${params.id}`,
    method: get,
  },
  {
    actionType: 'update',
    apiPath: (params: Params) => `${apiUsers}/${params.id}`,
    method: put,
  },
  { actionType: 'sendSMS', apiPath: `${apiUsers}/sms`, method: post },
  {
    actionType: 'getAvailability',
    apiPath: (params: Params) => `${apiUsers}/${params.id}/availability`,
    method: get,
  },
];

export const { getAll, getOne, update, sendSMS, getAvailability, getCareWorkers } = createThunks(typePrefix, config);
