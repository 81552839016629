import axios, { AxiosError, AxiosHeaders, AxiosProgressEvent } from 'axios';

export const get = async (url: string, params?: any, headers?: AxiosHeaders) => {
  try {
    const response = await axios({
      method: 'GET',
      url,
      params,
      headers,
    });

    return response.data;
  } catch (error) {
    throw new Error(buildErrorResponse(error));
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const post = async (url: string, data?: any) => {
  try {
    const response = await axios({
      method: 'POST',
      url,
      data,
    });

    if (response.data) return response.data;
    return true;
  } catch (error) {
    throw new Error(buildErrorResponse(error));
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const put = async (url: string, data: any) => {
  try {
    const response = await axios({
      method: 'PUT',
      url,
      data,
    });

    if (response.data) return response.data;
    return true;
  } catch (error) {
    throw new Error(buildErrorResponse(error));
  }
};

export const remove = async (url: string, params?: any) => {
  try {
    await axios({
      method: 'DELETE',
      url,
      params,
    });

    return true;
  } catch (error) {
    throw new Error(buildErrorResponse(error));
  }
};

export const download = async (
  url: string,
  params?: any,
  onProgress?: (value: string) => void,
  headers?: AxiosHeaders,
) => {
  try {
    let downloadHeaders = {
      'Access-Control-Expose-Headers': 'Content-Disposition',
    };
    if (headers) downloadHeaders = { ...headers, ...downloadHeaders };

    const file = await axios({
      method: 'GET',
      url,
      params,
      responseType: 'blob',
      headers: downloadHeaders,
      onDownloadProgress: (progressEvent) => onProgress && onProgress(calculateProgress(progressEvent)),
    });

    return file;
  } catch (error) {
    throw new Error(buildErrorResponse(error));
  }
};

const buildErrorResponse = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response) {
      // Request was made and the API responded with a status (and message)
      return (
        error.response.data?.message ??
        'Request was rejected on the server with an unknown reasoning. Please contact your system administrator'
      );
    }
    if (error.code) {
      // Request was made but no response was received
      switch (error.code) {
        case 'ERR_NETWORK': {
          return 'Network error: No response received from API. Please contact your administrator';
        }
        default: {
          return `Unknown error ${error.code}: ${error.message}`;
        }
      }
    }
  }

  // Error was raised which has not been accounted for above
  if (error instanceof Error) return `Unknown error occured: ${error.message}`;

  // Unknown failure with an unknown Error type
  return 'Unknown error occured. Please contact your administrator';
};

const calculateProgress = (progressEvent: AxiosProgressEvent) => {
  let value;
  let suffix;

  if (progressEvent.total) {
    value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    suffix = '%';
  } else {
    value = progressEvent.loaded;
    suffix = 'b';
    // Kilobytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'kb';
    }

    // Megabytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'mb';
    }
  }

  value = Math.round(value * 100) / 100;
  return `${value}${suffix}`;
};
