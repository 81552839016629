import dayjs from 'dayjs';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { isUserAvailable } from '../lib/common';
import { extractEvent } from '../lib/simulate';
import { selectAll as selectAllUnsavedChanges, selectAllChanges } from '../reducers/unsaved';
import { selectAll as selectAllUnallocatedChanges } from '../reducers/unallocatedAppointments';
import { selectAll as selectAllWeeklyPlannerChanges, selectFocusedWorkerId } from '../reducers/weeklyPlanner';

export const selectIsFocusedWorkerInRows = createSelector(
  [selectAllWeeklyPlannerChanges, selectFocusedWorkerId],
  (rows: any[], focusedWorkerId: number | null) => {
    if (!focusedWorkerId) return false;
    return rows.some(({ id }) => id === focusedWorkerId);
  },
);

export const selectWorkerFocusedRows = createSelector(
  [selectAllWeeklyPlannerChanges, selectFocusedWorkerId, selectIsFocusedWorkerInRows],
  (rows, focusedWorkerId, isFocusedWorkerInRows) => {
    if (!focusedWorkerId || !isFocusedWorkerInRows) return rows;

    const focusedRow = rows.find((row) => row.id === focusedWorkerId);
    if (!focusedRow) return rows;

    const otherRows = rows.filter((row) => row.id !== focusedWorkerId);
    return [focusedRow, ...otherRows];
  },
);

// Look for unsaved changes matching the ids of unallocated appointments, do not show if "allocated" but unsaved
export const selectUnallocated = createSelector(
  [selectAllUnallocatedChanges, selectAllUnsavedChanges],
  (unallocated, unsavedAppointments) =>
    unallocated
      .map((row) => ({ ...row, key: row.id ?? row.parent_repeat_id }))
      .filter(({ key }) => key !== null && !(key in unsavedAppointments)),
);

export const selectSimulatedChanges = createSelector(
  [selectAllUnsavedChanges, selectWorkerFocusedRows],
  (unsavedRows, savedRows) => {
    const rows = [...savedRows];
    unsavedRows.forEach((unsavedRow: any) => {
      const index = rows.findIndex(({ id }) => id === unsavedRow.id);
      if (index > -1) rows[index] = unsavedRow;
      else rows.push(unsavedRow);
    });
    return rows;
  },
);

export const selectChangeById = createSelector(
  [selectAllChanges, (_: RootState, key: number | null) => key],
  (rows, key) => (key ? rows[key] : null),
);

export const selectFilteredSimulatedAll = createSelector(
  selectSimulatedChanges,
  (state: RootState) => state.weeklyPlanner.focused,
  (state: RootState) => state.weeklyPlanner.filterOptions,
  (state: RootState) => state.weeklyPlanner.orderBy,
  (state: RootState) => state.weeklyPlanner.rows,
  (rows, focused, filterOptions, orderBy) => {
    if (!filterOptions || Object.values(filterOptions).every((value) => value === null)) {
      if (!orderBy.availability && !orderBy.distance) return rows;
    }

    const filteredRows = rows.filter((row) =>
      Object.values(filterOptions)
        .filter((ids) => Array.isArray(ids))
        .every((ids) => ids.includes(row.id)),
    );

    if (orderBy.availability) {
      const { date, start_time, end_time } = focused?.appointment || {};
      filteredRows.sort((a, b) => {
        const aAvailable = date
          ? isUserAvailable(
              a.availability?.[dayjs(date).format('dddd') as keyof typeof a.availability] ?? {},
              start_time,
              end_time,
            )
          : false;
        const bAvailable = date
          ? isUserAvailable(
              b.availability?.[dayjs(date).format('dddd') as keyof typeof b.availability] ?? {},
              start_time,
              end_time,
            )
          : false;

        if (aAvailable && !bAvailable) return -1;
        if (!aAvailable && bAvailable) return 1;

        return a.full_name.localeCompare(b.full_name);
      });
    }

    return filteredRows;
  },
);

export const selectInteracting = createSelector(
  selectSimulatedChanges,
  (state: RootState) => state.weeklyPlannerUnsaved.interacting,
  (rows, key) => {
    if (!key) return null;
    return extractEvent(rows, key) ?? null;
  },
);
