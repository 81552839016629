import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import crypto from 'crypto-js';

import { login } from './actions/login';
import { selectAuthenticated, selectErrorResponse } from './reducers/login';
import ErrorResponse from 'tsx/components/ErrorResponse';
import Button from 'tsx/components/Button';

const LOGIN_SECRET = process.env.REACT_APP_LOGIN_AUTHENTICATION_SECRET ?? '';
const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

function Login() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const AES = crypto.AES;

  const [values, setValues] = useState<{ email: string; password: string }>({ email: '', password: '' });

  const errorResponse = useAppSelector(selectErrorResponse);
  const isAuthenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const target = getTarget();
      navigate(target);
    }
  }, [isAuthenticated]);

  const getTarget = () => {
    const { state } = location;
    if (!state) return '/';

    const { from } = state;
    if (from && from !== '/login') return from;
    return '/';
  };

  const onChange = (name: string, value: string) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onSubmit = async () => {
    const { email, password } = values;
    dispatch(login({ email, password: AES.encrypt(password, LOGIN_SECRET).toString() }));
  };

  return (
    <div className="justify-content-center">
      <Row>
        <Col className="login d-flex justify-content-center mt-5">
          <Card className="rounded">
            <CardHeader className="d-flex justify-content-center bg-menu-background">
              <img src="/assets/tpassist_logo.png" alt="Logo" />
            </CardHeader>
            <CardBody className="d-flex flex-column text-center">
              <h3>Login</h3>
              <ErrorResponse message={errorResponse} />
              <Form>
                <FormGroup>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    placeholder="Email Address"
                    onChange={({ target: { name, value } }) => onChange(name, value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={({ target: { name, value } }) => onChange(name, value)}
                  />
                </FormGroup>
                <Button onClick={onSubmit} className="w-25" color="success">
                  Login
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="login d-flex justify-content-center">
          <Card className="rounded mt-3 justify-content-center">
            <CardHeader className="text-white">Forgot Password / PIN?</CardHeader>
            <CardBody className="d-flex justify-content-center">
              <Link to={`${REDIRECT_URL}/login.asp`}>
                <Button color="warning">Reset Password</Button>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
