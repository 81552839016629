import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap';
import Button from 'tsx/components/Button';
import ModalCard from 'tsx/components/ModalCard';
import { Icon, icons } from 'tsx/components/Icon';

import { Params } from 'tsx/libs/reduxUtils';
import { bulkUpdate } from 'tsx/features/appointments/actions/appointments';
import { selectAllChanges } from '../reducers/unsaved';

import { appointmentEditFields as appointmentFields } from 'tsx/features/appointments/forms/appointment/edit';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const ChangesDialog: React.FC<Props> = ({ isOpen = false, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const changes = useAppSelector(selectAllChanges);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSave = async (changes: any) => {
    setIsSaving(true);
    const data: Params[] = Object.entries(changes).map(([, change]: [string, any]) => change);
    const { payload } = await dispatch(bulkUpdate(data));

    setIsSaving(false);
    if (payload?.success) onClose();
  };

  const renderChanges = (changes: any) =>
    Object.entries(changes).map(([id, change], index) => (
      <div key={index} className="mb-2">
        <Row className="fw-bold mb-1">Appt #{id}</Row>
        {renderChange(change)}
      </div>
    ));

  const renderChange = (change: any) =>
    Object.entries(change)
      .filter(([key]) => key !== 'id')
      .map(([key, value]: any, index) => {
        return (
          <Row key={index} sm={4}>
            <Col className="text-end ">{appointmentFields[key]?.caption ?? key}</Col>
            <Col sm={1} className="text-center">
              <Icon icon={icons.faCaretRight} />
            </Col>
            <Col>{value}</Col>
          </Row>
        );
      });

  const modalProps = {
    className: 'border-0 rounded-0 mt-1 mb-1',
    headerClassName: 'rounded-0',
    bodyClassName: 'modal-body-scrollable',
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader className="bg-success text-white">Review Changes</ModalHeader>
      <ModalBody>
        <div className="mb-4 pb-2 border-2 border-bottom border-success">
          Please confirm the following changes to save...
        </div>
        <ModalCard {...modalProps} header="Show changes">
          {renderChanges(changes)}
        </ModalCard>
      </ModalBody>
      <ModalFooter className="align-items-center flex-column">
        <div>
          <Button size="sm" disabled={isSaving} color="success" onClick={() => onSave(changes)}>
            {isSaving && <Spinner type="grow" size="sm" className="ms-4 me-4" />}
            {!isSaving && <span>Save</span>}
          </Button>
          <Button size="sm" className="ms-2 ps-4 pe-4" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ChangesDialog;
