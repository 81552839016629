import React from 'react';
import { Alert, Collapse } from 'reactstrap';

import { Icon, icons } from 'tsx/components/Icon';
import { InputProps } from 'tsx/components/FormFields';

interface ComponentProps extends InputProps {
  color: string;
}

const AlertMessage: React.FC<ComponentProps> = ({ color = 'info', value }) => {
  let icon;
  switch (color) {
    case 'info':
    default: {
      icon = icons.faInfoCircle;
      break;
    }
    case 'success': {
      icon = icons.faCheckCircle;
      break;
    }
    case 'danger':
    case 'warning': {
      icon = icons.faTriangleExclamation;
      break;
    }
  }

  const isOpen = value !== undefined && value !== null && value?.length > 0;

  return (
    <Collapse isOpen={isOpen}>
      <Alert color={color} className="d-flex align-items-center justify-content-center">
        <Icon className="me-3" size="2x" icon={icon} />
        <div className="fw-bold">{value}</div>
      </Alert>
    </Collapse>
  );
};

export default AlertMessage;
