import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectAuthenticated } from './reducers/login';

function PrivateRoute() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tponeSessionKey = searchParams.get('tp1-session');
  const isAuthenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    // If tp1-session key, redirect to check session via component
    if (tponeSessionKey) {
      searchParams.delete('tp1-session');
      const searchParamString = searchParams.toString();
      const fromUrl = location.pathname + (searchParamString ? '?' + searchParamString : '');
      navigate('/auth-session', {
        state: { from: fromUrl, sessionKey: tponeSessionKey },
      });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !tponeSessionKey) {
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [isAuthenticated]);

  return <>{isAuthenticated && !tponeSessionKey && <Outlet />}</>;
}

export default PrivateRoute;
