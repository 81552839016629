import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import Button from 'tsx/components/Button';
import ContainerBanner from 'tsx/components/ContainerBanner';
import { Icon, icons } from 'tsx/components/Icon';

import { AuthTags } from 'tsx/constants/authTags';
import { Row as RecordRow } from 'tsx/types/reducers';

interface Props {
  appointment?: RecordRow;
  onAcknowledge: () => void;
}

function AppointmentDeclined({ appointment, onAcknowledge }: Props) {
  const navigate = useNavigate();
  const { id } = appointment ?? { id: null };

  const cancel = () => navigate(`/appointments/${id}/cancel`);

  return (
    <div className="m-2 border border-2 rounded border-danger">
      <ContainerBanner
        className="mb-3"
        color="danger"
        textColor="white"
        icon={icons.faTriangleExclamation}
        iconSize="2x"
        messages={['This declined appointment is pending action, please choose from the below options to confirm:']}
      />
      <div className="p-2">
        <ul>
          <li className="mb-2">
            <Row sm={6}>
              <Col className="mt-1">
                Mark as <b>Completed</b>
              </Col>
              <Col>
                <Button
                  authTag={AuthTags.APPOINTMENTS.UPDATE}
                  size="sm"
                  className="ms-2"
                  color="success"
                  onClick={onAcknowledge}
                >
                  <Icon className="me-2" icon={icons.faCheck} />
                  Acknowledge Decline
                </Button>
              </Col>
            </Row>
          </li>
          <li className="mb-2">
            <Row sm={6}>
              <Col className="mt-1">
                Mark as <b>Cancelled</b>
              </Col>
              <Col>
                <Button
                  authTag={AuthTags.APPOINTMENTS.CANCEL}
                  size="sm"
                  className="ms-2"
                  color="danger"
                  onClick={cancel}
                >
                  <Icon className="me-2" icon={icons.faBan} />
                  Cancel Appointment
                </Button>
              </Col>
            </Row>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AppointmentDeclined;
