import React from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';

import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { dateInputFieldFormat } from 'tsx/libs/dayjs';

function FormInputDate({ id, value, placeholder, disabled, onChange }: InputProps) {
  return (
    <Row className="align-items-center">
      <Col>
        <FormInput.DateInput
          type="date"
          className="pe-2"
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
      <Col sm={2}>
        {!disabled && (
          <Button
            size="sm"
            color="success"
            onClick={() => onChange && onChange(id, dayjs().format(dateInputFieldFormat))}
          >
            Today
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default FormInputDate;
