import { SearchProps } from 'tsx/components/FormFields/Search';
import { selectRegions } from 'tsx/features/main/reducers/regions';
import { selectUserDepartments } from 'tsx/features/care-workers/reducers/userDepartments';
import { selectLookupUsers } from 'tsx/features/main/reducers/users';

const fields: SearchProps = {
  user_id: {
    defaultShow: true,
    type: 'multi-lookup',
    field: 'id',
    caption: 'Care Worker',
    placeholder: 'All',
    selectorOptions: selectLookupUsers,
  },
  region_id: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'region_id',
    caption: 'Region',
    placeholder: 'All',
    selectorOptions: selectRegions,
  },
  department: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'department_id',
    caption: 'Department',
    placeholder: 'All',
    selectorOptions: selectUserDepartments,
  },
};

export default fields;
