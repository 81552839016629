import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState, store } from 'store';

import { get as getLocal, set as setLocal, remove as removeLocal } from 'tsx/libs/localstorage';
import { typePrefix, login, getSettings, authenticateSession } from '../actions/login';
import { State } from 'tsx/types/reducers';

const sessionKey = 'x-session-token';

interface LinkedAccount {
  id: number;
  full_name: string;
  company: {
    id: number;
    name: string;
  };
}

interface AuthState extends State {
  authenticated: boolean;
  sessionToken: string | null;
  forceRedirect: boolean;
  settings: { [key: string]: string | number | boolean | [] };
  defaults?: { [key: string]: string | number | boolean };
  user?: { [key: string]: string | number };
  tags?: string[];
  linkedAccounts?: LinkedAccount[];
}

const initialState: AuthState = {
  loading: 'idle',
  error: null,
  authenticated: getLocal(sessionKey) !== undefined,
  sessionToken: getLocal(sessionKey) ?? null,
  forceRedirect: false,
  rows: [],
  row: { id: -1 },
  settings: {},
  defaults: {},
  tags: [],
};

// Main slice, connecting API actions to redux state.
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    checkAuthenticated(state) {
      state.authenticated = getLocal(sessionKey) !== undefined;
    },
    removeAuthenticated(state) {
      state.authenticated = false;
      state.sessionToken = null;
      removeLocal(sessionKey);
    },
    setForceRedirect(state) {
      state.forceRedirect = true;
    },
    setSessionToken(state, action) {
      state.sessionToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.authenticated = true;
      state.user = action.payload.data;
    });
    builder.addCase(authenticateSession.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.authenticated = true;
      state.user = action.payload.data;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { company, user, tags, defaults } = action.payload.data;
      state.settings = company;
      state.defaults = defaults;
      state.user = user;
      state.tags = tags;
      state.linkedAccounts = user.linked_accounts;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Add x-session-token to request header if available
axios.interceptors.request.use(
  (request) => {
    const token = store.getState().login.sessionToken;
    if (token) {
      request.headers[sessionKey] = token;
    }
    return request;
  },
  (error) => error,
);

// Look for any unauthorised responses from the API.
axios.interceptors.response.use(
  (response) => {
    const token = response.headers[sessionKey];
    if (token) {
      store.dispatch(loginSlice.actions.setSessionToken(token));
      setLocal(sessionKey, token);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      const { data } = error.response;

      if (data.reason === 'company-disabled') store.dispatch(loginSlice.actions.setForceRedirect());
      store.dispatch(loginSlice.actions.removeAuthenticated());
    }
    return Promise.reject(error);
  },
);

export const selectErrorResponse = (state: RootState) => state.login.error;
export const selectAuthenticated = (state: RootState) => state.login.authenticated;
export const selectForceRedirect = (state: RootState) => state.login.forceRedirect;
export const selectCompanySettings = (state: RootState) => state.login.settings;
export const selectCompanyDefaults = (state: RootState) => state.login.defaults;
export const selectAuthenticatedUser = (state: RootState) => state.login.user;
export const selectUserTags = (state: RootState) => state.login.tags ?? [];
export const selectLinkedAccounts = (state: RootState) =>
  state.login.linkedAccounts?.map(({ id, company: { name } }) => ({ value: id, label: name }));

export default loginSlice.reducer;
