import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiAddressLookup } from 'tsx/constants/api';

export const typePrefix = 'address-lookup';

const config = [
  { actionType: 'getAddress', apiPath: `${apiAddressLookup}/addressLookup`, method: get },
  { actionType: 'getDetails', apiPath: `${apiAddressLookup}/addressDetails`, method: get },
];

export const { getAddress, getDetails } = createThunks(typePrefix, config);
