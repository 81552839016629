import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { State } from 'tsx/types/reducers';
import { typePrefix, getAll, getOne } from 'tsx/features/main/actions/clientReferrers';
import { RootState } from 'store';

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const clientReferrersSlice = createSlice({
  name: typePrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });

    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const selectClientReferrers = (state: RootState) => state.clientReferrers.rows;

export default clientReferrersSlice.reducer;
