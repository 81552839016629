import React, { useState } from 'react';
import { Col, Input, Row } from 'reactstrap';

import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

function FormInputTravelTime({ id, value = '', disabled, onChange }: InputProps) {
  const [override, setOverride] = useState(false);
  const message = !value ? 'Not entered' : `${value} mins`;

  return (
    <Row className="align-items-center">
      <Col className="d-flex align-items-center">
        {override && (
          <>
            <Input
              type="number"
              name={id}
              id={id}
              disabled={disabled}
              value={value ?? ''}
              className="w-50"
              onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
            />
            <span className="ms-2">mins</span>
          </>
        )}
        {!override && <FormInput.ReadOnly type="readonly" name={id} id={id} value={message} />}
      </Col>
      <Col>
        {!override && (
          <Button type="button" size="sm" color="success" onClick={() => setOverride(!override)}>
            Override
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default FormInputTravelTime;
