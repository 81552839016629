import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { CommonState, addCommonReducers } from 'tsx/libs/reduxUtils';
import { typePrefix, getAll, getOne, getColours } from '../actions/serviceTypes';

interface ServiceTypeRow {
  id: number;
  name: string;
  allow_transport: boolean;
  ndis_support_item: number;
  default_qualification_ids?: Array<number>;
  deleted: boolean;
  colour?: string;
}

export interface Colour {
  name: string;
  id: number;
  deleted: string;
  colour: string;
}

interface State extends CommonState<ServiceTypeRow> {
  colourRows: Array<Colour>;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  colourRows: [],
};

// Main slice, connecting API actions to redux state.
export const serviceTypesSlice = createSlice({
  name: 'service-types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getColours.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.colourRows = action.payload.data;
    });
    addCommonReducers<ServiceTypeRow>(builder, typePrefix, getAll, getOne);
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectAppointmentServiceTypes = (state: RootState) => state.appointmentServiceTypes.rows;
export const selectAppointmentServiceTypeColours = (state: RootState) => state.appointmentServiceTypes.colourRows;

export const selectAppointmentServiceTypeById = createSelector(
  [selectAppointmentServiceTypes, (_: RootState, id?: number) => id],
  (rows, id) => rows.find(({ id: rowId }) => id === rowId),
);

export const selectLookupAppointmentServiceTypes = createSelector(selectAppointmentServiceTypes, (rows) =>
  rows.map(({ id, name, deleted }) => ({
    id,
    name: `${name}${deleted ? ` - DELETED` : ''}`,
  })),
);

export const selectServiceTypeColourById = createSelector(selectAppointmentServiceTypeById, (serviceType) =>
  serviceType?.colour ? `#${serviceType.colour}` : null,
);

export default serviceTypesSlice.reducer;
