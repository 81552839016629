import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiStates } from 'tsx/constants/api';

export const typePrefix = 'states';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiStates,
    method: get,
    actionParams: { attributes: ['id', 'name'], limit: 0 },
  },
];

export const { getAll } = createThunks(typePrefix, config);
