import { FieldProps } from 'tsx/components/FormFields';

import FormInputSignature from '../../components/FormInputSignature';
import FormInputVehicleType from '../../components/FormInputVehicleType';
import FormInputTravelTime from '../../components/FormInputTravelTime';
import FormInputDistanceTravelled from '../../components/FormInputDistanceTravelled';
import FormInputActualTimes from '../../components/FormInputActualTimes';
import FormInputTimeMarked from '../../components/FormInputTimeMarked';

import { deriveActualTimes, deriveTimeMarked, deriveDistanceTravelled } from '../../lib/derive';
import { showTimeMarked, showAppointmentDateOrLess } from '../../lib/show';

import { commonFields } from './common';

const appointmentActiveFields: FieldProps = {
  ...commonFields,
  date: {
    type: 'readonly',
    caption: 'Appointment Date',
    order: 120,
  },
  client_package_name: {
    type: 'readonly',
    caption: 'Default Package',
    field: 'client.default_package.name',
    className: 'text-left',
    order: 220,
  },
  user_id: {
    type: 'readonly',
    field: 'user.full_name',
    caption: 'Care Worker',
    order: 910,
  },
  time_marked: {
    type: 'custom',
    caption: 'Time Marked',
    labelCaption: 'Auto marked by the system',
    colSize: 6,
    component: FormInputTimeMarked,
    order: 1110,
    deriveValue: deriveTimeMarked,
    show: showTimeMarked,
  },
  distance_travelled: {
    type: 'custom',
    caption: 'Distance Travelled',
    labelCaption: 'Entered by the care worker',
    colSize: 6,
    component: FormInputDistanceTravelled,
    deriveValue: deriveDistanceTravelled,
    show: showAppointmentDateOrLess,
    order: 1120,
  },
  travel_1: {
    type: 'custom',
    caption: 'Time Travelled (TO Client)',
    labelCaption: 'Entered by the care worker',
    component: FormInputTravelTime,
    colSize: 6,
    order: 1130,
    show: showAppointmentDateOrLess,
  },
  vehicle_type: {
    type: 'custom',
    caption: 'Vehicle Type',
    labelCaption: 'Entered by the care worker',
    component: FormInputVehicleType,
    colSize: 6,
    order: 1140,
    show: showAppointmentDateOrLess,
    options: [
      { id: 'user', name: 'My Car (Care Worker)' },
      { id: 'company', name: 'Company' },
    ],
  },
  actual_times: {
    type: 'custom',
    caption: 'Actual Times Entered',
    labelCaption: 'Entered by the care worker',
    component: FormInputActualTimes,
    deriveValue: deriveActualTimes,
    colSize: 6,
    order: 1150,
    show: showAppointmentDateOrLess,
  },
  signature: {
    type: 'custom',
    caption: 'Client Signature',
    component: FormInputSignature,
    order: 1160,
  },
};

export { appointmentActiveFields };
