import { store } from 'store';
import { deriveDuration } from './derive';

export const validateTasks: (values: any) => boolean = ({ task_ids = [] }) =>
  task_ids === null || task_ids?.length <= 45;

export const validateStartEndTimes: (values: any) => boolean = ({ start_time, end_time, ends_next_day = false }) => {
  if (start_time === undefined || start_time === '') return false;
  if (end_time === undefined || end_time === '') return false;
  return deriveDuration({ start_time, end_time, ends_next_day }) >= 0;
};

export const validateRepeatDates: (values: any) => boolean = ({ repeat, repeat_start }) => {
  if (!repeat) return true;
  if (repeat_start === undefined || repeat_start === '') return false;
  return true;
};

export const validateRepeatFrequency: (values: any) => boolean = ({ repeat, repeat_weekday, repeat_week_count }) => {
  if (!repeat) return true;
  if (!repeat_weekday || !repeat_week_count) return false;
  return true;
};

export const validateCareWorker: (values: any) => { valid: boolean; message?: string } = ({
  client_id,
  user_id = '',
}) => {
  if (!user_id) return { valid: true };

  const state = store.getState();
  const { clients } = state;
  const client = clients.rows.find(({ id }) => parseInt(client_id) === id);

  const { preferences } = client ?? {};
  const { no = [] } = preferences ?? {};
  if (no?.includes(parseInt(user_id))) {
    return {
      valid: false,
      message: 'Chosen care worker is not preferred by this client',
    };
  }

  return {
    valid: true,
  };
};

export const validateCancelReason: (values: any) => boolean = ({ cancellation_reason }) => {
  const state = store.getState();
  const {
    login: { settings },
  } = state;
  if (settings?.SYSTEM_CANCELLATION_MANDATORY_REASON === true && !cancellation_reason) return false;
  return true;
};

export const validateCancelReasonNote: (values: any) => boolean = ({ cancellation_reason_note }) => {
  const state = store.getState();
  const {
    login: { settings },
  } = state;
  if (settings?.SYSTEM_CANCELLATION_MANDATORY_REASON_NOTE === true && !cancellation_reason_note) return false;
  return true;
};
