import {
  faUser,
  faPencil,
  faArrowLeft,
  faArrowRight,
  faUsers,
  faInfoCircle,
  faBars,
  faBan,
  faCheck,
  faTriangleExclamation,
  faPlus,
  faLock,
  faBusinessTime,
  faMinus,
  faMagnifyingGlass,
  faBusSimple,
  faGears,
  faFileContract,
  faChalkboardUser,
  faFileInvoiceDollar,
  faCommentDollar,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faExternalLink,
  faSortDown,
  faSortUp,
  faEdit,
  faCheckCircle,
  faBuildingUser,
  faPersonArrowDownToLine,
  faPersonArrowUpFromLine,
  faEllipsis,
  faEnvelope,
  faFileExcel,
  faClock,
  faCar,
  faCommentSms,
  faCaretRight,
  faHourglassStart,
  faHourglassEnd,
  faEye,
  faEyeSlash,
  faHouse,
  faRoute,
  faUtensils,
  faCouch,
  faCalculator,
  faUserNurse,
  faUserPen,
  faRepeat,
  faCircle,
  faGear,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faFileLines, faSun } from '@fortawesome/free-regular-svg-icons';

export const icons = {
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faBuildingUser,
  faBusinessTime,
  faBusSimple,
  faCalculator,
  faCar,
  faCaretRight,
  faChalkboardUser,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCommentDollar,
  faCommentSms,
  faCouch,
  faEdit,
  faEllipsis,
  faEnvelope,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFileContract,
  faFileExcel,
  faFileInvoiceDollar,
  faFileLines,
  faGear,
  faGears,
  faHourglassStart,
  faHourglassEnd,
  faHouse,
  faInfoCircle,
  faLock,
  faMagnifyingGlass,
  faMinus,
  faPencil,
  faPersonArrowDownToLine,
  faPersonArrowUpFromLine,
  faPlus,
  faQuestionCircle,
  faRepeat,
  faRoute,
  faSortDown,
  faSortUp,
  faSun,
  faTriangleExclamation,
  faUser,
  faUserNurse,
  faUserPen,
  faUsers,
  faUtensils,
  faXmark,
  faUpRightFromSquare,
};
