import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { Collapse, Table } from 'reactstrap';

import { formatWeekday } from 'tsx/libs/dayjs';
import { InputProps } from 'tsx/components/FormFields';
import { Icon, icons } from 'tsx/components/Icon';

import { getAvailability } from '../../main/actions/users';

import { selectUserAvailability } from '../../main/reducers/users';

const FormInputWorkerAvailability: React.FC<InputProps> = ({ value = {}, id }) => {
  // Check for change
  const dispatch = useAppDispatch();
  const availableDays = useAppSelector(selectUserAvailability);
  const { date, user_id, repeat, repeat_start } = value;

  const checkDate = repeat ? repeat_start : date;
  const isOpen = !isNaN(parseInt(user_id)) && checkDate.length > 0;

  useEffect(() => {
    // If user and date selected, check for leave requests in relevant period
    if (isOpen) {
      dispatch(
        getAvailability({
          id: user_id,
          appointment_date: checkDate,
        }),
      );
    }
  }, [date, user_id, repeat_start]);

  const headerClassName = 'ps-2 pe-2 border-bottom';
  const headerCols = [<th key={0} className={headerClassName}></th>];
  const startCols = [
    <td key={0} title="Start">
      <Icon icon={icons.faHourglassStart} size="sm" />
    </td>,
  ];
  const endCols = [
    <td key={0} title="End">
      <Icon icon={icons.faHourglassEnd} size="sm" />
    </td>,
  ];
  for (let index = 1; index <= 7; index++) {
    const weekDay = formatWeekday(index);

    // Create header starting from Monday
    headerCols.push(
      <th key={index} className={headerClassName}>
        {weekDay}
      </th>,
    );

    // Build availability based on current available days in selector
    const availableDay = availableDays && availableDays[weekDay as keyof typeof availableDays];

    if (availableDay) {
      let { start, end } = availableDay;
      if (!start || start === '0000') start = '';
      if (!end || end === '0000') end = '';

      if (start.length > 0) start = start.padStart(4, '0');
      if (end.length > 0) end = end.padStart(4, '0');

      startCols.push(<td key={index}>{start}</td>);
      endCols.push(<td key={index}>{end}</td>);
    }
  }

  return (
    <Collapse isOpen={isOpen}>
      <Table className="text-center" data-id={id}>
        <thead>
          <tr>{headerCols}</tr>
        </thead>
        <tbody>
          <tr>{startCols}</tr>
          <tr>{endCols}</tr>
        </tbody>
      </Table>
    </Collapse>
  );
};

export default FormInputWorkerAvailability;
