import React, { useEffect } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { InputProps } from 'tsx/components/FormFields';
import { ReadOnly } from 'tsx/components/FormFields/Inputs';

const Currency: React.FC<InputProps> = ({ id, value, disabled = false, onChange, setFieldValue }) => {
  const readonlyCurrencyFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formatIncomeInputString = (input: string) => {
    // Remove any non-numeric characters except the decimal point
    let value = input.replace(/[^\d.]/g, '');

    // Handle leading decimal point
    if (value.startsWith('.')) {
      value = '0' + value;
    }

    // Split on decimal point
    const parts = value.split('.');

    // Restrict to 2 decimal places
    if (parts.length > 2 || (parts[1] && parts[1].length > 2)) {
      value = parts[0] + '.' + (parts[1] ? parts[1].substring(0, 2) : '');
    }

    return value;
  };

  const formatCurrency = (value: string): string => {
    return parseFloat(value.trim() || '0').toFixed(2);
  };

  const formatReadOnly = (value: string) => {
    if (value) {
      return readonlyCurrencyFormatter.format(parseFloat(value));
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedInput = formatIncomeInputString(value);

    setFieldValue(id, formattedInput);
    onChange && onChange(id, formattedInput);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setFieldValue(id, formatCurrency(value));
  };

  useEffect(() => {
    setFieldValue(id, formatCurrency(value?.toString() ?? ''));
  }, []);

  return (
    <>
      {!disabled && (
        <InputGroup>
          <InputGroupText>$</InputGroupText>
          <Input
            id={id}
            name={id}
            value={value}
            disabled={disabled}
            className="ms-1"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </InputGroup>
      )}
      {disabled && <ReadOnly type="readonly" id={id} name={id} value={formatReadOnly(value?.toString() ?? '0')} />}
    </>
  );
};

export default Currency;
