import { FieldProps } from 'tsx/components/FormFields';

import { appointmentNewFields, repeatAppointmentNewFields } from './appointment/new';
import { appointmentEditFields, repeatAppointmentEditFields } from './appointment/edit';
import { appointmentCancelledFields, repeatCancelledFields } from './appointment/cancelled';
import { appointmentActiveFields } from './appointment/completed';

import { AppointmentStatuses } from 'tsx/constants/maps';

export const get: (values: any) => FieldProps = ({ isNew, isRepeat, status_id }) => {
  if (isNew) {
    if (isRepeat) return repeatAppointmentNewFields;
    return appointmentNewFields;
  }

  switch (parseInt(status_id)) {
    case AppointmentStatuses.STARTED:
    case AppointmentStatuses.COMPLETED: {
      return appointmentActiveFields;
    }
    case AppointmentStatuses.CANCELLED: {
      if (isRepeat) return repeatCancelledFields;
      return appointmentCancelledFields;
    }
    default: {
      if (isRepeat) return repeatAppointmentEditFields;
      return appointmentEditFields;
    }
  }
};
