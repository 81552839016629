import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import { throttle } from 'lodash';
import { getDayString } from 'tsx/libs/dayjs';

import Button from 'tsx/components/Button';
import { Icon, icons } from 'tsx/components/Icon';
import Spinner from 'tsx/components/Spinner';
import MissingQualificationsViewMenu from './MissingQualificationViewMenu';
import WorkerQualifications from '../WorkerQualifications';

import { getAll as getMissingQualifications } from '../../actions/missingQualificationAppointments';

import {
  clear,
  selectAll as selectMissingQualifications,
  selectHasLoaded,
  selectLoading,
  selectParams,
  updateParams,
} from '../../reducers/missingQualificationAppointments';
import { focusWorker, selectOptions as selectWeeklyPlannerOptions } from '../../reducers/weeklyPlanner';

interface ComponentProps {
  weekStart: string;
}

const MissingQualifications: React.FC<ComponentProps> = React.memo(({ weekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const appointments = useAppSelector(selectMissingQualifications);
  const { missingQualificationsExtendedQualifications } = useAppSelector(selectWeeklyPlannerOptions);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  // Load on scroll
  const missingQualContainerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!missingQualContainerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = missingQualContainerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= appointments.length) {
        dispatch(updateParams({ ...params, limit: limit + 30 }));
      }
    }, 200),
    [limit, appointments.length, dispatch, isLoading],
  );

  // Fetch data on param change
  useEffect(() => {
    const container = missingQualContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (!isLoading && week_start && limit) {
      dispatch(getMissingQualifications(params));
    }
  }, [params, dispatch]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || weekStart !== week_start) {
      dispatch(updateParams({ week_start: weekStart, limit: 30 }));
    }
  }, [hasLoaded, weekStart, week_start, dispatch]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  const renderResults = () => {
    return appointments.map(({ client, user, start_time, date, qualification_level }, index) => {
      return (
        <Row key={index} className="underline missing-qualifications-card">
          <Col>
            <div>{client?.full_name}</div>
            <div>
              {getDayString(date)} {start_time}
            </div>
          </Col>
          <Col>{user.full_name}</Col>
          <Col className="worker-qualifications">
            <WorkerQualifications
              qualifications={qualification_level.filter((item: string) => {
                return user.qualifications.includes(item) ? null : item;
              })}
              isCondensed={!missingQualificationsExtendedQualifications}
              isWrapped={!missingQualificationsExtendedQualifications}
            />
          </Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
              <Icon icon={icons.faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  return (
    <div ref={missingQualContainerRef} className="card-container">
      <Container className="missing-qualifications">
        {hasLoaded && appointments.length > 0 ? (
          <>
            <div className="nav card-header">
              <MissingQualificationsViewMenu />
            </div>
            <Row className="fw-bold underline flex-nowrap">
              <Col>Appointment</Col>
              <Col>Worker</Col>
              <Col>Missing Qualifications</Col>
              <Col sm={1} />
            </Row>
            {renderResults()}
          </>
        ) : (
          <Row>No Missing Qualifications found</Row>
        )}
        {appointments.length >= 1 && <Spinner loading={isLoading} className="p-5" />}
      </Container>
      {appointments.length < 1 && <Spinner loading={isLoading} className="p-5" />}
    </div>
  );
});

MissingQualifications.displayName = 'MissingQualifications';
export default MissingQualifications;
