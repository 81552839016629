import React, { PropsWithChildren, useState } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import classNames from 'classnames';
import { Icon, icons } from 'tsx/components/Icon';

type Props = {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  isOpen?: boolean;
  setIsOpen?: (toggle: boolean) => void;
  allowOpen?: boolean;
  allowHeaderOpen?: boolean;
  header: React.ReactNode | string;
};

function ModalCard({
  className,
  headerClassName,
  bodyClassName,
  isOpen,
  setIsOpen,
  allowOpen = true,
  allowHeaderOpen = true,
  header,
  children,
}: PropsWithChildren<Props>) {
  const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);

  const toggle = () => {
    if (isOpen !== undefined && setIsOpen) setIsOpen(!isOpen);
    else setIsOpenUncontrolled(!isOpenUncontrolled);
  };

  const headerIsString = typeof header === 'string';

  const cardClass = classNames({
    [`${className}`]: className !== undefined,
  });

  const headerClass = classNames('d-flex justify-content-between align-items-center border-0', {
    [`${headerClassName}`]: headerClassName !== undefined,
  });

  const bodyClass = classNames({
    [`${bodyClassName}`]: bodyClassName !== undefined,
  });

  const isCollapseOpen = typeof isOpen == 'boolean' ? isOpen : isOpenUncontrolled;

  return (
    <Card className={cardClass}>
      <CardHeader className={headerClass} type={'button'} onClick={() => allowHeaderOpen && toggle()}>
        {headerIsString && <div>{header}</div>}
        {!headerIsString && header}
        {allowOpen && <Icon icon={isCollapseOpen ? icons.faChevronUp : icons.faChevronDown} className="ms-2" />}
      </CardHeader>
      <Collapse isOpen={isCollapseOpen}>
        <CardBody className={bodyClass}>{children}</CardBody>
      </Collapse>
    </Card>
  );
}

export default ModalCard;
