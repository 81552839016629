import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Container } from 'reactstrap';
import { throttle } from 'lodash';

import { Icon, icons } from 'tsx/components/Icon';
import Spinner from 'tsx/components/Spinner';

import { buildAssignChange } from '../../lib/simulate';
import { Appointment } from '../../lib/common';

import UnallocatedCard from '../UnallocatedCard';
import UnallocatedViewMenu from './UnallocatedViewMenu';
import Search from './Search';

import { getAll as getUnallocated } from '../../actions/unallocatedAppointments';
import { getAssignableUsers } from '../../actions/weeklyPlannerAppointments';
import { simulate } from '../../actions/unsaved';

import {
  clear,
  selectLoading,
  selectHasLoaded,
  selectParams,
  updateParams,
} from '../../reducers/unallocatedAppointments';
import { selectSimulatedChanges, selectUnallocated } from '../../selectors/simulate';

interface Filters {
  client_id: number | null;
  user_id: number | string | null;
  suburb: string | null;
  date: string | null;
}

interface ComponentProps {
  weekStart: string;
}

const UnallocatedAppointments: React.FC<ComponentProps> = React.memo(({ weekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const appointments = useAppSelector(selectUnallocated);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  const [openAppointment, setOpenAppointment] = useState<number | null>(null);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });

  // Load on scroll
  const containerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= appointments.length) {
        dispatch(updateParams({ ...params, limit: limit + 30 }));
      }
    }, 200),
    [limit, appointments.length, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (!isLoading && week_start && limit) {
      dispatch(getUnallocated(params));
    }
  }, [params, dispatch]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || weekStart !== week_start) {
      dispatch(updateParams({ week_start: weekStart, limit: 30 }));
    }
  }, [hasLoaded, weekStart, week_start, dispatch]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  const onAssign = (appointment: Appointment) => {
    // Build assign is expecting multiple appointments being assigned in one run, add an array of one change.
    const change = buildAssignChange(weeklyPlannerData, [appointment]);
    dispatch(simulate({ ...change, week_start: weekStart }));
  };

  const onBulkAssign = () => {
    const targets = appointments.filter(({ user_id, user }) => {
      if (!user_id || !user) return false;
      const { is_qualified, is_available } = user;
      if (!is_qualified || !is_available) return false;
      return true;
    });

    const change = buildAssignChange(weeklyPlannerData, targets);
    dispatch(simulate({ ...change, week_start: weekStart }));
  };

  const onMenuToggle = (key: number | null) => {
    if (key && openAppointment !== key) dispatch(getAssignableUsers({ id: key }));
    setOpenAppointment((prevKey) => (prevKey === key ? null : key));
  };

  const renderAppointments = (appointments: Appointment[]) => {
    if (appointments.length > 0) {
      return appointments.map((appointment, index) => (
        <UnallocatedCard
          key={index}
          appointment={appointment}
          isActionMenuOpen={openAppointment === appointment.key}
          onToggle={() => onMenuToggle(appointment.key)}
          onAssign={onAssign}
          anyActionMenuOpen={!!openAppointment}
        />
      ));
    }
    return <p className="text-center pt-2 fw-bold">No results found.</p>;
  };

  // Filter appointments using search filters
  const filteredAppointments: Appointment[] = appointments.filter((appt) => {
    if (filters.client_id && appt.client.id !== filters.client_id) return false;
    if (filters.suburb && appt.client.suburb !== filters.suburb) return false;
    if (filters.date && appt.repeat_next_date !== filters.date) return false;
    if (filters.user_id && appt.user?.id !== filters.user_id) return false;
    return true;
  });

  return (
    <div ref={containerRef} className="card-container">
      <Container className="unallocated-appointments">
        {hasLoaded && appointments.length > 0 ? (
          <>
            <div className="nav card-header">
              <Icon
                icon={icons.faMagnifyingGlass}
                className="search-icon me-2"
                title="Search Appointments"
                onClick={() => setSearchOpen(!isSearchOpen)}
              />
              <Icon icon={icons.faUserPen} className="me-2" title="Assign Preferred Workers" onClick={onBulkAssign} />
              <UnallocatedViewMenu />
            </div>
            <Search isOpen={isSearchOpen} appointments={appointments} weekStart={weekStart} setFilters={setFilters} />
            <div>{renderAppointments(filteredAppointments)}</div>
          </>
        ) : (
          <p className="text-center pt-2 fw-bold">No appointments found.</p>
        )}
        {appointments.length >= 1 && <Spinner loading={isLoading} className="p-5" />}
      </Container>
      {appointments.length < 1 && <Spinner loading={isLoading} className="p-5" />}
    </div>
  );
});

UnallocatedAppointments.displayName = 'UnallocatedAppointments';
export default UnallocatedAppointments;
