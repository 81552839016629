import React, { useState } from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import headerMenu from 'tsx/constants/headerMenu';
import {
  selectAuthenticatedUser,
  selectCompanyDefaults,
  selectCompanySettings,
  selectLinkedAccounts,
  selectUserTags,
} from './reducers/login';

const Header: React.FC = () => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [switchToggle, setSwitchToggle] = useState<boolean>(false);
  const user = useAppSelector(selectAuthenticatedUser);
  const companyDefaults = useAppSelector(selectCompanyDefaults);
  const tags = useAppSelector(selectUserTags);
  const settings = useAppSelector(selectCompanySettings);
  const linkedAccounts = useAppSelector(selectLinkedAccounts);
  const userFullName = user ? `${user.first_name} ${user.surname}` : '';

  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

  const renderUserName = <span className="fw-normal">{userFullName}</span>;

  return (
    <Navbar className="menu">
      <div className="d-flex flex-nowrap justify-content-between align-items-center w-100 ">
        <div className="d-flex align-items-center">
          <Nav>
            {headerMenu(user, settings, companyDefaults).map(
              ({ caption, link = '', children = [], authTag = null }, index) => {
                const isAuthorized = !authTag || tags.includes(authTag);
                if (!isAuthorized) return null;

                const headerClass = classNames('menu-link', {
                  [`active`]: openMenuIndex === index,
                });

                // avoid rendering empty headers if user has no authTags
                const authorizedChildren = children.filter(({ authTag }) => !authTag || tags.includes(authTag));
                if (!authorizedChildren.length && !link) return null;

                if (authorizedChildren.length > 0) {
                  return (
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      onMouseEnter={() => setOpenMenuIndex(index)}
                      onMouseLeave={() => setOpenMenuIndex(null)}
                      toggle={() => setOpenMenuIndex(openMenuIndex !== index ? index : null)}
                      isOpen={openMenuIndex === index}
                      key={index}
                    >
                      <DropdownToggle nav caret className={headerClass}>
                        {caption}
                      </DropdownToggle>
                      <DropdownMenu>
                        {authorizedChildren.map(({ caption, link }, childIndex) => (
                          <DropdownItem key={childIndex} tag={RouterNavLink} to={link} className="menu-dropdown-item">
                            {caption}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  );
                }

                return (
                  <NavItem key={index}>
                    <NavLink href={link} className="menu-link">
                      {caption}
                    </NavLink>
                  </NavItem>
                );
              },
            )}
          </Nav>
        </div>
        <div className="d-flex flex-row align-items-center mt-2 menu-user">
          <div className="d-flex me-3 fw-bold">
            <span className="align-self-center me-2">Logged in as:</span>
            {linkedAccounts && linkedAccounts.length > 1 ? (
              <UncontrolledDropdown
                inNavbar
                className="d-flex"
                isOpen={switchToggle}
                toggle={() => setSwitchToggle(!switchToggle)}
              >
                <DropdownToggle nav caret className={`menu-link ${switchToggle && `active`}`}>
                  {renderUserName}
                </DropdownToggle>
                <DropdownMenu>
                  {linkedAccounts.map(({ value, label }, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() =>
                        window.location.replace(`${REDIRECT_URL}/user-linked-account-swap.asp?swapID=${value}`)
                      }
                    >
                      <span className="fw-normal">{label}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              renderUserName
            )}
          </div>
          <Link className="menu-logout" to="/logout">
            Log out
          </Link>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
