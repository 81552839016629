import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get, put } from 'tsx/libs/genericAction';
import { apiAppointmentServiceTypes } from 'tsx/constants/api';

export const typePrefix = 'appointments-service-types';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiAppointmentServiceTypes,
    method: get,
    actionParams: {
      deleted: false,
      sort: ['priority', 'name'],
      attributes: ['id', 'name', 'allow_transport', 'ndis_support_item', 'default_qualification_ids', 'deleted'],
      limit: 0,
    },
  },
  { actionType: 'getColours', apiPath: `${apiAppointmentServiceTypes}/colours`, method: get },
  {
    actionType: 'getOne',
    apiPath: (params: Params) => `${apiAppointmentServiceTypes}/${params.id}`,
    method: get,
  },
  {
    actionType: 'upsertColour',
    apiPath: (params: Params) => `${apiAppointmentServiceTypes}/${params.id}/colours`,
    method: put,
  },
];

export const { getAll, getColours, getOne, upsertColour } = createThunks(typePrefix, config);
