import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { formatDateString, formatTimeString } from 'tsx/libs/dayjs';

const columns: ColumnDef<any>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    cell: (info) => info.getValue(),
    enableHiding: true,
  },
  {
    id: 'client.full_name',
    accessorKey: 'client.full_name',
    header: 'Client',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
    meta: {
      link: ({ id }: any) => `/appointments/${id}`,
      hoverIcon: faEdit,
    },
  },
  {
    id: 'user.full_name',
    accessorFn: (row) => row.user?.full_name ?? '',
    header: 'Care Worker',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'client.referrer_name',
    accessorKey: 'client.referrer_name',
    header: 'Referrer',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'client.region.name',
    accessorFn: (row) => row.client?.region?.name ?? '',
    header: 'Client Region',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'repeat_start',
    accessorKey: 'repeat_start',
    header: 'Repeat Start',
    cell: (info) => formatDateString(info.getValue()),
    sortUndefined: 'last',
    sortingFn: 'datetime',
  },
  {
    id: 'start_time',
    header: 'Start Time',
    accessorFn: (row) => row?.start_time ?? '',
    cell: (info) => formatTimeString(info.getValue()),
    sortUndefined: 'last',
  },
  {
    id: 'repeat_weekday',
    accessorFn: (row) => row.repeat_frequency?.day ?? '',
    header: 'Week Day',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'repeat_week_count',
    accessorFn: (row) => row.repeat_frequency?.frequency ?? '',
    header: 'Week Repeat Count',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'status_id',
    accessorKey: 'status.name',
    header: 'Status',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'service_type.name',
    accessorFn: ({ service_type }) =>
      service_type !== undefined ? `${service_type.name}${service_type.deleted ? ' - DELETED' : ''}` : '',
    header: 'Service Type',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'package_fixed.name',
    accessorFn: (row) => row.package_fixed?.name ?? '',
    header: 'Package',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
];

export default columns;
