import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { Icon, icons } from 'tsx/components/Icon';
import { Break } from '../lib/common';

interface ComponentProps {
  data: Break;
  style?: CSSProperties;
}

const BreakCard: React.FC<ComponentProps> = ({ data, style }) => {
  const { duration, type } = data;
  const className = classNames('break-card', {
    [`${type}`]: type !== undefined,
  });

  let icon;
  switch (type) {
    case 'meal': {
      icon = icons.faUtensils;
      break;
    }

    case 'shift':
    default: {
      icon = icons.faCouch;
      break;
    }
  }

  return (
    <div
      className={className}
      style={style}
      title={`${type.toUpperCase()} Break (~${typeof duration === 'number' ? Math.round(duration) : duration}mins)`}
    >
      <div>{type.toUpperCase()}</div>
      <div className="icons">
        <Icon icon={icon} size="sm" />
      </div>
    </div>
  );
};

export default BreakCard;
