import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import { InputProps } from 'tsx/components/FormFields';
import { datePickerDisplayFormat, dateInputFieldFormat } from 'tsx/libs/dayjs';

const DateInput: React.FC<InputProps> = ({ id, value, placeholder, disabled = false, className, onChange, name }) => {
  let dateValue = null;

  if (value) dateValue = dayjs(value, dateInputFieldFormat);

  const setChange = (value: Dayjs | null) => {
    onChange && onChange(id, value?.format(dateInputFieldFormat));
  };

  const dateClass = classNames('form-control', {
    [`${className}`]: className != undefined,
  });

  return (
    <DatePicker
      value={dateValue}
      disabled={disabled}
      label={placeholder}
      name={name}
      format={datePickerDisplayFormat}
      className={dateClass}
      slotProps={{
        field: { shouldRespectLeadingZeros: true },
        textField: { size: 'small' },
        actionBar: { actions: ['clear', 'today'] },
      }}
      onChange={(value) => setChange(value)}
    />
  );
};

export default DateInput;
