import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';

interface Item {
  key: string;
  label?: string;
}

interface ComponentProps {
  caption?: string;
  items?: Item[];
  onClick?: (values: string[]) => void;
  values?: string[];
}

const DropdownCheckbox: React.FC<ComponentProps> = ({ caption = 'Select', items = [], values = [], onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (key: string, checked: boolean) => {
    const changedValues = [...values];
    const index = changedValues.indexOf(key);
    if (index > -1 && !checked) changedValues.splice(index, 1);
    else if (checked) changedValues.push(key);
    onClick && onClick(changedValues);
  };

  const checkboxItems = items.map(({ key, label }, index) => {
    const checked = values.includes(key);
    return (
      <DropdownItem key={index} toggle={false} onClick={() => handleClick(key, !checked)}>
        <Input type="checkbox" name={key} id={key} checked={checked} onChange={() => handleClick(key, !checked)} />
        <Label className="ms-2 mb-0">{label}</Label>
      </DropdownItem>
    );
  });

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} direction="down">
      <DropdownToggle caret size="sm">
        {caption}
      </DropdownToggle>
      <DropdownMenu>{checkboxItems}</DropdownMenu>
    </Dropdown>
  );
};

export default DropdownCheckbox;
