import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiCareQualificationLevels } from 'tsx/constants/api';

export const typePrefix = 'appointments-care-qualification-levels';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiCareQualificationLevels,
    method: get,
    actionParams: { disabled: false, sort: ['order'], limit: 0 },
  },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiCareQualificationLevels}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
