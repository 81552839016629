import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'tsx/components/FormFields';

const Switch: React.FC<InputProps> = ({ id, value, disabled = false, onChange }) => {
  return (
    <FormGroup switch>
      <Input
        id={id}
        type="switch"
        name="switch"
        checked={value ?? false}
        disabled={disabled}
        onChange={() => onChange && onChange(id, !value)}
      />
      <Label>{value ? 'Yes' : 'No'}</Label>
    </FormGroup>
  );
};

export default Switch;
