import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { put } from 'tsx/libs/genericAction';
import { apiWeeklyPlanner } from 'tsx/constants/api';
import { Worker } from '../lib/common';

export const typePrefix = 'weekly-planner-unsaved';

export interface SimulateParams extends Params {
  type: string;
  data: { id: number; date: string };
  workers: { origin?: Worker; target: Worker };
}

const config = [{ actionType: 'simulate', apiPath: `${apiWeeklyPlanner}/simulate`, method: put }];

export const { simulate } = createThunks(typePrefix, config);
