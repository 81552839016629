import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiUserDepartments } from 'tsx/constants/api';

export const typePrefix = 'users-departments';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiUserDepartments,
    method: get,
    actionParams: { attributes: ['id', 'name', 'company_id'], sort: ['name'], limit: 0 },
  },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiUserDepartments}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
