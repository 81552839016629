import React, { useEffect } from 'react';
import { Col, Collapse, Input, Row } from 'reactstrap';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

function FormInputChargeableCancellation({ value = {}, onChange }: InputProps) {
  const { chargeable_cancellation, chargeable_cancellation_details, duration } = value;
  const { client, client_unit, carer } = chargeable_cancellation_details ?? {};
  const isOpen = chargeable_cancellation === true;
  const chargeableTypes = [
    { id: 'mins', name: 'Mins' },
    { id: 'fixed', name: 'Fixed Amount' },
  ];

  useEffect(() => {
    // Load default cancellation details
    if (isOpen && !chargeable_cancellation_details) {
      onChange &&
        onChange('chargeable_cancellation_details', {
          client: duration,
          client_unit: 'mins',
          carer: duration,
        });
    }
  }, [chargeable_cancellation]);

  const setChange = (target: { name: string; value: any }) => {
    const { name, value } = target;
    const newValue = {
      ...chargeable_cancellation_details,
      [name]: value,
    };

    onChange && onChange('chargeable_cancellation_details', newValue);
  };

  const labelSm = 4;

  return (
    <>
      <FormInput.Switch
        type="switch"
        id="chargeable_cancellation"
        name="chargeable_cancellation"
        value={chargeable_cancellation ?? ''}
        onChange={onChange}
      />
      <Collapse isOpen={isOpen}>
        <Row className="mb-1 align-items-center">
          <Col sm={labelSm}>Charge Client</Col>
          <Col className="d-flex">
            <Input
              type="number"
              name="client"
              id="client"
              value={client ?? ''}
              className="w-25 me-1"
              onChange={({ target }) => setChange(target)}
            />
            <FormInput.Select
              type="select"
              name="client_unit"
              id="client_unit"
              className="w-50"
              value={client_unit ?? ''}
              ignorePlaceholder={true}
              onChange={(name, value) => setChange({ name, value })}
              options={chargeableTypes}
            />
          </Col>
        </Row>
        <Row className="mb-1 align-items-center">
          <Col sm={labelSm}>Pay Care Worker</Col>
          <Col className="d-flex align-items-center">
            <Input
              type="number"
              name="carer"
              id="carer"
              value={carer ?? ''}
              className="w-25 me-1"
              onChange={({ target }) => setChange(target)}
            />
            <span className="ms-2">mins</span>
          </Col>
        </Row>
      </Collapse>
    </>
  );
}

export default FormInputChargeableCancellation;
