import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiAppointmentCancellationReasons } from 'tsx/constants/api';

export const typePrefix = 'appointments-cancellation-reasons';

const config = [
  { actionType: 'getAll', apiPath: apiAppointmentCancellationReasons, method: get, actionParams: { limit: 0 } },
];

export const { getAll } = createThunks(typePrefix, config);
