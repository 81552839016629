import { FieldProps } from 'tsx/components/FormFields';
import LabelDuration from 'tsx/components/LabelDuration';

import { selectCareQualificationLevels } from '../../reducers/careQualificationLevels';
import { selectLocations } from '../../reducers/locations';
import { selectMedications } from 'tsx/features/main/reducers/medications';
import { selectTaskTypes } from 'tsx/features/main/reducers/taskTypes';
import { selectEquipments } from 'tsx/features/main/reducers/equipments';
import { selectLookupAppointmentCancellationReasons } from '../../reducers/cancellationReasons';

import FormInputSignature from '../../components/FormInputSignature';
import FormInputEquipments from '../../components/FormInputEquipments';

import {
  deriveClientAddress,
  deriveDuration,
  readOnlyChargeableCancellation,
  readOnlyDistanceTravelled,
  readOnlyRepeatDates,
  readOnlyRepeatFrequency,
  readOnlyStartEndTime,
  readOnlyTimeActual,
  readOnlyTimeMarked,
  readOnlyTimeTravelled,
} from '../../lib/derive';
import { showTimeMarked, showAppointmentDateOrLess, showLocations, showPaymentAmount } from '../../lib/show';

const commonFields: FieldProps = {
  id: {
    type: 'hidden',
  },
  status: {
    type: 'readonly',
    field: 'status.name',
    className: 'fw-bold text-danger lead',
    order: 100,
  },
  cancellation_reason: {
    type: 'readonly',
    caption: 'Reason',
    selectorOptions: selectLookupAppointmentCancellationReasons,
    order: 110,
  },
  client_name: {
    type: 'readonly',
    field: 'client.full_name',
    caption: 'Client / Facility',
    deriveDescription: deriveClientAddress,
    order: 200,
  },
  location_override_id: {
    type: 'readonly',
    caption: 'Location',
    show: showLocations,
    selectorOptions: selectLocations,
    order: 500,
  },
  service_type_id: {
    type: 'readonly',
    field: 'service_type.name',
    caption: 'Service Type',
    order: 600,
  },
  qualification_level: {
    type: 'multi-readonly',
    caption: 'Qualifications Required',
    selectorOptions: selectCareQualificationLevels,
    order: 900,
  },
  user_id: {
    type: 'readonly',
    field: 'user.full_name',
    caption: 'Care Worker',
    order: 910,
  },
  start_end_time: {
    type: 'readonly',
    caption: 'Start/End Times',
    deriveValue: readOnlyStartEndTime,
    order: 1000,
  },
  duration: {
    type: 'custom',
    caption: 'Duration',
    component: LabelDuration,
    deriveValue: deriveDuration,
    className: 'fw-bold',
    order: 1100,
  },
  time_marked: {
    type: 'multi-readonly',
    caption: 'Time Marked',
    labelCaption: 'Auto marked by the system',
    deriveValue: readOnlyTimeMarked,
    show: showTimeMarked,
    order: 1110,
  },
  distance_travelled: {
    type: 'multi-readonly',
    caption: 'Distance Travelled',
    labelCaption: 'Entered by the care worker',
    deriveValue: readOnlyDistanceTravelled,
    show: showAppointmentDateOrLess,
    order: 1120,
  },
  travel_1: {
    type: 'readonly',
    caption: 'Time Travelled (TO Client)',
    labelCaption: 'Entered by the care worker',
    deriveValue: readOnlyTimeTravelled,
    show: showAppointmentDateOrLess,
    order: 1130,
  },
  vehicle_type: {
    type: 'readonly',
    caption: 'Vehicle Type',
    labelCaption: 'Entered by the care worker',
    show: showAppointmentDateOrLess,
    options: [
      { id: 'user', name: 'My Car (Care Worker)' },
      { id: 'company', name: 'Company' },
    ],
    order: 1140,
  },
  actual_times: {
    type: 'multi-readonly',
    caption: 'Actual Times Entered',
    labelCaption: 'Entered by the care worker',
    deriveValue: readOnlyTimeActual,
    show: showAppointmentDateOrLess,
    order: 1150,
  },
  signature: {
    type: 'custom',
    caption: 'Client Signature',
    component: FormInputSignature,
    order: 1160,
  },
  payment_type: {
    type: 'readonly',
    field: 'payment_type',
    caption: 'Payment Type',
    disabled: true,
    order: 1190,
  },
  payment_amount: {
    type: 'currency',
    field: 'payment_amount',
    caption: 'Payment Collected',
    disabled: true,
    show: showPaymentAmount,
    order: 1200,
  },
  flexibility: {
    type: 'switch',
    caption: 'Flexible with Time',
    disabled: true,
    order: 1400,
  },
  tasks: {
    type: 'multi-readonly',
    field: 'task_ids',
    caption: 'Tasks',
    selectorOptions: selectTaskTypes,
    order: 1500,
  },
  medications: {
    type: 'multi-readonly',
    field: 'medication_ids',
    caption: 'Medications',
    selectorOptions: selectMedications,
    order: 1600,
  },
  equipment_details: {
    type: 'custom',
    caption: 'Equipment Details',
    selectorOptions: selectEquipments,
    component: FormInputEquipments,
    disabled: true,
    order: 1700,
  },
};

const singleSpecific: FieldProps = {
  chargeable: {
    type: 'multi-readonly',
    caption: 'Chargeable Cancellation',
    deriveValue: readOnlyChargeableCancellation,
    order: 105,
  },
  date: {
    type: 'readonly',
    caption: 'Appointment Date',
    order: 120,
  },
};

const repeatSpecific: FieldProps = {
  repeat_dates: {
    type: 'multi-readonly',
    caption: 'Repeat Dates',
    deriveValue: readOnlyRepeatDates,
    order: 120,
  },
  repeat_frequency: {
    type: 'multi-readonly',
    caption: 'Frequency',
    deriveValue: readOnlyRepeatFrequency,
    order: 130,
  },
};

const appointmentCancelledFields: FieldProps = {
  ...commonFields,
  ...singleSpecific,
};

const repeatCancelledFields: FieldProps = {
  ...commonFields,
  ...repeatSpecific,
};

export { appointmentCancelledFields, repeatCancelledFields };
