import { useEffect, useState } from 'react';

export const get = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('localstorage exception (get):', error);
    return null;
  }
};

export const set = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('localstorage exception (set):', error);
  }
};

export const remove = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('localstorage exception (remove):', error);
  }
};

export const useStorage = (key: string, initialValue: any) => {
  const [value, setValue] = useState(() => get(key) || initialValue);

  useEffect(() => set(key, value), [key, value]);

  return [value, setValue];
};
