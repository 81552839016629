import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { throttle } from 'lodash';

import { dateInputFieldFormat, timeValueFormat } from 'tsx/libs/dayjs';
import { buildDailyChange, getPredictedTime } from '../../lib/simulate';

import Spinner from 'tsx/components/Spinner';
import ContainerPanel from 'tsx/components/ContainerPanel';
import DailyRow from './DailyRow';
import FocusBar from './FocusBar';

import { simulate } from '../../actions/unsaved';

import {
  selectFocusedAppointment,
  selectLoading,
  selectParams,
  updateWeeklyPlannerParams,
} from '../../reducers/weeklyPlanner';
import { selectSimulatedChanges, selectFilteredSimulatedAll } from '../../selectors/simulate';

interface ComponentProps {
  date: Dayjs;
  onDateChange?: (value: Dayjs) => void;
}

export const MAX_PIPS = 288;
export const PIP_INTERVALS = 5; // Each pip represents x number of minutes

const DailyView: React.FC<ComponentProps> = ({ date, onDateChange }) => {
  const dispatch = useAppDispatch();
  const { limit } = useAppSelector(selectParams);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);
  const weeklyPlannerDataLoading = useAppSelector(selectLoading);
  const weeklyPlannerDataFiltered = useAppSelector(selectFilteredSimulatedAll);

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId } = focusedAppointment || {};

  const isLoading = weeklyPlannerDataLoading === 'pending';

  const [timeMarkerPosition, setTimeMarkerPosition] = useState<number>(calculateTimeMarkerPosition());
  const [markerHeight, setMarkerHeight] = useState<number>(0);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const startDate = date.weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day'));

  const onScroll = useCallback(
    throttle(async () => {
      if (!scrollableContainerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = scrollableContainerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= weeklyPlannerData.length) {
        dispatch(updateWeeklyPlannerParams({ limit: limit + 30 }));
      }

      // Update time marker height while scrolling
      if (contentWrapperRef.current) {
        setMarkerHeight(contentWrapperRef.current.offsetHeight);
      }
    }, 200),
    [limit, weeklyPlannerData.length, dispatch, isLoading],
  );

  useEffect(() => {
    setTimeMarkerPosition(calculateTimeMarkerPosition());
  }, [date, limit]);

  useEffect(() => {
    // Update time marker height when content loads
    if (contentWrapperRef.current) {
      setMarkerHeight(contentWrapperRef.current.offsetHeight);
    }
  }, [weeklyPlannerData, weeklyPlannerDataFiltered]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeMarkerPosition(calculateTimeMarkerPosition());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const setView = (value: Dayjs) => {
    onDateChange && onDateChange(value);
  };

  const onDragEnd = (event: any) => {
    const { active, over, delta } = event;
    if (!over) return;

    const { containerId: oldRow, appointment } = active.data.current;
    const {
      id: newRow,
      rect: { width },
    } = over;

    // Time change event, changing start/end time
    let data = appointment;
    if (oldRow === newRow) {
      let { start_time, end_time, duration } = appointment;
      const { date } = appointment;
      const { x } = delta;
      const timeFormat = 'HH:mm';

      //Calculate duration if doesn't exist
      if (!duration) {
        duration = dayjs(`${end_time}`, timeFormat).diff(dayjs(`${start_time}`, timeFormat), 'minute');
      }

      const predictedConverter = (selTime: string) => {
        return getPredictedTime(width, x, MAX_PIPS, selTime, PIP_INTERVALS, date);
      };

      //If moved but placed in same position - Do not simulate.
      if (
        predictedConverter(start_time).format(timeValueFormat) === start_time &&
        predictedConverter(end_time).format(timeValueFormat) === end_time
      ) {
        return;
      }

      //Check time & direction of move on X axis to determine where to snap.
      if (predictedConverter(start_time).isBefore(date, 'day')) {
        start_time = '00:00';
        end_time = dayjs(start_time, timeFormat).add(duration, 'minute').format(timeFormat);
      } else if (predictedConverter(end_time).isAfter(date, 'day')) {
        end_time = '23:55';
        start_time = dayjs(end_time, timeFormat).subtract(duration, 'minute').format(timeFormat);
      } else {
        start_time = predictedConverter(start_time).format(timeValueFormat);
        end_time = predictedConverter(end_time).format(timeValueFormat);
      }

      data = {
        ...data,
        start_time,
        end_time,
      };
    }

    const change = buildDailyChange(weeklyPlannerData, oldRow, newRow, data);
    dispatch(simulate({ ...change, week_start: startDate }));
  };

  function calculateTimeMarkerPosition(): number {
    const now = dayjs();
    const minutesSinceMidnight = now.hour() * 60 + now.minute();
    const percentageOfDay = minutesSinceMidnight / 1440;
    const container = document.querySelector('.hour-marker-container') as HTMLElement;

    const containerWidth = container?.offsetWidth || 0;
    const containerOffsetLeft = container?.offsetLeft || 0;

    return containerOffsetLeft + percentageOfDay * containerWidth;
  }

  const headerColumnData = daysOfWeek.map((day) => (
    <div key={day.toString()} className={`header-cell ${day.isSame(date, 'day') ? 'selectedDate' : ''}`}>
      <span className="clickable" onClick={() => setView(day)}>
        {day.format('ddd (DD/MMM)')}
      </span>
    </div>
  ));

  const containerClassNames = classNames('daily-view-container', {
    loading: isLoading,
  });

  const isCollapsed = isNaN(Number(focusId));

  return (
    <ContainerPanel
      className="daily-view-wrapper"
      isCollapsed={isCollapsed}
      canCollapse={false}
      canResize={false}
      sidepanel={<FocusBar />}
    >
      <div ref={scrollableContainerRef} className={containerClassNames}>
        <div className="sticky-container">
          {/* header */}
          <div className="header-wrapper">
            <div className="header-cell title">View:</div>
            {headerColumnData}
          </div>

          {/*Timeline*/}
          <div className="timeline-wrapper">
            <div className="timeline-header">Timeline</div>
            <div className="timeline-content">
              <div className="hour-marker-container">
                {[...Array(24)].map((_, index) => (
                  <div key={index} className="hour-marker">
                    {`${index}:00`}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {date.isSame(dayjs(), 'day') && (
            <div
              className="current-time-marker"
              style={{ left: `${timeMarkerPosition}px`, height: `${markerHeight}px` }}
            ></div>
          )}
        </div>

        {/* Daily Planner Content */}
        <div className="content-wrapper" ref={contentWrapperRef}>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            {weeklyPlannerDataFiltered?.map((worker, index) => (
              <DailyRow
                key={`${worker.id}-${index}`}
                worker={worker}
                date={date}
                events={worker.events[date.format(dateInputFieldFormat)]}
                style={{
                  gridRow: index + 1,
                }}
              />
            ))}
          </DndContext>
        </div>
        <Spinner loading={isLoading} className="p-5" />
      </div>
    </ContainerPanel>
  );
};

export default DailyView;
