import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import dayjs from 'dayjs';

import { weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';
import Button from 'tsx/components/Button';
import { Icon, icons } from 'tsx/components/Icon';
import ModalCard from 'tsx/components/ModalCard';

import { getAwardAlerts } from '../../actions/weeklyPlannerAppointments';
import { selectAwardAlerts } from '../../reducers/weeklyPlannerAppointments';
import { focusWorker } from '../../reducers/weeklyPlanner';
import AwardAlertViewMenu from './AwardAlertViewMenu';

interface ComponentProps {
  weekStart: string;
  modalProps: any;
}

const AwardAlerts: React.FC<ComponentProps> = ({ weekStart, modalProps }) => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(selectAwardAlerts);

  const loadData = () => {
    dispatch(getAwardAlerts({ week_start: weekStart }));
  };

  useEffect(() => {
    loadData();
  }, [weekStart]);

  const renderResults = () => {
    return alerts.map(({ date, message, user }, index) => {
      const displayDate = date ? dayjs(date).format(weeklyPlannerDayDisplayFormat) : '-';

      return (
        <Row key={index} className="underline">
          <Col sm={3}>{displayDate}</Col>
          <Col>{user.full_name}</Col>
          <Col>{message}</Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
              <Icon icon={icons.faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const count = alerts.length;
  const header = `Award Alerts (${count})`;

  return (
    <ModalCard {...modalProps} header={header}>
      <div className="nav card-header">
        <AwardAlertViewMenu />
      </div>
      <Container className="award-alerts-container">
        {count ? (
          <>
            <Row className="fw-bold row underline flex-nowrap">
              <Col sm={3}>Day</Col>
              <Col>Worker</Col>
              <Col>Alert</Col>
              <Col sm={1} />
            </Row>
            {renderResults()}
          </>
        ) : (
          <Row>No alerts found</Row>
        )}
      </Container>
    </ModalCard>
  );
};

export default AwardAlerts;
