import React, { useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Collapse, Row } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';
import { datePickerDisplayFormat, getDate } from 'tsx/libs/dayjs';
import { Appointment } from '../../lib/common';
import { DatePicker } from '@mui/x-date-pickers';
import Select, { Option } from 'tsx/components/Select';

interface ComponentProps {
  isOpen: boolean;
  appointments: Appointment[];
  weekStart: string;
  setFilters: any;
}

interface Filters {
  client_id: number | null;
  user_id: number | null;
  suburb: string | null;
  date: string | null;
}

const Search: React.FC<ComponentProps> = ({ isOpen, appointments, weekStart, setFilters }) => {
  const [formValues, setFormValues] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });

  let clientOptions: Option[] = [];
  let workerOptions: Option[] = [{ value: -1, label: 'No Preference' }];
  let suburbOptions: Option[] = [];

  const weekStartFormat = dayjs(weekStart);
  const weekEndFormat = dayjs(weekStart).add(6, 'day');

  //Remove Duplicates from selected object array
  const getDistinctOptions = (options: Option[]) =>
    [...new Set(options.map((option) => option.value))].map((value) =>
      options.find((option) => option.value === value),
    ) as Option[];

  const sortOptions = (options: Option[]) => options.sort((a, b) => a?.label?.localeCompare(b?.label ?? '') ?? 0);

  // Map options by week's results.
  appointments.map((appointment) => {
    clientOptions.push({ value: appointment.client.id, label: appointment.client.full_name });
    appointment.user ? workerOptions.push({ value: appointment.user.id, label: appointment.user.full_name }) : [];
    suburbOptions.push({
      value: appointment.client.suburb,
      label: `${appointment.client.suburb} ${appointment.client.postcode}`,
    });
  });

  clientOptions = getDistinctOptions(sortOptions(clientOptions));
  workerOptions = getDistinctOptions(sortOptions(workerOptions));
  suburbOptions = getDistinctOptions(sortOptions(suburbOptions));

  const onSearch = () => {
    setFilters(formValues);
  };

  // Handle non-date form changes.
  const handleChange = (name: string, value: string | number | null) => {
    if (!value) {
      setFormValues({ ...formValues, [name]: null });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // Handle Date form change.
  const handleDateChange = (value: Dayjs | null) => {
    if (value === null) {
      setFormValues({ ...formValues, date: null });
    } else {
      setFormValues({ ...formValues, date: getDate(value) });
    }
  };

  return (
    <Collapse isOpen={isOpen}>
      <Card className="m-1">
        <CardBody>
          <CardTitle tag="h6" className="fw-bold">
            Search Unallocated Appointments
          </CardTitle>

          <div className="mb-2">
            <Select
              label="Client:"
              isClearable
              id={`client_id`}
              name={`client_id`}
              options={clientOptions}
              classNamePrefix={'form-input-select'}
              placeholder={'Client Name'}
              onChange={handleChange}
            />
            <Select
              label="Repeat Worker:"
              isClearable
              id={`user_id`}
              name={`user_id`}
              options={workerOptions}
              classNamePrefix={'form-input-select'}
              placeholder={'Repeat Worker'}
              onChange={handleChange}
            />
            <Select
              label="Suburb:"
              isClearable
              id={`suburb`}
              name={`suburb`}
              options={suburbOptions}
              classNamePrefix={'form-input-select'}
              placeholder={'Suburb'}
              onChange={handleChange}
            />

            <p className="my-1">Date:</p>
            <DatePicker
              showDaysOutsideCurrentMonth
              format={datePickerDisplayFormat}
              minDate={weekStartFormat}
              maxDate={weekEndFormat}
              name="search-date"
              defaultValue={weekStartFormat}
              value={dayjs(formValues.date)}
              onChange={handleDateChange}
              slotProps={{ field: { clearable: true }, textField: { size: 'small' } }}
            />
          </div>
          <Row className="d-flex-inline">
            <Col>
              <Button size="sm" onClick={onSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};

export default Search;
