import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';
import { Dayjs } from 'dayjs';

import Button from 'tsx/components/Button';
import ContainerHeader from 'tsx/components/ContainerHeader';
import WeekPicker from 'tsx/components/WeekPicker';
import { Icon, icons } from 'tsx/components/Icon';

import ChangesDialog from './ChangesDialog';
import ServiceColor from './ServiceColor';
import { SearchBar } from 'tsx/components/FormFields/Search';
import searchFields from '../forms/search';

import { useSearch } from 'tsx/libs/searchParams';

import { getAll as getDepartments } from 'tsx/features/care-workers/actions/userDepartments';

import { selectOptions, toggleOption } from '../reducers/weeklyPlanner';
import { getAll as getRegions } from 'tsx/features/main/actions/regions';

interface ComponentProps {
  isDayView: boolean;
  onDateChange?: (value: Dayjs) => void;
  onViewChange?: (toggle: boolean) => void;
  onRefresh?: (searchParams?: { repeat: boolean; sort: string[]; limit: number }) => void;
}

const Banner: React.FC<ComponentProps> = ({ isDayView, onDateChange, onViewChange, onRefresh }) => {
  const dispatch = useAppDispatch();
  const {
    appointmentFlexibility,
    workerAvailability,
    workerQualifications,
    workerAwardAlerts,
    totalSimple,
    totalAppointments,
    totalPredicted,
    totalDistance,
    showTravel,
    showBreaks,
  } = useAppSelector(selectOptions);

  const initialSearchState: any = {};

  const [isChangesOpen, setIsChangesOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isColorEditOpen, setColorEditOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearch('search', initialSearchState);

  const search = (values: { [key: string]: any }) => setSearchParams(values);
  const setDateChange = (value: Dayjs) => onDateChange && onDateChange(value);
  const setViewChange = () => onViewChange && onViewChange(!isDayView);

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any) => (
    <DropdownItem toggle={false} onClick={onClick} className="d-flex align-items-center">
      <Label className="me-2 mb-0">{label}</Label>
      <Icon icon={iconToggle ? icons.faEye : icons.faEyeSlash} className="mt-1 ms-auto" />
    </DropdownItem>
  );

  useEffect(() => {
    if (searchParams !== initialSearchState) {
      onRefresh?.(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDepartments());
  }, []);

  return (
    <>
      <ContainerHeader className="weekly-planner">
        <span>Weekly Planner</span>
      </ContainerHeader>
      <ChangesDialog
        isOpen={isChangesOpen}
        onClose={() => {
          setIsChangesOpen(false);
          onRefresh && onRefresh();
        }}
      />
      <div className="nav d-flex justify-content-end bg-light p-2 mt-2 mb-2 banner">
        <div className="d-flex">
          <ServiceColor isOpen={isColorEditOpen} onClose={() => setColorEditOpen(!isColorEditOpen)} />
          <Button size="sm" color="success" onClick={() => setViewChange()}>
            {isDayView ? 'Show Weekly' : 'Show Daily'}
          </Button>
          <WeekPicker onChange={setDateChange} />
          <Button size="sm" className="me-2" onClick={() => setIsSearchBarOpen(!isSearchBarOpen)}>
            <Icon className="me-2" icon={icons.faMagnifyingGlass} />
            {isSearchBarOpen ? 'Hide' : 'Search'}
          </Button>
          <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down" className="mx-2">
            <DropdownToggle size="sm" className={`settings-icon ${isViewOpen ? 'active' : ''}`}>
              <Icon icon={icons.faGear} className="align-self-center" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Planner Options</DropdownItem>
              <DropdownItem onClick={() => setColorEditOpen(true)}>Edit Service Colour</DropdownItem>
              <DropdownItem divider />
              <DropdownItem header className="fw-bold">
                View
              </DropdownItem>
              <DropdownItem header>Worker Details</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerQualifications', toggle: !workerQualifications })),
                workerQualifications,
                'Qualifications',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerAvailability', toggle: !workerAvailability })),
                workerAvailability,
                'Availability',
              )}
              <DropdownItem header>Planner</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'appointmentFlexibility', toggle: !appointmentFlexibility })),
                appointmentFlexibility,
                'Flexibility',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'showBreaks', toggle: !showBreaks })),
                showBreaks,
                'Breaks',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'showTravel', toggle: !showTravel })),
                showTravel,
                'Travel',
              )}

              <DropdownItem header>Totals</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerAwardAlerts', toggle: !workerAwardAlerts })),
                workerAwardAlerts,
                'Award Alerts',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalSimple', toggle: !totalSimple })),
                totalSimple,
                'Simple',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalAppointments', toggle: !totalAppointments })),
                totalAppointments,
                'Appointments',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalPredicted', toggle: !totalPredicted })),
                totalPredicted,
                'Predicted Shift',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalDistance', toggle: !totalDistance })),
                totalDistance,
                'Distance',
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <Collapse isOpen={isSearchBarOpen}>
        <SearchBar fields={searchFields} initialValues={searchParams} storageKey="weekly-planner" onSubmit={search} />
      </Collapse>
    </>
  );
};

export default Banner;
