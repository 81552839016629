import React, { useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Icon } from 'tsx/components/Icon';
import { useAppDispatch, useAppSelector } from 'hooks';

import { DateInput } from 'tsx/components/FormFields/Inputs';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { getPublishEstimates } from '../actions/weeklyPlanner';
import { publish } from '../actions/weeklyPlanner';
import { Params } from 'tsx/libs/reduxUtils';
import { bulkUpdate } from 'tsx/features/appointments/actions/appointments';

import { selectPublishEstimates } from '../reducers/weeklyPlanner';
import { selectAllChanges, selectAllChangesCount } from '../reducers/unsaved';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  originalDate: string;
};

const PublishDialog: React.FC<Props> = ({ isOpen = false, onClose, originalDate }: Props) => {
  const dispatch = useAppDispatch();
  const { users, appointments } = useAppSelector(selectPublishEstimates);
  const changes = useAppSelector(selectAllChanges);
  const changesCount = useAppSelector(selectAllChangesCount);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newDate, setNewDate] = useState<string>('');
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const dateUpdate = (id: string, value: string) => {
    setNewDate(value);
    updateEstimates(value);
  };

  const updateEstimates = async (date: string) => {
    try {
      setIsLoading(true);
      await dispatch(getPublishEstimates({ published_date: date }));
    } finally {
      setIsLoading(false);
    }
  };

  const onPublish = async () => {
    // Set dialog as publishing
    setIsPublishing(true);

    // Save any unsaved changes before publishing
    if (changesCount > 0) {
      const data: Params[] = Object.entries(changes).map(([, change]: [string, any]) => change);
      const { payload } = await dispatch(bulkUpdate(data));
      if (!payload?.success) {
        console.error('error, should be returning a response message in reducer');
        return;
      }
    }

    // Publish the date
    const { payload } = await dispatch(publish({ published_date: newDate }));
    if (payload?.success) {
      setIsPublishing(false);
      window.location.reload();
    }
  };

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader className="bg-success text-white">Publish New Date</ModalHeader>
      <ModalBody>
        <div className="mb-2 pb-2 border-2 border-bottom border-success">
          Please confirm the date & following changes to publish:
        </div>

        <div className="p-2">
          <span className="mb-2 font-weight-bold">Publish Date</span>
          <div className="d-flex flex-row justify-content-center">
            <Input type="date" name="oldPublishDate" value={originalDate} disabled className="w-50" />
            <div className="align-bottom">
              <Icon icon={faChevronRight} className="align-bottom ms-2 me-2" />
            </div>
            <DateInput
              type="date"
              id="newPublishDate"
              name="newPublishDate"
              value={newDate}
              onChange={dateUpdate}
              disabled={isLoading}
              className="w-50"
            />
          </div>
        </div>

        <div className="mt-4 mx-2">
          <Row>
            <Col sm="6">Unsaved Changes:</Col>
            <Col>{changesCount}</Col>
          </Row>
          <Row>
            <Col sm="6">Affected Care Workers:</Col>
            <Col>
              {isLoading && <Spinner type="border" size="sm" color="info" />}
              {!isLoading && <span>{users ?? '-'}</span>}
            </Col>
          </Row>
          <Row>
            <Col sm="6">Affected Appointments:</Col>
            <Col>
              {isLoading && <Spinner type="border" size="sm" color="info" />}
              {!isLoading && <span>{appointments ?? '-'}</span>}
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn ms-2 px-4"
          color="success"
          disabled={isLoading || isPublishing || newDate === ''}
          onClick={onPublish}
        >
          {isLoading || isPublishing ? <Spinner type="grow" size="sm" /> : <span>Publish</span>}
        </Button>
        <Button className="btn ms-2 px-4" onClick={onClose} disabled={isPublishing}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PublishDialog;
