import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiClientReferrers } from 'tsx/constants/api';

export const typePrefix = 'clients-referrers';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiClientReferrers,
    method: get,
    actionParams: { limit: 0 },
  },
  {
    actionType: 'getOne',
    apiPath: (params: Params) => `${apiClientReferrers}/${params.id}`, // TODO: Test getOne endpoint
    method: get,
  },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
