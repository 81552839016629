import React, { CSSProperties } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { dateInputFieldFormat } from 'tsx/libs/dayjs';
import Spinner from 'tsx/components/Spinner';

import WorkerCell from './WorkerCell';
import DayCell from './DayCell';
import { Worker, WorkerAvailability } from '../lib/common';
import { selectRowLoading } from '../reducers/unsaved';
import { selectFocusedWorkerId } from '../reducers/weeklyPlanner';

interface ComponentProps {
  worker: Worker;
  days: Dayjs[];
  date: Dayjs;
  style?: CSSProperties;
}

const WorkerRow: React.FC<ComponentProps> = ({ worker, days, date, style }) => {
  const loading = useAppSelector((state) => selectRowLoading(state, parseInt(worker.id)));
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);
  const getDayAvailability = (day: Dayjs) => {
    if (!day || !worker?.availability) return null;
    const dayName = day.format('dddd');
    return worker?.availability[dayName as keyof WorkerAvailability] ?? {};
  };

  const className = classNames('worker-row', {
    focus: focusedWorkerId && parseInt(worker.id) === focusedWorkerId,
  });
  const isLoading = loading === 'pending';

  return (
    <div className={className}>
      {isLoading && <Spinner loading={isLoading} style={style} />}
      <WorkerCell worker={worker} date={date} style={{ ...style, gridColumn: 1 }} />
      {days.map((day, index) => (
        <DayCell
          style={{ ...style, gridColumn: index + 2 }}
          key={`${worker.id}-${index}`}
          id={`${worker.id}-${index}`}
          events={worker.events[day.format(dateInputFieldFormat)]}
          availability={getDayAvailability(day)}
        />
      ))}
    </div>
  );
};

export default WorkerRow;
