import { ActionReducerMapBuilder, createAsyncThunk, isPending, isRejected } from '@reduxjs/toolkit';
import { LoadingState } from 'tsx/types/reducers';

/**
 * Actions
 */

export interface Params {
  id?: string;
  [key: string]: string | number | boolean | object | null | undefined;
}

export interface ThunkConfig {
  actionType: string;
  apiPath: string | ((params: Params) => string);
  method: (url: string, params?: any) => Promise<any>;
  actionParams?: Params;
}

const generateThunk = (
  type: string,
  apiEndpoint: string | ((params: Params) => string),
  method: (url: string, params?: any) => Promise<any>,
  actionParams?: Params,
) => {
  return createAsyncThunk(type, async (params?: Params) => {
    let finalParams;
    // If an array of parameters, don't mess with it.
    // Don't add actionParams to an array of values
    if (Array.isArray(params)) finalParams = params;
    else {
      const safeParams = params ?? {};
      finalParams = { ...actionParams, ...safeParams };
    }

    const endpoint = typeof apiEndpoint === 'function' ? apiEndpoint(finalParams) : apiEndpoint;
    return method(endpoint, finalParams);
  });
};

export const createThunk = generateThunk;

export const createThunks = (prefix: string, thunks: Array<ThunkConfig>) => {
  return thunks.reduce(
    (acc, { actionType, apiPath, method, actionParams }) => {
      acc[actionType] = generateThunk(`${prefix}/${actionType}`, apiPath, method, actionParams);
      return acc;
    },
    {} as Record<string, any>,
  );
};

/**
 * Reducers
 */

export interface CommonState<T> {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<T>;
  row?: T;
}

export const setLoading = <T>(
  state: CommonState<T>,
  actionType: string,
  loadingState: LoadingState,
  typePrefix: string,
) => {
  if (actionType.startsWith(`${typePrefix}/`)) {
    state.loading = loadingState;
  }
};

export const addCases = <T>(builder: ActionReducerMapBuilder<CommonState<T>>, getAll?: any, getOne?: any) => {
  if (getAll) {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
  }
  if (getOne) {
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
  }
};

export const addMatchers = <T>(builder: ActionReducerMapBuilder<CommonState<T>>, typePrefix: string) => {
  builder
    .addMatcher(isPending, (state, action) => setLoading(state, action.type, 'pending', typePrefix))
    .addMatcher(isRejected, (state, action) => {
      setLoading(state, action.type, 'declined', typePrefix);
      state.error = action.error.message;
    });
};

export const addCommonReducers = <T>(
  builder: ActionReducerMapBuilder<CommonState<T>>,
  typePrefix: string,
  getAll?: any,
  getOne?: any,
) => {
  addCases(builder, getAll, getOne);
  addMatchers(builder, typePrefix);
};
