import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiAppointmentRepeatFrequencies } from 'tsx/constants/api';

export const typePrefix = 'appointments-repeat-frequencies';

const config = [
  { actionType: 'getAll', apiPath: apiAppointmentRepeatFrequencies, method: get, actionParams: { limit: 0 } },
];

export const { getAll } = createThunks(typePrefix, config);
