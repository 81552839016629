import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Col, Row, Tooltip } from 'reactstrap';

import { Icon, icons } from 'tsx/components/Icon';

import { Worker, Event, getEventTotalsRange } from '../lib/common';
import { formatDuration } from 'tsx/libs/dayjs';

import { selectAwardAlerts } from '../reducers/weeklyPlannerAppointments';

interface ComponentProps {
  worker: Worker;
  events: Event[];
}

const WorkerAlerts: React.FC<ComponentProps> = ({ worker, events = [] }) => {
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  let hasAlert = false;

  const userAlerts = useAppSelector(selectAwardAlerts).filter((award) => award.user.id === worker.id);
  const toggle = () => setTooltipOpen(!isTooltipOpen);

  const alert = userAlerts
    .filter(({ tag }) => tag === 'min-hours' || tag === 'max-hours')
    .map(({ tag, user }) => {
      hasAlert = true;
      const duration = getEventTotalsRange(events, 'predicted');
      return {
        caption: tag === 'min-hours' ? `Min: ${user.hours_min}h` : `Max: ${user.hours_max}h`,
        tooltip: `Current Hours: ${formatDuration(Number(duration))}`,
      };
    });

  const renderAlerts = () => {
    return alert.map(({ caption, tooltip }, index) => {
      return (
        <Row key={index} id={`alert-${worker.id}-${index}`}>
          <Col>{caption}</Col>
          <Col sm={2} className="mx-1 text-center">
            <Icon className="alert-icon" icon={icons.faTriangleExclamation} />
          </Col>
          <Tooltip isOpen={isTooltipOpen} target={`alert-${worker.id}-${index}`} toggle={toggle} placement="right">
            {tooltip}
          </Tooltip>
        </Row>
      );
    });
  };

  return hasAlert ? <Row className="award-alert">{renderAlerts()}</Row> : <></>;
};

export default WorkerAlerts;
