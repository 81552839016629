import { ColumnDef } from '@tanstack/react-table';
import { useAppSelector, useAppDispatch } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Icon, icons } from 'tsx/components/Icon';
import Select from 'tsx/components/Select';
import TableView from 'tsx/components/TableView';
import { getColours, upsertColour } from 'tsx/features/appointments/actions/serviceTypes';
import {
  selectAppointmentServiceTypeColours,
  selectAppointmentServiceTypes,
} from 'tsx/features/appointments/reducers/serviceTypes';

interface ComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SelectService {
  value: number;
  label: string;
  colour?: string;
}

const ServiceColor: React.FC<ComponentProps> = ({ isOpen = false, onClose }) => {
  const [selectedColour, setSelectedColour] = useState<string>('');
  const [selectedService, setSelectedService] = useState<SelectService>();
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const serviceTypes = useAppSelector(selectAppointmentServiceTypes);
  const currentColours = useAppSelector(selectAppointmentServiceTypeColours);

  const columns: ColumnDef<any>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      cell: (info) => `${info.getValue()}`,
      enableHiding: true,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Service Name',
      cell: ({ row: { original } }) => {
        const { id, name, colour } = original;
        return (
          <span
            className="service-title"
            onClick={() => {
              setSelectedService({
                value: id,
                label: name,
                colour,
              });
              setSelectedColour(`#${colour}`);
            }}
          >
            {name} <Icon className="edit-icon ps-1" icon={icons.faEdit} />
          </span>
        );
      },
    },
    {
      id: 'colour',
      accessorKey: 'colour',
      header: 'Colour',
      cell: ({ row }) => {
        const color = `#${row.original.colour}`;
        return (
          <span>
            <Icon icon={icons.faCircle} className="me-1" style={{ color }} />
            <span>{color}</span>
          </span>
        );
      },
    },
  ];

  const serviceOptions: SelectService[] = useMemo(() => {
    return serviceTypes.map(({ id, name, colour }) => ({
      value: id,
      label: name,
      colour,
    }));
  }, [serviceTypes]);

  const handleChange = useCallback(
    (value: number) => {
      setHasUpdated(false);
      const option = serviceOptions.find((option) => option.value === value);
      if (option && selectedService?.value !== option.value) {
        setSelectedService(option);
        setSelectedColour(option.colour ? `#${option.colour}` : '');
      }
    },
    [serviceOptions, selectedService],
  );

  const handleColourChange = useCallback((colour: string) => {
    setSelectedColour(colour);
    setHasUpdated(false);
  }, []);

  const onSubmit = useCallback(async () => {
    if (selectedService) {
      const parsedColour = selectedColour.startsWith('#') ? selectedColour.slice(1) : selectedColour;
      const { payload } = await dispatch(upsertColour({ id: selectedService.value, colour: parsedColour }));
      setSelectedService((prev) => prev && { ...prev, colour: parsedColour });
      setHasUpdated(payload?.success ?? false);
    }
  }, [dispatch, selectedService, selectedColour]);

  useEffect(() => {
    dispatch(getColours());
  }, [dispatch, hasUpdated]);

  return (
    <Modal size="xl" isOpen={isOpen} className="colour-dialog">
      <ModalHeader className="bg-success text-white btn-close-white" data-bs-theme="dark" toggle={onClose}>
        Edit Service Type Colour
      </ModalHeader>
      <ModalBody className="colour-body">
        <Row>
          <Col sm={'8'} className="me-4 h-100">
            <div className="colour-table">
              <TableView id="colours" data={currentColours as []} columns={columns} />
            </div>
          </Col>
          <Col>
            <div>
              Select Service:
              <Select
                id={`select-service`}
                name={`select-service`}
                options={serviceOptions}
                onChange={(_name, value) => {
                  handleChange(value);
                }}
                value={selectedService?.value}
              />
            </div>
            <div className="colour-panel d-flex-column">
              <p>
                <span>Current:</span>
                <span>
                  {selectedService?.colour ? (
                    <>
                      <Icon icon={icons.faCircle} className="mx-1" style={{ color: `#${selectedService.colour}` }} />
                      <span>#{selectedService.colour}</span>
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              </p>
              <p>New: {selectedColour}</p>
              <div className="d-flex flex-column justify-content-center">
                <HexColorPicker color={selectedColour} onChange={handleColourChange} className="align-self-center" />
                <div className="align-self-center mt-3">
                  <Button onClick={onSubmit} disabled={!selectedService}>
                    {selectedService?.colour ? 'Update' : 'Add'}
                  </Button>
                  {hasUpdated === true && <Icon className="check-icon" icon={icons.faCheck} />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ServiceColor;
