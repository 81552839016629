import React from 'react';
import { Input, Label } from 'reactstrap';
import { useAppSelector } from 'hooks';

import { InputProps } from 'tsx/components/FormFields';
import { Row } from 'tsx/types/reducers';

type FieldOptions = Array<{ id: string | number; name: string }> | Row[];

const Radio: React.FC<InputProps> = ({
  id,
  value,
  disabled = false,
  options,
  selectorOptions,
  onChange,
  setFieldValue,
}) => {
  let fieldOptions: FieldOptions = [];
  if (selectorOptions) fieldOptions = useAppSelector(selectorOptions);
  else if (options) fieldOptions = options;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFieldValue(id, value);
    onChange && onChange(id, value);
  };

  const radioOptions = fieldOptions.map(({ id, name }, index) => (
    <Label key={index} check className="me-3">
      <Input
        type="radio"
        name={name}
        value={id}
        checked={value === id.toString()}
        onChange={handleChange}
        disabled={disabled}
        className="me-1"
      />
      {name}
    </Label>
  ));

  return <div className="d-flex flex-wrap align-items-center">{radioOptions}</div>;
};

export default Radio;
