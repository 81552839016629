import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';

import Header from 'tsx/features/main/Header';
import QuickMenu from 'tsx/features/main/QuickMenu';

import { getSettings } from './actions/login';
import { useAllSearchParams } from 'tsx/libs/searchParams';
import { selectViewType, setViewType } from './reducers/app';

function Home() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const searchParams = useAllSearchParams();

  const viewType = useAppSelector(selectViewType);

  useEffect(() => {
    dispatch(getSettings());

    // Add view toggle to remove header/quick menu/navigation items, used for iFrame windows within TP1
    // This must be removed upon close of TP1. (╯°□°）╯︵ ┻━┻
    const { view_type } = searchParams;
    dispatch(setViewType(view_type));
  }, []);

  // TODO - Temporary redirect until navigation menu, homepage and dashboard established
  if (pathname === '/') return <Navigate to="/appointments" replace />;

  const containerClass = classNames('app-container', {
    ['prevent-nav']: viewType === 'feature',
  });

  return (
    <Container fluid className={containerClass}>
      <Header />
      <Row className="app-row gx-0">
        <QuickMenu />
        <Col className="features">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
