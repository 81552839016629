import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import { apiMedications } from 'tsx/constants/api';

export const typePrefix = 'medications';

const config = [
  { actionType: 'getAll', apiPath: apiMedications, method: get, actionParams: { limit: 0 } },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiMedications}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
