import React from 'react';
import { Input, Label } from 'reactstrap';
import { useAppSelector } from 'hooks';

import { Row } from 'tsx/types/reducers';
import { InputProps } from 'tsx/components/FormFields';

type FieldOptions = Array<{ id: string | number; name: string }> | Row[];

interface ComponentProps extends InputProps {
  highlightOptions?: Array<number>;
  size?: 'sm' | 'lg';
}

const MultiCheckbox: React.FC<ComponentProps> = ({
  id,
  value = [],
  disabled,
  colSize,
  options,
  selectorOptions,
  highlightOptions = [],
  onChange,
  size = 'lg',
}) => {
  // Update array to include or remove changed checkbox, return array of checked values.
  const setChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const numeric = parseInt(name);

    let newValue: number[] = [];
    if (value instanceof Array) newValue = [...value];

    const index = newValue.indexOf(numeric);
    if (index > -1) newValue.splice(index, 1);
    else newValue.push(numeric);

    onChange && onChange(id, newValue);
  };

  // Prioritise selectorOptions over options when building
  let fieldOptions: FieldOptions = [];
  if (selectorOptions) fieldOptions = useAppSelector(selectorOptions);
  else if (options) fieldOptions = options;

  const sizeMultiplier = size === 'sm' ? 2 : 1;

  // Determine number of checkboxes based on colSize
  let groupColSize: number;
  switch (colSize) {
    case 1:
    case 2:
    case 3: {
      groupColSize = 12;
      break;
    }
    case 4:
    case 5:
    case 6: {
      groupColSize = 6 * sizeMultiplier;
      break;
    }
    default: {
      groupColSize = 3 * sizeMultiplier;
      break;
    }
  }

  const values = value instanceof Array ? value.map((optionId: string | number) => optionId.toString()) : [];

  // Iterate and build fields based on options
  const fields = fieldOptions.map(({ id, name }, index) => {
    const reference = id.toString();
    const highlight = highlightOptions.includes(parseInt(reference)) ? ' bg-info rounded' : '';
    return (
      <div key={index} className={`col-${groupColSize}`}>
        <div key={index} className={`m-1 p-1 d-flex${highlight}`}>
          <Input
            type="checkbox"
            name={reference}
            id={reference}
            checked={values.includes(reference) ?? false}
            onChange={setChange}
            disabled={disabled}
          />
          <Label for={reference} className="ms-2 mb-0">
            {name}
          </Label>
        </div>
      </div>
    );
  });

  return <div className="d-flex flex-wrap">{fields}</div>;
};

export default MultiCheckbox;
