import React from 'react';
import { useAppSelector } from 'hooks';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { selectAppointmentServiceTypeById } from '../reducers/serviceTypes';

function FormInputQualifications({ id, value, disabled, colSize, options, selectorOptions, onChange }: InputProps) {
  const { service_type_id, qualification_level } = value;
  const serviceType = useAppSelector((state) => selectAppointmentServiceTypeById(state, parseInt(service_type_id)));

  // Highlight based on default qualifications to the service type
  const highlightOptions = serviceType?.default_qualification_ids ?? [];

  return (
    <FormInput.MultiCheckbox
      type="multi-checkbox"
      id={id}
      name={id}
      value={qualification_level}
      onChange={onChange}
      colSize={colSize}
      disabled={disabled}
      options={options}
      selectorOptions={selectorOptions}
      highlightOptions={highlightOptions}
    />
  );
}

export default FormInputQualifications;
