import React from 'react';
import { useAppSelector } from 'hooks';
import { Alert, Collapse } from 'reactstrap';

import { InputProps } from 'tsx/components/FormFields';
import { Icon, icons } from 'tsx/components/Icon';

import { selectUserByID } from 'tsx/features/main/reducers/users';

function FormInputWorkerRosterNotes({ value = {}, id }: InputProps) {
  const { user_id } = value;
  const user = useAppSelector((state) => selectUserByID(state, parseInt(user_id)));

  const notes = user?.rostering_notes;
  const isOpen = notes ? notes?.length > 0 : false;
  return (
    <Collapse isOpen={isOpen}>
      <Alert color="info" className="d-flex align-items-center justify-content-center" data-id={id}>
        <Icon className="me-2" size="2x" icon={icons.faInfoCircle} />
        <div className="fw-bold">{notes}</div>
      </Alert>
    </Collapse>
  );
}

export default FormInputWorkerRosterNotes;
