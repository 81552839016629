import React, { useEffect } from 'react';
import { Input, Label } from 'reactstrap';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { getTime, formatTimeString, formatDateTimeValue } from 'tsx/libs/dayjs';

function FormInputStartEndTimes({ value, onChange, disabled }: InputProps) {
  let { start_time, end_time, ends_next_day } = value;

  start_time = formatDateTimeValue('time', start_time);
  end_time = formatDateTimeValue('time', end_time);
  ends_next_day = formatDateTimeValue('checkbox', ends_next_day);

  useEffect(() => {
    if (start_time && !end_time) {
      const newEndTime = getTime(start_time, 5);
      onChange && onChange('end_time', formatTimeString(newEndTime));
    }
  }, [start_time, end_time]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    onChange && onChange(name, checked);
  };

  return (
    <div className="d-flex align-items-center">
      <FormInput.Time
        id="start_time"
        name="start_time"
        type="time"
        value={start_time ?? ''}
        disabled={disabled}
        placeholder="Start"
        onChange={onChange}
      />
      <div className="ms-2 me-2">to</div>
      <FormInput.Time
        id="end_time"
        name="end_time"
        type="time"
        value={end_time ?? ''}
        disabled={disabled}
        placeholder="End"
        onChange={onChange}
      />
      <div className="col-4 ms-2">
        <Input
          id="ends_next_day"
          type="checkbox"
          name="ends_next_day"
          checked={ends_next_day ?? false}
          onChange={handleCheckboxChange}
        />
        <Label for="ends_next_day" className="ms-2 mb-0">
          Overnight
        </Label>
      </div>
    </div>
  );
}

export default FormInputStartEndTimes;
