import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'tsx/components/Button';

type Props = {
  isOpen?: boolean;
  onClose: (confirm: boolean) => void;
  messages?: string[];
};

function ConfirmDialog({ isOpen = false, messages = [], onClose }: Props) {
  const details = messages.map((message, index) => <li key={index}>{message}</li>);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="bg-success text-white">Confirmation Required</ModalHeader>
      <ModalBody>
        <div className="mb-4 pb-2 border-2 border-bottom border-success">
          Please be aware of the following details...
        </div>
        <ul>{details}</ul>
      </ModalBody>
      <ModalFooter className="align-items-center flex-column">
        <div className="mb-1">Would you like to continue?</div>
        <div>
          <Button size="sm" color="success" className="ps-4 pe-4 me-2" onClick={() => onClose(true)}>
            Yes
          </Button>
          <Button size="sm" className="ps-4 pe-4" onClick={() => onClose(false)}>
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDialog;
