import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'reactstrap';
import { TextField, Autocomplete as AutocompleteMui } from '@mui/material';
import { debounce } from '@mui/material/utils';
import classNames from 'classnames';
import { InputProps } from 'tsx/components/FormFields';

interface ComponentProps extends InputProps {
  staticSuggestions?: string[];
  onFetchSuggestions?: (query: string) => Promise<any[]>;
}

const Autocomplete: React.FC<ComponentProps> = ({
  id,
  placeholder,
  className,
  staticSuggestions = [],
  onFetchSuggestions,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [, setSelectedValue] = useState<string | null>(null);

  const debounceFetch = useMemo(() => {
    if (onFetchSuggestions) {
      return debounce(async (query: string) => {
        const results = await onFetchSuggestions(query);
        setSuggestions(results);
      }, 300);
    }
    return null;
  }, [onFetchSuggestions]);

  useEffect(() => {
    if (staticSuggestions.length) {
      setSuggestions(staticSuggestions);
    }
  }, [staticSuggestions]);

  const handleInputChange = (_event: React.ChangeEvent<unknown>, input: string) => {
    setInputValue(input);
    debounceFetch?.(input);
  };

  const handleSelect = (_event: React.ChangeEvent<unknown>, newValue: string | null) => {
    setSelectedValue(newValue);
    onChange && onChange(id, newValue);
  };

  const autocompleteSearchClass = classNames('form-control', {
    [`${className}`]: className !== undefined,
  });

  return (
    <>
      <Row className="mb-1 d-flex align-items-center">
        <AutocompleteMui
          freeSolo
          options={suggestions}
          inputValue={inputValue}
          className="w-80"
          onInputChange={handleInputChange}
          onChange={handleSelect}
          renderInput={(params) => <TextField {...params} label={placeholder ?? 'Search'} variant="outlined" />}
          sx={{ autocompleteSearchClass }}
        />
      </Row>
    </>
  );
};

export default Autocomplete;
