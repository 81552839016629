import React from 'react';
import { useAppSelector } from 'hooks';

import { InputProps } from 'tsx/components/FormFields';
import { ReadOnly } from 'tsx/components/FormFields/Inputs';
import { deriveOptionValue, deriveBooleanValue } from 'tsx/libs/records';

const MultiReadOnly: React.FC<InputProps> = ({ value, id, options, selectorOptions, className }) => {
  // Build caption based on value, derived value or selector value.
  // Prioritise selectorOptions over options when building

  let selectOptions: any[] = [];
  if (selectorOptions) selectOptions = useAppSelector(selectorOptions);
  else if (options) selectOptions = options;

  if (!(value instanceof Array)) {
    return (
      <ReadOnly
        type="readonly"
        name={id}
        id={id}
        value={value}
        options={options}
        selectorOptions={selectorOptions}
        className={className}
      />
    );
  }

  const captions = value.map((value) => {
    let caption;
    if (selectOptions) caption = deriveOptionValue({ options: selectOptions }, value);
    else if (typeof value === 'boolean') {
      caption = deriveBooleanValue(value);
    } else {
      caption = value;
    }
    return caption;
  });

  return (
    <div id={id} className={`${className !== undefined ? className : ''}`}>
      {captions.map((caption, index) => (
        <div key={index}>{caption}</div>
      ))}
    </div>
  );
};

export default MultiReadOnly;
