import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLSearchParams } from 'url';

const get = (params: URLSearchParams, key: string) => {
  try {
    const data = params.get(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('searchParams exception (get):', error);
    return null;
  }
};

export const useSearch = (key: string, initialValue: any = undefined) => {
  const [params, setParams] = useSearchParams();
  const [value, setValue] = useState(() => get(params, key) || initialValue);
  const currentParams = useAllSearchParams();

  useEffect(() => {
    if (value === undefined) return;
    try {
      setParams({ ...currentParams, [key]: JSON.stringify(value) });
    } catch (error) {
      console.error('searchParams exception (set):', error);
    }
  }, [key, value]);

  return [value, setValue];
};

// Custom hook for extracting url search param values
export const useAllSearchParams = () => {
  const [searchParams] = useSearchParams();
  return Object.fromEntries(searchParams.entries());
};
